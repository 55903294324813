import { Dispatch, SetStateAction, useState } from "react"
import FeedbackContainer from "../../feedbacks/common/FeedbackContainer"
import { Document, Page, pdfjs } from "react-pdf"
import "react-pdf/dist/Page/TextLayer.css"
import "react-pdf/dist/Page/AnnotationLayer.css"
import { LinearProgress, Stack } from "@mui/material"
import { t } from "i18next"
import awLogo from "../../../assets/images/aw-logo.svg"

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "//unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js",
  import.meta.url
).toString()

const AlertFullScreenPDF = ({
  open,
  setOpen,
  file,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  file: string
}) => {
  // pdf render
  const [pagesNumber, setPagesNumber] = useState<number>(0)

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setPagesNumber(numPages)
  }

  return (
    <FeedbackContainer open={open} setOpen={setOpen} withPadding={false}>
      <div
        style={{
          width: "100%",
          overflowY: "scroll",
          paddingBottom: 90,
        }}
      >
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={
            <Stack alignItems="center" style={{ width: "100%" }}>
              <img
                src={awLogo}
                style={{ width: "38%", maxWidth: 165 }}
                alt={t("aworld_logo_alt")}
              />
              <LinearProgress
                style={{
                  width: "34%",
                  maxWidth: 157,
                  marginTop: 16,
                  borderRadius: 100,
                }}
                classes={{
                  colorPrimary: "linear-progress-background",
                  barColorPrimary: "linear-progress-bar",
                }}
              />
            </Stack>
          }
        >
          {pagesNumber
            ? Array(pagesNumber)
                .fill("")
                .map((item, index) => (
                  <Page
                    key={index}
                    width={window.innerWidth}
                    pageNumber={index + 1}
                  />
                ))
            : null}
        </Document>
      </div>
    </FeedbackContainer>
  )
}

export default AlertFullScreenPDF
