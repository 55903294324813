import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import AppearFromSide from "../animations/AppearFromSide"
import {
  desktopColumnsGap,
  desktopMaxWidth,
  footerHeight,
  navBarHeight,
} from "../../services/config/constants"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import { useTranslation } from "react-i18next"
import FootprintCategoryCardDesktop from "./desktop/FootprintCategoryCardDesktop"
import FootprintCompensateCardDesktop from "./desktop/FootprintCompensateCardDesktop"
import { useContext, useState } from "react"
import FootprintDoughnutChart from "./common/FootprintDoughnutChart"
import { FootprintContext } from "../../controllers/footprint"
import { TagsContext } from "../../controllers/tags"
import { FootprintSector } from "../../services/config/enum"
import { useNavigate } from "react-router-dom"
import Button from "../global/common/Button"
import infoIconPrimary from "../../assets/icons/info-primary.svg"
import AlertInfo from "../global/common/AlertInfo"
import { MainContext } from "../../controllers/main"

const FootprintDesktop = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useContext(MainContext)
  const { userFootprint, userFormsList } = useContext(FootprintContext)
  const { mainCategories } = useContext(TagsContext)

  // how is footprint calculated info alert
  const [footprintCalculationsAlertOpen, setFootprintCalculationsAlertOpen] =
    useState<boolean>(false)

  return (
    <Stack
      style={{ width: "100%", minHeight: `calc(100% - ${footerHeight}px)` }}
    >
      {/* navbar background */}
      <div
        style={{
          width: "100%",
          height: navBarHeight,
          backgroundColor: colors.background,
        }}
      />
      {/* breadcrumb */}
      <BreadcrumbDesktop
        items={[
          {
            label: t("performance"),
            onClick: () => {
              navigate("/performance")
            },
          },
          { label: t("carbon_footprint") },
        ]}
        selectedItem={1}
        style={{ marginTop: 22 }}
      />
      {/* main container */}
      <PaddingContainerDesktop>
        <Stack style={{ width: "100%", maxWidth: desktopMaxWidth }}>
          {/* header */}
          <AppearFromSide fade>
            <Title
              fontSize={36}
              lineHeight="46px"
              style={{ marginTop: 22, fontStyle: "italic" }}
              component="h1"
              color={colors.primary}
            >
              {t("carbon_footprint").toUpperCase()}
            </Title>
          </AppearFromSide>
          <AppearFromSide fade delay={0.05}>
            <Text
              fontSize={28}
              lineHeight="46px"
              fontWeight={400}
              style={{ marginTop: 8 }}
            >
              {t("discover_your_emissions")}
            </Text>
          </AppearFromSide>
          <AppearFromSide fade delay={0.1}>
            <Title
              fontSize={26}
              lineHeight="46px"
              style={{ marginTop: 50 }}
              component="h2"
            >
              {t("your_annual_emissions")}
            </Title>
          </AppearFromSide>
          <Stack
            direction="row"
            style={{
              width: "100%",
              height: 459,
              gap: desktopColumnsGap * 2,
            }}
          >
            {/* chart */}
            <AppearFromSide
              fade
              delay={0.15}
              style={{ width: "45.5%", minWidth: "45.5%", height: "100%" }}
            >
              <FootprintDoughnutChart />
            </AppearFromSide>
            {/* missions */}
            <AppearFromSide
              fade
              delay={0.15}
              x={100}
              style={{ width: "100%", height: "100%", paddingTop: 25 }}
            >
              <Stack
                gap={3}
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              >
                {/* help card */}
                <Stack
                  style={{
                    width: "100%",
                    height: 188,
                    borderRadius: 15,
                    border: "1px solid " + colors.primary,
                    padding: 14,
                  }}
                >
                  <Stack direction="row" alignItems="center" gap={1.5}>
                    <img
                      src={user?.profileImage}
                      style={{
                        width: 76,
                        height: 76,
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: 8,
                      }}
                      alt=""
                    />
                    <Title fontSize={20}>{t("do_you_need_help")}</Title>
                  </Stack>
                  <ButtonBase
                    disableRipple
                    style={{
                      width: "fit-content",
                      justifyContent: "flex-start",
                      marginTop: 18,
                    }}
                    aria-label={t("how_it_is_calculated")}
                    onClick={() => {
                      setFootprintCalculationsAlertOpen(true)
                    }}
                  >
                    <Stack direction="row" gap={1} style={{ height: 21 }}>
                      <img
                        src={infoIconPrimary}
                        style={{ width: 20, height: 20 }}
                        alt=""
                      />
                      <Title
                        fontSize={18}
                        lineHeight="21px"
                        color={colors.primary}
                      >
                        {t("how_it_is_calculated")}
                      </Title>
                    </Stack>
                  </ButtonBase>
                </Stack>
                {/* back to performance button */}
                <Button
                  title={t("back_to_performance")}
                  outlined
                  width="100%"
                  onClick={() => {
                    navigate("/performance")
                  }}
                >
                  {t("back_to_performance")}
                </Button>
                {/* compensate emissions card */}
                {userFootprint ? <FootprintCompensateCardDesktop /> : null}
              </Stack>
            </AppearFromSide>
          </Stack>
          <AppearFromSide fade delay={0.2} style={{ marginTop: 34 }}>
            <Stack
              direction="row"
              alignItems="center"
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              <Title fontSize={26} lineHeight="35px" component="h2">
                {t("categories")}
              </Title>
              <Text
                fontSize={16}
                fontWeight={400}
                style={{ position: "absolute", right: 0 }}
              >
                {t("numbers_expressed_in_tons_co2e")}
              </Text>
            </Stack>
          </AppearFromSide>
          <AppearFromSide
            fade
            delay={0.25}
            style={{ marginTop: 26, marginBottom: 95 }}
          >
            <Stack
              direction="row"
              alignItems="center"
              style={{
                width: "100%",
                height: 180,
                gap: desktopColumnsGap,
              }}
            >
              <FootprintCategoryCardDesktop
                mainCategory={mainCategories[0]}
                sector={FootprintSector.home}
                value={userFootprint ? userFootprint.footprintHome : undefined}
                givenAnswers={
                  userFormsList.some((item) => item.sector === "home")
                    ? 4
                    : userFootprint
                    ? 2
                    : 0
                }
                totalAnswers={4}
              />
              <FootprintCategoryCardDesktop
                mainCategory={mainCategories[1]}
                sector={FootprintSector.shopping}
                value={
                  userFootprint ? userFootprint.footprintShopping : undefined
                }
                givenAnswers={
                  userFormsList.some((item) => item.sector === "shopping")
                    ? 6
                    : userFootprint
                    ? 2
                    : 0
                }
                totalAnswers={6}
              />
              <FootprintCategoryCardDesktop
                mainCategory={mainCategories[2]}
                sector={FootprintSector.transport}
                value={
                  userFootprint ? userFootprint.footprintTransport : undefined
                }
                givenAnswers={
                  userFormsList.some((item) => item.sector === "transport")
                    ? 10
                    : userFootprint
                    ? 4
                    : 0
                }
                totalAnswers={10}
              />
              <FootprintCategoryCardDesktop
                mainCategory={mainCategories[3]}
                sector={FootprintSector.food}
                value={userFootprint ? userFootprint.footprintFood : undefined}
                givenAnswers={userFootprint ? 1 : 0}
                totalAnswers={1}
              />
            </Stack>
          </AppearFromSide>
        </Stack>
      </PaddingContainerDesktop>
      {/* how footprint is calculated info alert */}
      <AlertInfo
        open={footprintCalculationsAlertOpen}
        setOpen={setFootprintCalculationsAlertOpen}
        title={t("how_it_is_calculated")}
        description={t("footprint_description")}
        descriptionAlign="center"
      />
    </Stack>
  )
}

export default FootprintDesktop
