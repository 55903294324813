import { ButtonBase, Stack } from "@mui/material"
import {
  defaultBoxShadowDarker,
  desktopMaxWidth,
} from "../../../services/config/constants"
import boostLearnMissionImage from "../../../assets/images/boostMissions/learn-boost-desktop.png"
import Title from "../../global/common/Title"
import { CSSProperties, useContext } from "react"
import BoostBadge from "../common/BoostBadge"
import { useTranslation } from "react-i18next"
import { MainContext } from "../../../controllers/main"
import { colors } from "../../../services/config/colors"
import Text from "../../global/common/Text"
import Countdown from "../../global/common/Countdown"
import stopwatchPrimaryIcon from "../../../assets/icons/stopwatch-primary.svg"
import Mission from "../../../models/mission"

const BoostBannerDesktop = ({
  mission,
  style,
}: {
  mission: Mission
  style?: CSSProperties
}) => {
  const { t } = useTranslation()
  const { isMobile } = useContext(MainContext)

  return (
    <ButtonBase
      disabled
      style={{
        width: "100%",
        maxWidth: desktopMaxWidth,
        height: 178,
        minHeight: 178,
        borderRadius: 16,
        backgroundImage: `url(${boostLearnMissionImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        boxShadow: defaultBoxShadowDarker,
        ...style,
      }}
    >
      <Stack
        justifyContent="center"
        style={{
          width: "100%",
          height: "100%",
          paddingLeft: 388,
          position: "relative",
        }}
      >
        <Stack direction="row" style={{ gap: 10 }}>
          <BoostBadge />
          <Stack
            justifyContent="center"
            style={{
              width: "auto",
              height: isMobile ? 32 : 40,
              paddingInline: isMobile ? 8 : 10,
              borderRadius: 4,
              backgroundColor: colors.backgroundWhite,
              border: isMobile
                ? "1px solid " + colors.primary
                : "1.5px solid " + colors.primary,
            }}
          >
            <Text
              fontSize={isMobile ? 14 : 18}
              fontWeight={700}
              color={colors.primary}
              style={{ marginTop: isMobile ? 4 : 6 }}
            >
              {`${mission.bonusType!.type === "multiplier" ? "x" : "+"}${
                mission.bonusType!.value
              } ${t("points")}`}
            </Text>
          </Stack>
        </Stack>
        <Title
          fontSize={20}
          style={{
            maxWidth: "70%",
            marginTop: 20,
          }}
        >
          Ottieni punti extra completando la missione "{mission.title}"
        </Title>
        {/* countdown */}
        <Stack gap={1.5} style={{ position: "absolute", top: 52, right: 24 }}>
          <Text fontSize={11} fontWeight={500} color={colors.primary}>
            TERMINA IN
          </Text>
          <Stack
            className="center"
            direction="row"
            gap={1}
            style={{
              width: "fit-content",
              height: 40,
              backgroundColor: colors.backgroundWhite,
              border: `2px solid ${colors.primary}`,
              borderRadius: 3,
              paddingInline: 16,
            }}
          >
            <img
              src={stopwatchPrimaryIcon}
              style={{ width: 24, height: 24 }}
              alt=""
            />
            <Countdown
              targetDate={new Date(mission.activeTimeSlots!.end)}
              showDays={false}
              style={{ fontSize: 16, color: colors.primary, marginTop: 5 }}
            />
          </Stack>
        </Stack>
      </Stack>
    </ButtonBase>
  )
}

export default BoostBannerDesktop
