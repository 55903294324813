import { ButtonBase, LinearProgress } from "@mui/material"
import { CSSProperties, ReactNode, useContext } from "react"
import { colors } from "../../../services/config/colors"
import { MainContext } from "../../../controllers/main"

const Button = ({
  children,
  style,
  width = "calc(100% - 32px)",
  onClick,
  onFocusVisible,
  onBlur,
  disabled = false,
  loading = false,
  outlined = false,
  startIcon,
  title,
}: {
  children?: ReactNode
  style?: CSSProperties
  width?: string | number
  onClick?: () => void
  onFocusVisible?: () => void
  onBlur?: () => void
  disabled?: boolean
  loading?: boolean
  outlined?: boolean
  startIcon?: ReactNode
  title: string
}) => {
  const { isMobile } = useContext(MainContext)

  return (
    <ButtonBase
      disabled={disabled || loading}
      style={{
        width: width,
        height: 56,
        borderRadius: 16,
        backgroundColor: outlined
          ? colors.backgroundWhite
          : loading || disabled
          ? colors.disabled
          : colors.primary,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color:
          outlined && disabled
            ? colors.disabled
            : disabled
            ? colors.textWhite
            : outlined
            ? colors.primary
            : colors.textWhite,
        fontSize: isMobile ? 16 : 18,
        lineHeight: "29px",
        fontWeight: 700,
        border: outlined
          ? isMobile
            ? "1px solid " + (disabled ? colors.disabled : colors.primary)
            : "1.5px solid " + (disabled ? colors.disabled : colors.primary)
          : "none",
        transition: "200ms",
        overflow: "hidden",
        textTransform: "uppercase",
        fontStyle: "italic",
        ...style,
      }}
      onClick={onClick}
      onFocusVisible={onFocusVisible}
      onBlur={onBlur}
      aria-label={title}
      aria-disabled={disabled ? "true" : "false"}
    >
      {startIcon ? (
        <div
          style={{
            marginRight: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {startIcon}
        </div>
      ) : null}
      {children}
      {loading && (
        <LinearProgress
          style={{ width: "100%", position: "absolute", bottom: 0 }}
        />
      )}
    </ButtonBase>
  )
}

export default Button
