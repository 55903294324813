import { useContext, useEffect, useMemo, useState } from "react"
import { MainContext } from "../../controllers/main"
import ChallengeMobile from "../../components/challenge/ChallengeMobile"
import ChallengeDesktop from "../../components/challenge/ChallengeDesktop"
import {
  registerPageEvent,
  scrollWindowToTop,
} from "../../services/utils/utils"
import Prize from "../../models/prize"
import prize1Image from "../../assets/images/prizes/prize-1.jpg"
import trenitalia50Image from "../../assets/images/prizes/trenitalia-50.png"
import { t } from "i18next"
import ChallengeModel from "../../models/challenge"
import { ChallengeContext } from "../../controllers/challenge"
import { UsersContext } from "../../controllers/users"
import { MissionsContext } from "../../controllers/missions"

const Challenge = () => {
  const { isMobile, isOldChallenge } = useContext(MainContext)
  const { oldChallenge, challenge, oldChallengeSavings, challengeSavings } =
    useContext(ChallengeContext)
  const { getLeaderboard } = useContext(UsersContext)
  const { getMissionsHistory, getCompletedMissionsCount } =
    useContext(MissionsContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("challenge")
  }, [])

  // manage old challenge
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [displayedChallenge, setDisplayedChallenge] = useState<ChallengeModel>(
    challenge!
  )
  const [displayedSavings, setDisplayedSavings] = useState<{
    co2Saving: number
    waterSaving: number
    energySaving: number
  } | null>(challengeSavings)
  const [displayedLeaderboardLoading, setDisplayedLeaderboardLoading] =
    useState<boolean>(false)

  useEffect(() => {
    const changeData = async () => {
      setTimeout(() => {
        scrollWindowToTop("smooth")
      }, 10)

      setDisplayedChallenge(isOldChallenge ? oldChallenge! : challenge!)
      setDisplayedSavings(
        isOldChallenge ? oldChallengeSavings : challengeSavings
      )

      setDisplayedLeaderboardLoading(true)
      await Promise.all([
        getLeaderboard(false, false),
        getMissionsHistory(false, false),
        getCompletedMissionsCount(false),
      ])
      setDisplayedLeaderboardLoading(false)
    }

    if (!isFirstRender) {
      changeData()
    } else {
      setIsFirstRender(false)
    }
  }, [isOldChallenge])

  // array of prizes
  const oldPrizes: Prize[] = useMemo(
    () => [
      {
        heading: `1° ${t("in_ranking").toLowerCase()}`,
        title: "Un weekend per 2 persone allo Chalet Levissima 3000",
        image: prize1Image,
        value: "Valore € 1.000",
      },
      {
        heading: `2° - 5° ${t("in_ranking").toLowerCase()}`,
        title: "Buono Trenitalia",
        image: trenitalia50Image,
        value: "Valore € 50 ciascuno",
      },
      {
        heading: "3 premi ad estrazione",
        title: "Un weekend per 2 persone allo Chalet Levissima 3000",
        image: prize1Image,
        value: "Valore € 1.000",
        draw: true,
      },
    ],
    []
  )

  const prizes: Prize[] = useMemo(
    () => [
      {
        heading: `1° - 3° ${t("in_ranking").toLowerCase()}`,
        title: "Un weekend per 2 persone allo Chalet Levissima 3000*",
        image: prize1Image,
        value: "Valore € 1.000 ciascuno",
      },
      {
        heading: "1 premio ad estrazione",
        title: "Un weekend per 2 persone allo Chalet Levissima 3000*",
        image: prize1Image,
        value: "Valore € 1.000",
        draw: true,
      },
    ],
    []
  )

  return isMobile ? (
    <ChallengeMobile
      displayedChallenge={displayedChallenge}
      displayedSavings={displayedSavings}
      displayedLeaderboardLoading={displayedLeaderboardLoading}
      oldPrizes={oldPrizes}
      prizes={prizes}
    />
  ) : (
    <ChallengeDesktop
      displayedChallenge={displayedChallenge}
      displayedSavings={displayedSavings}
      displayedLeaderboardLoading={displayedLeaderboardLoading}
      oldPrizes={oldPrizes}
      prizes={prizes}
    />
  )
}

export default Challenge
