import { colors } from "../../../services/config/colors"
import stopwatchIcon from "../../../assets/icons/stopwatch.svg"
import { Stack } from "@mui/material"
import Text from "./Text"

const TimeBadge = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      style={{
        width: "auto",
        height: 38,
        backgroundColor: colors.backgroundWhite,
        marginTop: 12,
        borderRadius: 16,
        paddingInline: 16,
        gap: 10,
      }}
    >
      <img src={stopwatchIcon} style={{ width: 24, height: 24 }} alt="" />
      <Text
        fontSize={16}
        fontWeight={700}
        color="#40406A"
        style={{ marginTop: 5 }}
      >
        09:00
      </Text>
    </Stack>
  )
}

export default TimeBadge
