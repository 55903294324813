import { Stack } from "@mui/material"
import Title from "../../global/common/Title"
import Text from "../../global/common/Text"
import { colors } from "../../../services/config/colors"
import { useTranslation } from "react-i18next"
import { MissionType } from "../../../services/config/enum"
import actWhiteIcon from "../../../assets/icons/missions/missionsTypes/act-white.svg"
import learnWhiteIcon from "../../../assets/icons/missions/missionsTypes/learn-white.svg"
import measureWhiteIcon from "../../../assets/icons/missions/missionsTypes/measure-white.svg"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"
import BoostBadge from "../../missions/common/BoostBadge"

const PointsHistoryListItem = ({
  title,
  time,
  points,
  type,
  isCorrect,
  boost,
  forFullList = false,
}: {
  title: string
  time: string
  points: number
  type: MissionType
  isCorrect?: boolean
  boost?: boolean
  forFullList?: boolean
}) => {
  const { t } = useTranslation()
  const { isMobile } = useContext(MainContext)

  return (
    <Stack
      style={{
        width: "100%",
        height: "auto",
        position: "relative",
        gap: isMobile ? 4 : 0,
      }}
    >
      <div
        style={{
          width: isMobile ? 22 : 32,
          height: isMobile ? 22 : 32,
          borderRadius: "100%",
          backgroundColor: type
            ? (colors.missions as any)[type.toLowerCase()]
            : colors.backgroundWhite,
          position: "absolute",
          top: 0,
          left: forFullList ? 12 : isMobile ? 18 : 26,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={
            type === MissionType.ACT
              ? actWhiteIcon
              : type === MissionType.LEARN
              ? learnWhiteIcon
              : measureWhiteIcon
          }
          style={{
            width:
              type === MissionType.MEASURE
                ? isMobile
                  ? 8
                  : 13
                : isMobile
                ? 10
                : 15,
          }}
          alt=""
        />
      </div>
      <Title
        fontSize={isMobile ? 14 : 20}
        lineHeight={isMobile ? "20px" : "28px"}
        paddingLeft={forFullList ? 62 : isMobile ? 55 : 80}
        color={isCorrect === false || points < 0 ? colors.error : colors.text}
      >
        {isCorrect === false || points < 0
          ? t("you_did_not_complete_mission")
          : t("you_completed_mission")}
        :
      </Title>
      {isMobile ? (
        <Text
          fontSize={12}
          lineHeight="17px"
          fontWeight={500}
          paddingLeft={55}
          style={{ width: "82%" }}
          color={isCorrect === false || points < 0 ? "#D15852" : colors.text}
        >
          {title ?? "unknown"}
        </Text>
      ) : (
        <Title
          fontSize={16}
          lineHeight="24px"
          paddingLeft={forFullList ? 62 : 80}
          ellipsis
          style={{ width: "75%" }}
          color={isCorrect === false || points < 0 ? "#D15852" : colors.text}
        >
          {title ?? "unknown"}
        </Title>
      )}
      <Text
        fontSize={isMobile ? 12 : 16}
        lineHeight={isMobile ? "17px" : "22px"}
        fontWeight={400}
        paddingLeft={forFullList ? 62 : isMobile ? 55 : 80}
        style={{
          marginTop: isMobile ? 0 : 5,
        }}
        color={colors.stroke}
      >
        {`${
          new Date(time).toLocaleDateString() ===
          new Date().toLocaleDateString()
            ? t("today")
            : new Date(time).toLocaleDateString() ===
              new Date(
                new Date().setDate(new Date().getDate() - 1)
              ).toLocaleDateString()
            ? t("yesterday")
            : new Date(time).toLocaleDateString()
        } · ${new Date(time).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}`}
      </Text>
      {points && (isCorrect !== false || points < 0) ? (
        <Text
          fontSize={isMobile ? 14 : 20}
          lineHeight={isMobile ? "20px" : "26px"}
          fontWeight={700}
          color={points < 0 ? colors.error : colors.primary}
          style={{ position: "absolute", top: 0, right: isMobile ? 18 : 26 }}
        >
          {points} {t("points")}
        </Text>
      ) : null}
      {boost && isCorrect !== false ? (
        <BoostBadge
          style={{
            height: isMobile ? 28 : 40,
            position: "absolute",
            right: isMobile ? 18 : 26,
            bottom: 0,
          }}
        />
      ) : null}
    </Stack>
  )
}

export default PointsHistoryListItem
