import { ButtonBase, Stack } from "@mui/material"
import { CSSProperties } from "react"
import PaddingContainerDesktop from "./PaddingContainerDesktop"
import awLogoWhite from "../../../assets/images/aw-logo-white.svg"
import { desktopMaxWidth } from "../../../services/config/constants"
import { colors } from "../../../services/config/colors"
import { useTranslation } from "react-i18next"
import Text from "../common/Text"

const FooterDesktop = ({ style }: { style?: CSSProperties }) => {
  const { t } = useTranslation()

  return (
    <footer>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{
          width: "100%",
          height: 106,
          minHeight: 106,
          gap: 50,
          backgroundColor: colors.primary,
          ...style,
        }}
      >
        <PaddingContainerDesktop>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            style={{
              width: "100%",
              height: 30,
              maxWidth: desktopMaxWidth,
              gap: 18,
              position: "relative",
            }}
          >
            <ButtonBase
              disableRipple
              style={{ width: 180, height: 30 }}
              onClick={() => {
                window.open("https://aworld.org")
              }}
              aria-label={t("open_aworld_website")}
            >
              <img
                src={awLogoWhite}
                style={{ width: "100%", height: "100%" }}
                alt=""
              />
            </ButtonBase>
            <ButtonBase
              disableRipple
              aria-label="www.aworld.org"
              style={{ justifyContent: "flex-start", marginTop: 3 }}
              onClick={() => {
                window.open("https://aworld.org")
              }}
            >
              <Text fontSize={16} fontWeight={400} color={colors.textWhite}>
                www.aworld.org
              </Text>
            </ButtonBase>
            <ButtonBase
              disableRipple
              aria-label="Note legali"
              style={{ position: "absolute", right: 0, marginTop: 3 }}
              onClick={() => {
                window.open("https://www.levissima.it/note-legali")
              }}
            >
              <Text
                fontSize={16}
                fontWeight={400}
                color={colors.textWhite}
                style={{ textDecoration: "underline" }}
              >
                Note legali
              </Text>
            </ButtonBase>
          </Stack>
        </PaddingContainerDesktop>
      </Stack>
    </footer>
  )
}

export default FooterDesktop
