import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import Title from "../global/common/Title"
import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import arrowLefIcon from "../../assets/icons/arrow-left.svg"
import Text from "../global/common/Text"
import infoIcon from "../../assets/icons/info-primary.svg"
import { useContext, useState } from "react"
import FootprintCategoryCardMobile from "./mobile/FootprintCategoryCardMobile"
import walkInNatureImage from "../../assets/images/walk-in-nature.png"
import FootprintAnswersCardMobile from "./mobile/FootprintAnswersCardMobile"
import FootprintDoughnutChart from "./common/FootprintDoughnutChart"
import AppearFromSide from "../animations/AppearFromSide"
import { FootprintContext } from "../../controllers/footprint"
import { TagsContext } from "../../controllers/tags"
import AlertInfo from "../global/common/AlertInfo"
import { FootprintSector } from "../../services/config/enum"
import chevronLeftCircularIcon from "../../assets/icons/chevron-left-circular.svg"
import chevronRightCircularIcon from "../../assets/icons/chevron-right-circular.svg"

const FootprintMobile = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { userFootprint, userFormsList } = useContext(FootprintContext)
  const { mainCategories } = useContext(TagsContext)

  // footprint info alert
  const [footprintInfoAlertOpen, setFootprintInfoAlertOpen] =
    useState<boolean>(false)

  // your answers carousel controls
  const cardWidth = 140
  const [currentCard, setCurrentCard] = useState<number>(0)

  return (
    <Stack
      alignItems="center"
      style={{
        width: "100%",
        minHeight: "100%",
        backgroundColor: colors.backgroundWhite,
        paddingTop: 22,
        overflowX: "hidden",
      }}
    >
      {/* header */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ width: "100%", height: 48, paddingInline: 16 }}
      >
        <ButtonBase
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
          onClick={() => {
            navigate("/performance")
          }}
          aria-label={t("go_back")}
        >
          <img src={arrowLefIcon} style={{ width: 15 }} alt="" />
        </ButtonBase>
        <Title
          fontSize={20}
          lineHeight="23px"
          style={{ height: 23, fontStyle: "italic" }}
          component="h1"
          color={colors.primary}
          ellipsis
        >
          {t("your_carbon_footprint").toUpperCase()}
        </Title>
        <div
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
        />
      </Stack>
      <ButtonBase
        disableRipple
        style={{
          width: "fit-content",
          height: 22,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        aria-label={t("how_is_calculated")}
        onClick={() => {
          setFootprintInfoAlertOpen(true)
        }}
      >
        <img src={infoIcon} style={{ width: 16, height: 16 }} alt="" />
        <Text
          fontSize={14}
          lineHeight="16px"
          fontWeight={700}
          style={{ marginLeft: 5 }}
          color={colors.primary}
        >
          {t("how_is_calculated")}
        </Text>
      </ButtonBase>
      {/* chart */}
      <div
        style={{
          width: "100%",
          maxWidth: 375,
          marginTop: 22,
        }}
      >
        <FootprintDoughnutChart />
      </div>
      {/* categories details */}
      <AppearFromSide
        fade
        delay={0.05}
        style={{ width: "100%", marginTop: 35, paddingInline: 16 }}
      >
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr
              style={{
                width: "100%",
                height: 14,
              }}
            >
              <th>
                <Text fontSize={10} lineHeight="14px" fontWeight={400}>
                  {t("categories").toUpperCase()}
                </Text>
              </th>
              <th style={{ display: "flex", justifyContent: "flex-end" }}>
                <Text fontSize={10} lineHeight="14px" fontWeight={400}>
                  {t("tons_co2e")}
                </Text>
              </th>
            </tr>
          </thead>
          <tbody>
            <FootprintCategoryCardMobile
              mainCategory={mainCategories[0]}
              value={userFootprint ? userFootprint.footprintHome : undefined}
            />
            <FootprintCategoryCardMobile
              mainCategory={mainCategories[1]}
              value={
                userFootprint ? userFootprint.footprintShopping : undefined
              }
              withBorder
            />
            <FootprintCategoryCardMobile
              mainCategory={mainCategories[2]}
              value={
                userFootprint ? userFootprint.footprintTransport : undefined
              }
              withBorder
            />
            <FootprintCategoryCardMobile
              mainCategory={mainCategories[3]}
              value={userFootprint ? userFootprint.footprintFood : undefined}
              withBorder
            />
          </tbody>
        </table>
      </AppearFromSide>
      {/* compensate card */}
      {userFootprint ? (
        <AppearFromSide
          fade
          delay={0.1}
          style={{ width: "calc(100% - 32px)", marginTop: 20 }}
        >
          <Stack
            direction="row"
            alignItems="center"
            style={{
              width: "100%",
              height: "auto",
              backgroundColor: colors.primary,
              borderRadius: 16,
              padding: 16,
              gap: 16,
            }}
          >
            <div
              style={{
                width: 78,
                minWidth: 78,
                height: 78,
                borderRadius: "100%",
                backgroundColor: colors.backgroundWhite,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={walkInNatureImage}
                style={{ width: 58, height: 58 }}
                alt=""
              />
            </div>
            <Stack style={{ gap: 8 }}>
              <Title fontSize={14} color={colors.textWhite}>
                {t("did_you_know")}
              </Title>
              <Text fontSize={14} fontWeight={400} color={colors.textWhite}>
                <Trans
                  i18nKey="footprint_compensate"
                  count={Math.round((userFootprint.footprint * 1000) / 22)}
                />
              </Text>
            </Stack>
          </Stack>
        </AppearFromSide>
      ) : null}
      {/* user answers */}
      <AppearFromSide
        fade
        delay={0.15}
        style={{ width: "100%", marginTop: 40 }}
      >
        <Stack
          style={{
            width: "100%",
            paddingTop: 24,
            paddingBottom: 32,
            backgroundColor: colors.background,
          }}
        >
          <Stack style={{ paddingInline: 16, gap: 3 }}>
            <Title fontSize={18} component="h2">
              {t("your_answers")}
            </Title>
            <Text fontSize={14} lineHeight="17px">
              {t("footprint_refine_description")}
            </Text>
          </Stack>
          <div style={{ width: "100%", position: "relative", marginTop: 20 }}>
            {/* carousel back */}
            <ButtonBase
              style={{
                width: 46,
                height: 46,
                borderRadius: "100%",
                backgroundColor: colors.backgroundWhite,
                position: "absolute",
                top: 0,
                bottom: 0,
                marginTop: "auto",
                marginBottom: "auto",
                left: 16,
                boxShadow: "0px 0px 24px rgba(17, 67, 97, 0.08)",
                zIndex: 1,
              }}
              aria-label={t("go_back")}
              aria-disabled={currentCard === 0 ? "true" : "false"}
              onClick={() => {
                const container = document.getElementById(
                  "answers-carousel-container"
                )!
                container.scrollTo({
                  left: cardWidth * (currentCard - 1),
                  behavior: "smooth",
                })
              }}
            >
              <img
                src={chevronLeftCircularIcon}
                style={{
                  width: "100%",
                  opacity: currentCard === 0 ? 0.3 : 1,
                  transition: "200ms",
                }}
                alt=""
              />
            </ButtonBase>
            {/* carousel */}
            <Stack
              id="answers-carousel-container"
              direction="row"
              style={{
                width: "100%",
                maxWidth: "100%",
                height: 70,
                overflowX: "scroll",
                paddingInline: 78,
                gap: 12,
                position: "relative",
              }}
              onScroll={() => {
                const container = document.getElementById(
                  "answers-carousel-container"
                )!
                setCurrentCard(Math.round(container.scrollLeft / cardWidth))
              }}
            >
              <FootprintAnswersCardMobile
                width={cardWidth}
                mainCategory={mainCategories[0]}
                sector={FootprintSector.home}
                givenAnswers={
                  userFormsList.some((item) => item.sector === "home")
                    ? 4
                    : userFootprint
                    ? 2
                    : 0
                }
                totalAnswers={4}
              />
              <FootprintAnswersCardMobile
                width={cardWidth}
                mainCategory={mainCategories[1]}
                sector={FootprintSector.shopping}
                givenAnswers={
                  userFormsList.some((item) => item.sector === "shopping")
                    ? 6
                    : userFootprint
                    ? 2
                    : 0
                }
                totalAnswers={6}
              />
              <FootprintAnswersCardMobile
                width={cardWidth}
                mainCategory={mainCategories[2]}
                sector={FootprintSector.transport}
                givenAnswers={
                  userFormsList.some((item) => item.sector === "transport")
                    ? 10
                    : userFootprint
                    ? 4
                    : 0
                }
                totalAnswers={10}
              />
              <FootprintAnswersCardMobile
                width={cardWidth}
                mainCategory={mainCategories[3]}
                sector={FootprintSector.food}
                givenAnswers={userFootprint ? 1 : 0}
                totalAnswers={1}
              />
            </Stack>
            {/* carousel next */}
            <ButtonBase
              style={{
                width: 46,
                height: 46,
                borderRadius: "100%",
                backgroundColor: colors.backgroundWhite,
                position: "absolute",
                top: 0,
                bottom: 0,
                marginTop: "auto",
                marginBottom: "auto",
                right: 16,
                boxShadow: "0px 0px 24px rgba(17, 67, 97, 0.08)",
                zIndex: 1,
              }}
              aria-label={t("next")}
              aria-disabled={currentCard === 3 ? "true" : "false"}
              onClick={() => {
                const container = document.getElementById(
                  "answers-carousel-container"
                )!
                container.scrollTo({
                  left: cardWidth * (currentCard + 1),
                  behavior: "smooth",
                })
              }}
            >
              <img
                src={chevronRightCircularIcon}
                style={{
                  width: "100%",
                  opacity: currentCard === 3 ? 0.3 : 1,
                  transition: "200ms",
                }}
                alt=""
              />
            </ButtonBase>
          </div>
        </Stack>
      </AppearFromSide>
      {/* footprint info alert */}
      <AlertInfo
        open={footprintInfoAlertOpen}
        setOpen={setFootprintInfoAlertOpen}
        title={t("how_is_calculated")}
        description={t("footprint_description")}
        descriptionAlign="center"
      />
    </Stack>
  )
}

export default FootprintMobile
