import { ButtonBase, Stack } from "@mui/material"
import Title from "../../global/common/Title"
import { colors } from "../../../services/config/colors"
import Text from "../../global/common/Text"
import lightningIcon from "../../../assets/icons/lightning.svg"
import { useTranslation } from "react-i18next"
import { useContext, useEffect, useState } from "react"
import { ChallengeContext } from "../../../controllers/challenge"
import { MainContext } from "../../../controllers/main"
import Confetti from "../../global/common/Confetti"
import ChallengeDotProgressBar from "../common/ChallengeDotProgressBar"
import { useNavigate } from "react-router-dom"
import { numberWithSeparators } from "../../../services/utils/utils"

const ChallengeRecapCardDesktop = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { viewTutorial, currentTutorialPage } = useContext(MainContext)
  const { challenge } = useContext(ChallengeContext)

  // show confetti fot tutorial animation
  const [showConfetti, setShowConfetti] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      setShowConfetti(true)
    }, 160)
  }, [])

  return (
    <ButtonBase
      aria-label={t("go_to_challenge")}
      style={{ width: "100%", borderRadius: 15 }}
      onClick={() => {
        navigate("/challenge")
      }}
    >
      <Stack
        id="challenge-card"
        alignItems="center"
        style={{
          width: "100%",
          height: "auto",
          backgroundColor: colors.backgroundWhite,
          borderRadius: 15,
          position: "relative",
          paddingTop: 18,
          paddingBottom: 18,
          boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
        }}
        role="region"
        aria-label={t("challenge_card")}
      >
        {showConfetti ? <Confetti zIndex={150} particleCount={80} /> : null}
        <Title fontSize={14} lineHeight="17px" color={colors.primary}>
          {t("collective_challenge").toUpperCase()}
        </Title>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ width: "100%", height: 39, gap: 10, marginTop: 36 }}
        >
          <img
            src={lightningIcon}
            style={{ height: 27, marginTop: -4 }}
            alt=""
          />
          <Title fontSize={30} color={colors.primary}>
            {numberWithSeparators(challenge!.currentAmount)}{" "}
            {t("missions").toLowerCase()}
          </Title>
        </Stack>
        <ChallengeDotProgressBar
          percentage={
            viewTutorial && currentTutorialPage === 1
              ? 100
              : Math.round(
                  (challenge!.currentAmount / challenge!.targetAmount) * 100
                )
          }
          challengeTargetAmount={challenge!.targetAmount}
          animated={false}
          style={{
            width: "calc(100% - 36px)",
            height: 15.6,
            marginTop: 14,
          }}
        />
        <Stack
          direction="row"
          alignItems="center"
          style={{
            width: "100%",
            height: 28,
            gap: 8,
            marginTop: 12,
            paddingInline: 18,
          }}
        >
          <Text
            fontSize={14}
            fontWeight={600}
            textAlign="center"
            style={{ width: "100%" }}
          >
            {`${challenge!.userCount} ${t("partecipants")} - ${t(
              "goal"
            )} ${numberWithSeparators(challenge!.targetAmount)} ${t(
              "missions"
            ).toLowerCase()}`}
          </Text>
        </Stack>
      </Stack>
    </ButtonBase>
  )
}

export default ChallengeRecapCardDesktop
