import { CSSProperties, useContext, useEffect, useState } from "react"
import { colors } from "../../../services/config/colors"
import Text from "../../global/common/Text"
import { MainContext } from "../../../controllers/main"
import { useTranslation } from "react-i18next"
import {
  challengeTargetToShowMilestone,
  shouldShowMilestone,
} from "../../../services/config/constants"

const ChallengeProgressBar = ({
  percentage,
  challengeTargetAmount,
  showPercentageStartingFromDesktop = 10,
  showPercentageStartingFromMobile = 15,
  percentageTextColor = colors.textWhite,
  style,
}: {
  percentage: number
  challengeTargetAmount: number
  showPercentageStartingFromDesktop?: number
  showPercentageStartingFromMobile?: number
  percentageTextColor?: string
  style?: CSSProperties
}) => {
  const { t } = useTranslation()
  const { isMobile, viewTutorial, currentTutorialPage } =
    useContext(MainContext)

  // bar percentage, different from the props one beacause the max of this one is 100
  const [barPercentage, setBarPercentage] = useState<number>(0)

  useEffect(() => {
    setBarPercentage(percentage > 100 ? 100 : percentage)
  }, [percentage])

  return (
    <div
      style={{
        width: "100%",
        height: 20,
        overflow: "hidden",
        position: "relative",
        borderRadius: 100,
        backgroundColor: colors.background,
        display: "flex",
        alignItems: "center",
        border: "1px solid " + colors.primary,
        ...style,
      }}
      aria-label={t("challenge_completion", { percentage: percentage })}
      role="group"
      aria-roledescription={t("progress_bar")}
    >
      <div
        style={{
          width: `calc(${barPercentage}% + 20px)`,
          height: "100%",
          backgroundColor: colors.primary,
          borderRadius: 100,
          transition:
            viewTutorial && currentTutorialPage === 1 ? "1000ms" : "0ms",
          transitionTimingFunction:
            viewTutorial && currentTutorialPage === 1
              ? "cubic-bezier(0.47, 0, 0.745, 0.2)"
              : "unset",
          marginLeft: -20,
        }}
      />
      {/* milestone indicator */}
      {challengeTargetAmount === challengeTargetToShowMilestone &&
      shouldShowMilestone ? (
        <div
          style={{
            width: 12,
            height: 12,
            position: "absolute",
            top: 3,
            left: 0,
            right: 0,
            marginInline: "auto",
            backgroundColor: "#84C57B",
            transform: "rotate(45deg)",
          }}
        />
      ) : null}
      {!isMobile && (
        <Text
          fontSize={12}
          fontWeight={700}
          color={percentageTextColor}
          style={{
            position: "absolute",
            left: `calc(${barPercentage}% - 40px)`,
            opacity: percentage >= showPercentageStartingFromDesktop ? 1 : 0,
          }}
          ariaHidden
        >
          {percentage === Infinity ? "100" : percentage}%
        </Text>
      )}
      {isMobile && (
        <Text
          fontSize={12}
          fontWeight={700}
          color={percentageTextColor}
          style={{
            position: "absolute",
            left: `calc(${barPercentage}% - 40px)`,
            opacity: percentage >= showPercentageStartingFromMobile ? 1 : 0,
          }}
          ariaHidden
        >
          {percentage === Infinity ? "100" : percentage}%
        </Text>
      )}
    </div>
  )
}

export default ChallengeProgressBar
