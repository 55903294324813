import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import { get } from "../services/api/api"
import Challenge from "../models/challenge"
import { MainContext } from "./main"
import { oldChallengeContractId } from "../services/config/constants"

interface ChallengeContextInterface {
  challengeLoading: boolean
  challengeError: boolean
  challenge: Challenge | null
  getChallenge: (withLoading?: boolean) => void
  savingsLoading: boolean
  savingsError: boolean
  challengeSavings: {
    co2Saving: number
    waterSaving: number
    energySaving: number
  } | null
  getSavings: (withLoading?: boolean) => void
  oldChallenge: Challenge | null
  oldChallengeSavings: {
    co2Saving: number
    waterSaving: number
    energySaving: number
  } | null
}

const ChallengeContext = createContext<ChallengeContextInterface>({
  challengeLoading: true,
  challengeError: false,
  challenge: null,
  getChallenge: () => {},
  savingsLoading: true,
  savingsError: false,
  challengeSavings: null,
  getSavings: () => {},
  oldChallenge: null,
  oldChallengeSavings: null,
})

const ChallengeController = ({ children }: { children: ReactNode }) => {
  const { setViewTutorial, isChallengeOver, contract } = useContext(MainContext)

  // loadings
  const [challengeLoading, setChallengeLoading] = useState<boolean>(true)
  const [savingsLoading, setSavingsLoading] = useState<boolean>(true)

  // errors
  const [challengeError, setChallengeError] = useState<boolean>(false)
  const [savingsError, setSavingsError] = useState<boolean>(false)

  // states
  const [oldChallenge, setOldChallenge] = useState<Challenge | null>(null) // old challenge
  const [challenge, setChallenge] = useState<Challenge | null>(null) // current challenge
  const [oldChallengeSavings, setOldChallengeSavings] = useState<{
    co2Saving: number
    waterSaving: number
    energySaving: number
  } | null>(null) // old challenge savings
  const [challengeSavings, setChallengeSavings] = useState<{
    co2Saving: number
    waterSaving: number
    energySaving: number
  } | null>(null) // current challenge savings

  // get current challenge
  const getChallenge = async (withLoading = true) => {
    if (withLoading) {
      setChallengeLoading(true)
    }
    setChallengeError(false)

    try {
      const result = await Promise.all([
        get(`/web/challenge/get?teamcontract_id=${oldChallengeContractId}`),
        get(
          `/web/challenge/get${
            isChallengeOver && contract ? "?teamcontract_id=" + contract.id : ""
          }`
        ),
      ])

      if (
        result[0].data &&
        result[0].data.title &&
        result[0].data.description
      ) {
        console.log("old challenge", result[0].data)
        setOldChallenge(result[0].data)
      }

      if (
        result[1].data &&
        result[1].data.title &&
        result[1].data.description
      ) {
        console.log("challenge", result[1].data)
        setChallenge(result[1].data)
      } else {
        // hide tutorial if challenge is not present (tutorial only works with challenge)
        setViewTutorial(false)
      }

      setChallengeLoading(false)
    } catch (e) {
      console.log("challenge error", e)
      setChallengeLoading(false)

      // hide tutorial if challenge is not present (tutorial only works with challenge)
      setViewTutorial(false)
      // setChallengeError(true)
    }
  }

  // get challenge savings
  const getSavings = async (withLoading = true) => {
    if (withLoading) {
      setSavingsLoading(true)
    }
    setSavingsError(false)

    try {
      const result = await Promise.all([
        get(`/web/savings/get?teamcontract_id=${oldChallengeContractId}`),
        get(
          `/web/savings/get${
            isChallengeOver && contract ? "?teamcontract_id=" + contract.id : ""
          }`
        ),
      ])

      console.log("old challenge savings", result[0].data)
      console.log("challenge savings", result[1].data)

      setOldChallengeSavings(result[0].data)
      setChallengeSavings(result[1].data)

      setSavingsLoading(false)
    } catch (e) {
      console.log("challenge savings error", e)
      setSavingsLoading(false)
      // setSavingsError(true)
    }
  }

  // initial fetch
  useEffect(() => {
    getChallenge()
    getSavings()
  }, [])

  return (
    <ChallengeContext.Provider
      value={{
        challengeLoading,
        challengeError,
        challenge,
        getChallenge,
        savingsLoading,
        savingsError,
        challengeSavings,
        getSavings,
        oldChallenge,
        oldChallengeSavings,
      }}
    >
      {children}
    </ChallengeContext.Provider>
  )
}
export { ChallengeController, ChallengeContext }
