import { Grid, LinearProgress, Stack } from "@mui/material"
import backgroundImageTop from "../../assets/images/landing-background-top.jpg"
import backgroundImageBottom from "../../assets/images/landing-background-bottom.png"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import AppearFromSide from "../animations/AppearFromSide"
import { colors } from "../../services/config/colors"
import MissionCardDesktop from "./desktop/MissionCardDesktop"
import FadeFromTop from "../animations/FadeFromTop"
import {
  contestRules,
  defaultBoxShadow,
  desktopColumnsGap,
  desktopMaxWidth,
} from "../../services/config/constants"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { MissionsContext } from "../../controllers/missions"
import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import {
  numberWithSeparators,
  scrollWindowToTop,
} from "../../services/utils/utils"
import MissionsTabs from "../missions/common/MissionsTabs"
import { MissionPeriod } from "../../services/config/enum"
import DailyMissionsCardDesktop from "./desktop/DailyMissionsCardDesktop"
import AlertInfo from "../global/common/AlertInfo"
import MissionInfoCardDesktop from "./desktop/MissionInfoCardDesktop"
import spaceshipImage from "../../assets/images/spaceship.svg"
import lockWhiteIcon from "../../assets/icons/lock-white.svg"
import nextChallengeBackground from "../../assets/images/next-challenge-background.png"
import missionsBackgroundImage from "../../assets/images/missions-background.png"
import recapDecorLeft from "../../assets/images/recap-decor-left.png"
import recapDecorRight from "../../assets/images/recap-decor-right.png"
import { MainContext } from "../../controllers/main"
import { UsersContext } from "../../controllers/users"
import { ChallengeContext } from "../../controllers/challenge"
import Prize from "../../models/prize"
import Appear from "../animations/Appear"
import LeaderboardListItem from "../performance/common/LeaderboardListItem"
import ChallengeRecapCardDesktop from "../challenge/desktop/ChallengeRecapCardDesktop"
import Button from "../global/common/Button"
import ChallengeRecapFeedback from "../feedbacks/common/ChallengeRecapFeedback"
import ExtraMissionCardDesktop from "./desktop/ExtraMissionCardDesktop"
import drawPrizeBackground from "../../assets/images/draw-prize-background.png"

const LandingDesktopRecap = ({
  prizes,
  recapFeedbackOpen,
  setRecapFeedbackOpen,
  userPosition,
}: {
  prizes: Prize[]
  recapFeedbackOpen: boolean
  setRecapFeedbackOpen: Dispatch<SetStateAction<boolean>>
  userPosition: number
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { viewTutorial, setCurrentMission, user } = useContext(MainContext)
  const { leaderboard, leaderboardNextToken, getLeaderboard } =
    useContext(UsersContext)
  const { challenge } = useContext(ChallengeContext)
  const { missions, selectedTab, setSelectedTab, completedMissionsCount } =
    useContext(MissionsContext)

  // what is a mission alert
  const [missionInfoAlertOpen, setMissionInfoAlertOpen] =
    useState<boolean>(false)

  // come back tomorrow alert
  const [comeBackAlertOpen, setComeBackAlertOpen] = useState<boolean>(false)

  // leaderboard loading and next token refs
  const loadingRef = useRef<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const nextToken = useRef<string | null>(leaderboardNextToken)

  // update next token ref when state changes
  useEffect(() => {
    nextToken.current = leaderboardNextToken
  }, [leaderboardNextToken])

  return (
    <Stack
      alignItems="center"
      style={{
        width: "100%",
        position: "relative",
      }}
    >
      {/* header */}
      <div
        style={{
          width: "100%",
          height: 746,
          minHeight: 746,
          paddingTop: 177,
          position: "relative",
        }}
      >
        <img
          src={backgroundImageTop}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "bottom",
            position: "absolute",
            top: 0,
          }}
          alt=""
        />
        <PaddingContainerDesktop style={{ position: "relative" }}>
          <Stack
            direction="row"
            style={{
              width: "100%",
              maxWidth: desktopMaxWidth,
              gap: desktopColumnsGap,
            }}
          >
            {/* first header half */}
            <Stack style={{ width: "50%" }}>
              <AppearFromSide delay={0.05} fade>
                <Title
                  fontSize={40}
                  lineHeight="46px"
                  style={{
                    letterSpacing: "-0.4px",
                    fontStyle: "italic",
                    textTransform: "uppercase",
                  }}
                  component="h1"
                  color={colors.primary}
                >
                  <Trans i18nKey="partner_challenge_ended" />
                </Title>
              </AppearFromSide>
              <AppearFromSide delay={0.1} fade>
                <Text
                  fontSize={24}
                  lineHeight="40px"
                  fontWeight={400}
                  style={{ marginTop: 40 }}
                >
                  <Trans
                    i18nKey="challenge_ended_description"
                    values={{
                      n: challenge
                        ? numberWithSeparators(challenge.currentAmount)
                        : "0",
                    }}
                  />
                </Text>
              </AppearFromSide>
            </Stack>
            {/* second header half */}
            <Stack justifyContent="center" style={{ width: "50%" }}>
              {challenge ? (
                <AppearFromSide
                  x={100}
                  delay={0.05}
                  fade
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Stack style={{ width: "75%" }}>
                    <ChallengeRecapCardDesktop />
                    <Button
                      title={t("discover_the_initiative")}
                      width={300}
                      style={{
                        marginTop: 38,
                        borderRadius: 30,
                        alignSelf: "center",
                      }}
                      onClick={() => {
                        navigate("/challenge")
                      }}
                    >
                      {t("discover_the_initiative")}
                    </Button>
                  </Stack>
                </AppearFromSide>
              ) : null}
            </Stack>
          </Stack>
        </PaddingContainerDesktop>
      </div>
      {/* challenge winners */}
      <PaddingContainerDesktop style={{ position: "relative" }}>
        {/* decors */}
        <img
          src={recapDecorLeft}
          style={{ width: 249, position: "absolute", top: -22, left: 0 }}
          alt=""
        />
        <img
          src={recapDecorRight}
          style={{ width: 357, position: "absolute", top: 300, right: -100 }}
          alt=""
        />
        <Stack
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            position: "relative",
          }}
        >
          <FadeFromTop>
            <Title
              fontSize={40}
              lineHeight="46px"
              component="h2"
              color={colors.primary}
              textAlign="center"
              style={{ fontStyle: "italic" }}
            >
              <Trans i18nKey="discover_winners" />
            </Title>
          </FadeFromTop>
          <Appear scale={false} style={{ width: "100%", marginTop: 55 }}>
            <Stack
              direction="row"
              style={{
                width: "100%",
                height: 347,
                gap: desktopColumnsGap,
              }}
            >
              {prizes
                .filter((prize) => !prize.draw)
                .map((prize) => (
                  <Stack
                    key={prize.title}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 12,
                      boxShadow: defaultBoxShadow,
                      backgroundColor: colors.backgroundWhite,
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: 180,
                        backgroundImage: `url(${prize.image})`,
                        backgroundSize: "148%",
                        backgroundPosition: "90%",
                        position: "relative",
                      }}
                    >
                      <div
                        className="center"
                        style={{
                          width: "fit-content",
                          height: 30,
                          backgroundColor: colors.primary,
                          borderRadius: 22,
                          paddingInline: 11,
                          paddingTop: 3,
                          position: "absolute",
                          left: 15,
                          bottom: 13,
                        }}
                      >
                        <Text
                          fontSize={18}
                          fontWeight={500}
                          color={colors.textWhite}
                        >
                          {prize.heading}
                        </Text>
                      </div>
                    </div>
                    <Stack style={{ padding: 16, gap: 12 }}>
                      <Title
                        fontSize={22}
                        lineHeight="30px"
                        style={{ height: 90 }}
                      >
                        {prize.title}
                      </Title>
                      <Title
                        fontSize={18}
                        fontWeight={500}
                        color={colors.textSecondary}
                      >
                        {prize.value}
                      </Title>
                    </Stack>
                  </Stack>
                ))}
              <div
                style={{
                  width: 512,
                  minWidth: 512,
                  maxWidth: 512,
                  height: "100%",
                  borderRadius: 12,
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <Stack
                  gap={1}
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "3px solid #EFF5F2",
                    borderRadius: 12,
                    overflowY: "auto",
                    backgroundColor: colors.backgroundWhite,
                    paddingTop: 15,
                    position: "relative",
                  }}
                  onScroll={async (e) => {
                    if (
                      e.currentTarget.scrollTop >=
                        e.currentTarget.scrollHeight - 400 &&
                      nextToken.current &&
                      !loadingRef.current &&
                      !leaderboard.some((item) => item.position > 5)
                    ) {
                      loadingRef.current = true
                      setLoading(true)

                      await getLeaderboard(false)

                      loadingRef.current = false
                      setLoading(false)
                    }
                  }}
                >
                  <Stack
                    direction="row"
                    style={{
                      width: "100%",
                      height: 26,
                      minHeight: 26,
                      paddingInline: 22,
                    }}
                  >
                    <Text
                      fontSize={20}
                      lineHeight="26px"
                      fontWeight={500}
                      color={colors.textSecondary}
                      style={{ flex: 1 }}
                    >
                      {t("winners")}*
                    </Text>
                    <Text
                      fontSize={20}
                      lineHeight="26px"
                      fontWeight={500}
                      color={colors.textSecondary}
                    >
                      {t("points_extended")}
                    </Text>
                  </Stack>
                  {leaderboard.map((item, index) =>
                    item.position <= 5 ? (
                      <LeaderboardListItem
                        key={item.sub}
                        place={item.position}
                        name={item.first_name}
                        points={item.points}
                        profileImage={item.profileImage}
                        isUser={item.sub === user?.sub}
                        height={67}
                      />
                    ) : null
                  )}
                </Stack>
                {loading && (
                  <LinearProgress
                    style={{
                      width: "100%",
                      borderRadius: 14,
                      position: "absolute",
                      bottom: 0,
                    }}
                  />
                )}
              </div>
            </Stack>
          </Appear>
          <Text fontSize={14} fontWeight={400} style={{ marginTop: 26 }}>
            *Tra i partecipanti a pari merito, entro il 20 dicembre, si
            procederà a un'estrazione per l'assegnazione del premio come
            previsto da{" "}
            <span
              role="button"
              style={{
                fontWeight: 700,
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => {
                window.open(contestRules)
              }}
            >
              regolamento
            </span>
            .
          </Text>
          {/* draw prize */}
          {prizes.some((prize) => prize.draw) ? (
            <Stack
              direction="row"
              alignItems="center"
              style={{
                width: "100%",
                height: 320,
                backgroundImage: `url(${drawPrizeBackground})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                marginTop: 26,
                paddingInline: 28,
                gap: 50,
              }}
            >
              <Stack>
                <Stack
                  key={prizes.find((prize) => prize.draw)!.title}
                  style={{
                    width: 480,
                    height: 230,
                    borderRadius: 12,
                    boxShadow: defaultBoxShadow,
                    backgroundColor: colors.secondary,
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: 105,
                      minHeight: 105,
                      backgroundImage: `url(${
                        prizes.find((prize) => prize.draw)!.image
                      })`,
                      backgroundSize: "148%",
                      backgroundPosition: "90%",
                      position: "relative",
                    }}
                  >
                    <div
                      className="center"
                      style={{
                        width: "fit-content",
                        height: 30,
                        backgroundColor: colors.primary,
                        borderRadius: 22,
                        paddingInline: 11,
                        paddingTop: 3,
                        position: "absolute",
                        left: 15,
                        bottom: 13,
                      }}
                    >
                      <Text
                        fontSize={18}
                        fontWeight={500}
                        color={colors.textWhite}
                      >
                        {prizes.find((prize) => prize.draw)!.heading}
                      </Text>
                    </div>
                  </div>
                  <Stack style={{ padding: 16, gap: 12 }}>
                    <Title
                      fontSize={22}
                      lineHeight="30px"
                      color={colors.textWhite}
                    >
                      {prizes.find((prize) => prize.draw)!.title}
                    </Title>
                    <Title
                      fontSize={18}
                      fontWeight={500}
                      color={colors.textWhite}
                    >
                      {prizes.find((prize) => prize.draw)!.value}
                    </Title>
                  </Stack>
                </Stack>
                <Text
                  fontSize={14}
                  fontWeight={400}
                  color={colors.primary}
                  style={{ marginTop: 16 }}
                >
                  <span
                    role="button"
                    style={{
                      fontWeight: 700,
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() => {
                      window.open(contestRules)
                    }}
                  >
                    Leggi qui
                  </span>{" "}
                  il regolamento completo.
                </Text>
              </Stack>
              <Stack gap={2} alignItems="center">
                <Title
                  textAlign="center"
                  color={colors.primary}
                  style={{ fontStyle: "italic" }}
                >
                  SCOPRI I 3 FORTUNATI VINCITORI DEL PREMIO AD ESTRAZIONE
                </Title>
                <Text textAlign="center" fontWeight={400}>
                  ESTRAZIONE ENTRO IL:
                </Text>
                <div
                  className="center"
                  style={{
                    width: 300,
                    height: 65,
                    backgroundColor: colors.primary,
                    borderRadius: 26,
                  }}
                >
                  <Title
                    color={colors.textWhite}
                    style={{ textTransform: "uppercase", marginTop: 7 }}
                  >
                    20 dicembre
                  </Title>
                </div>
              </Stack>
            </Stack>
          ) : null}
        </Stack>
      </PaddingContainerDesktop>
      {/* missions */}
      <FadeFromTop
        style={{
          width: "100%",
          marginTop: 92,
          paddingTop: 40,
          position: "relative",
        }}
      >
        <img
          src={missionsBackgroundImage}
          style={{
            width: "100%",
            maxHeight: 1800,
            position: "absolute",
            top: 0,
          }}
          alt=""
        />
        <Title
          fontSize={40}
          lineHeight="46px"
          component="h2"
          color={colors.primary}
          textAlign="center"
          style={{
            fontStyle: "italic",
            textTransform: "uppercase",
            position: "relative",
          }}
        >
          <Trans i18nKey="keep_completing_missions" />
        </Title>
      </FadeFromTop>
      {/* tabs */}
      {missions.length ? (
        <FadeFromTop style={{ width: "100%", marginTop: 120 }} delay={0.05}>
          <PaddingContainerDesktop>
            <MissionsTabs
              tabs={[t("all"), t("today"), t("week"), t("special")]}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              style={{
                width: "100%",
                maxWidth: 1050,
              }}
              infoButtonOnClick={() => {
                setMissionInfoAlertOpen(true)
              }}
            />
          </PaddingContainerDesktop>
        </FadeFromTop>
      ) : (
        <FadeFromTop style={{ width: "100%", marginTop: 36 }} delay={0.05}>
          <PaddingContainerDesktop>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              style={{
                width: "100%",
                maxWidth: 1050,
                height: 60,
                position: "relative",
              }}
            >
              <Text fontSize={26} fontWeight={400}>
                {t("all_missions_completed")}
              </Text>
            </Stack>
          </PaddingContainerDesktop>
        </FadeFromTop>
      )}
      {/* missions grid */}
      {missions.length ? (
        <FadeFromTop style={{ marginTop: 40, width: "100%" }} delay={0.1}>
          <PaddingContainerDesktop>
            {/* missions grid */}
            <Grid
              id="missions-desktop-container"
              aria-label={t("available_missions")}
              container
              columns={3}
              columnSpacing={`${desktopColumnsGap}px`}
              rowSpacing="74px"
              style={{
                width: `calc(100% + ${desktopColumnsGap}px)`,
                maxWidth: 1080,
              }}
              role="tabpanel"
            >
              {missions
                .filter((mission) => !mission.pointPerActivity)
                .filter((mission) => {
                  switch (selectedTab) {
                    case 0:
                      return mission
                    case 1:
                      if (mission.period === MissionPeriod.DAILY) {
                        return mission
                      } else {
                        return null
                      }
                    case 2:
                      if (mission.period === MissionPeriod.WEEKLY) {
                        return mission
                      } else {
                        return null
                      }
                    case 3:
                      if (mission.period === MissionPeriod.CUSTOM) {
                        return mission
                      } else {
                        return null
                      }
                  }

                  return null
                })
                .map((mission, index) => (
                  <Grid key={mission.missionId + selectedTab} item xs={1}>
                    <MissionCardDesktop
                      mission={mission}
                      index={index}
                      color={mission.color}
                      title={mission.title}
                      titleColor={mission.titleColor}
                      goButtonColor={mission.goButtonColor}
                      image={mission.image}
                      current={mission.current}
                      target={mission.target}
                      points={mission.points}
                      satisfied={mission.satisfied}
                      missionType={mission.missionType}
                      missionSubType={mission.missionSubType}
                      missionTypeId={mission.missionTypeId}
                      period={mission.period}
                      onClick={() => {
                        scrollWindowToTop()

                        setCurrentMission(mission)
                        localStorage.setItem(
                          "currentMission",
                          JSON.stringify(mission)
                        )

                        if (
                          mission.navigationPath ===
                          "/performance/footprint/form"
                        ) {
                          navigate(mission.navigationPath, {
                            state: { formId: mission.missionTypeId },
                          })
                        } else {
                          navigate(mission.navigationPath)
                        }
                      }}
                      selectedTab={selectedTab}
                      end={mission.end}
                      currentPerDay={mission.currentPerDay}
                      targetPerDay={mission.targetPerDay}
                      setComeBackAlertOpen={setComeBackAlertOpen}
                      updatedAt={mission.updatedAt}
                      isCorrect={mission.isCorrect}
                    />
                  </Grid>
                ))}
              {selectedTab === 1 ? (
                <Grid item xs={1}>
                  {missions.filter(
                    (mission) =>
                      mission.period === MissionPeriod.DAILY &&
                      mission.satisfied
                  ).length === 2 ? (
                    <MissionInfoCardDesktop
                      missionsCompleted={2}
                      missionsPeriod={MissionPeriod.DAILY}
                    />
                  ) : (
                    <DailyMissionsCardDesktop />
                  )}
                </Grid>
              ) : selectedTab === 2 ? (
                <Grid item xs={1}>
                  <MissionInfoCardDesktop
                    key={selectedTab}
                    missionsCompleted={
                      missions.filter(
                        (mission) =>
                          mission.period === MissionPeriod.WEEKLY &&
                          mission.satisfied
                      ).length
                    }
                    missionsPeriod={MissionPeriod.WEEKLY}
                  />
                </Grid>
              ) : selectedTab === 3 ? (
                <Grid item xs={1}>
                  <MissionInfoCardDesktop
                    key={selectedTab}
                    missionsCompleted={
                      missions.filter(
                        (mission) =>
                          mission.period === MissionPeriod.CUSTOM &&
                          mission.satisfied
                      ).length
                    }
                    missionsPeriod={MissionPeriod.CUSTOM}
                  />
                </Grid>
              ) : null}
            </Grid>
          </PaddingContainerDesktop>
        </FadeFromTop>
      ) : (
        <FadeFromTop delay={0.1} style={{ marginTop: 80 }}>
          <PaddingContainerDesktop>
            <img src={spaceshipImage} style={{ width: 330 }} alt="" />
          </PaddingContainerDesktop>
        </FadeFromTop>
      )}
      {/* pointPerActivity missions */}
      {missions.filter((mission) => mission.pointPerActivity).length ? (
        <FadeFromTop delay={0.1} style={{ width: "100%", marginTop: 74 }}>
          <PaddingContainerDesktop>
            <Stack gap={2} style={{ width: "100%", maxWidth: desktopMaxWidth }}>
              {missions
                .filter((mission) => mission.pointPerActivity)
                .map((mission) => (
                  <ExtraMissionCardDesktop
                    key={mission.missionId}
                    mission={mission}
                  />
                ))}
            </Stack>
          </PaddingContainerDesktop>
        </FadeFromTop>
      ) : null}
      {/* next challenge */}
      <PaddingContainerDesktop style={{ marginTop: 300, position: "relative" }}>
        <Stack
          direction="row"
          alignItems="center"
          style={{
            width: "100%",
            maxWidth: desktopMaxWidth,
            gap: 55,
          }}
        >
          <Stack
            alignItems="center"
            style={{
              width: 500,
              minWidth: 500,
              height: 415,
              borderRadius: 40,
              border: "3px solid #ACC6B7",
              paddingTop: 50,
              paddingInline: 32,
              backgroundImage: `url(${nextChallengeBackground})`,
              backgroundSize: "cover",
            }}
          >
            <Title
              fontSize={30}
              lineHeight="32px"
              color={colors.primary}
              textAlign="center"
              style={{ textTransform: "uppercase" }}
            >
              Partecipa alla nuova challenge
            </Title>
            <Text
              fontSize={25}
              fontWeight={400}
              style={{ textTransform: "uppercase", marginTop: 32 }}
            >
              Sblocca:
            </Text>
            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              style={{
                height: 65,
                backgroundColor: colors.primary,
                borderRadius: 26,
                marginTop: 90,
                paddingInline: 24,
              }}
            >
              <img
                src={lockWhiteIcon}
                style={{ width: 40, height: 40 }}
                alt=""
              />
              <Title
                color={colors.textWhite}
                style={{ textTransform: "uppercase", marginTop: 7 }}
              >
                11 dicembre
              </Title>
            </Stack>
          </Stack>
          <Stack gap={3}>
            <Title
              fontSize={40}
              color={colors.primary}
              style={{ fontStyle: "italic", textTransform: "uppercase" }}
            >
              AWorld & Levissima di nuovo insieme per il pianeta
            </Title>
            <Text fontSize={26} lineHeight="40px" fontWeight={400}>
              L’impegno di Levissima ed AWorld non si ferma qui. Una nuova
              challenge più sfidante con un nuovo obiettivo sostenibile partirà
              a gennaio.
            </Text>
          </Stack>
        </Stack>
      </PaddingContainerDesktop>
      {/* bottom part */}
      <Stack
        alignItems="center"
        style={{
          width: "100%",
          height: 449,
          backgroundImage: `url(${backgroundImageBottom})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          position: "relative",
          marginTop: -100,
        }}
      />
      {/* what is a mission info alert */}
      <AlertInfo
        open={missionInfoAlertOpen}
        setOpen={setMissionInfoAlertOpen}
        title={t("what_are_missions_extended")}
        description={t("what_is_a_mission_description")}
        descriptionAlign="center"
      />
      {/* come back tomorrow info alert */}
      <AlertInfo
        open={comeBackAlertOpen}
        setOpen={setComeBackAlertOpen}
        title={t("thats_all_for_today")}
        description={t("come_back_tomorrow_to_continue")}
        descriptionAlign="center"
      />
      {/* challenge recap feedback */}
      <ChallengeRecapFeedback
        open={recapFeedbackOpen}
        handleClose={() => {
          localStorage.setItem("recapFeedbackSecondChallenge", "true")
          setRecapFeedbackOpen(false)
        }}
        position={userPosition}
        prize={userPosition === 1 ? prizes[0] : prizes[1]}
      />
      {/* tutorial backdrop */}
      {viewTutorial && (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            position: "absolute",
            top: 0,
            zIndex: 100,
          }}
        />
      )}
    </Stack>
  )
}

export default LandingDesktopRecap
