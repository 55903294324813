import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
} from "react"
import { colors } from "../../../services/config/colors"
import Button from "../../global/common/Button"
import { useTranslation } from "react-i18next"
import { MainContext } from "../../../controllers/main"
import { Fade, Modal } from "@mui/material"

const FeedbackContainer = ({
  open,
  setOpen,
  children,
  withCloseButton = true,
  withPadding = true,
  withBackground = true,
  forTutorial = false,
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  children?: ReactNode
  withCloseButton?: boolean
  withPadding?: boolean
  withBackground?: boolean
  forTutorial?: boolean
}) => {
  const { t } = useTranslation()
  const { isMobile } = useContext(MainContext)

  // can close (avoid click spamming)
  const canClose = useRef<boolean>(false)

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        canClose.current = true
      }, 500)
    } else {
      canClose.current = false
    }
  }, [open])

  return (
    <Modal
      open={open}
      hideBackdrop={forTutorial}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
      }}
    >
      <Fade in={open}>
        <div
          style={{
            width: "100%",
            height: "100%",
            maxHeight: "100%",
            overflowY: "scroll",
            backgroundColor: forTutorial
              ? "rgba(0, 0, 0, 0)"
              : withBackground
              ? colors.backgroundWhite
              : "rgba(0, 0, 0, 0.5)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: withPadding ? 42 : 0,
            paddingBottom: !withPadding ? 0 : withCloseButton ? 120 : 42,
          }}
        >
          {children}
          {withCloseButton && (
            <Button
              width={isMobile ? undefined : 330}
              style={{
                position: "absolute",
                bottom: 22,
                minWidth: 200,
                zIndex: 10,
              }}
              onClick={() => {
                if (canClose.current) {
                  setOpen(false)
                }
              }}
              title={t("close")}
            >
              {t("close")}
            </Button>
          )}
        </div>
      </Fade>
    </Modal>
  )
}

export default FeedbackContainer
