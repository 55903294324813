import { colors } from "../../services/config/colors"
import awLogo from "../../assets/images/aw-logo.svg"
import { LinearProgress } from "@mui/material"
import "../../styles/loading.css"
import { useTranslation } from "react-i18next"
import { useContext, useEffect } from "react"
import { MainContext } from "../../controllers/main"
import Text from "../../components/global/common/Text"

const Loading = ({
  title,
  determinate,
  progress,
}: {
  title: string
  determinate?: boolean
  progress?: number
}) => {
  const { t } = useTranslation()
  const { windowHeight, setVisualizingLoadingPage } = useContext(MainContext)

  // tell other components this page is being visualized
  useEffect(() => {
    setVisualizingLoadingPage(true)

    return () => {
      setVisualizingLoadingPage(false)
    }
  }, [])

  return (
    <div
      style={{
        width: "100%",
        height: windowHeight,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: colors.backgroundWhite,
      }}
      aria-label={t("loading_indicator")}
      role="group"
      tabIndex={0}
    >
      <img
        src={awLogo}
        style={{ width: "38%", maxWidth: 165 }}
        alt={t("aworld_logo_alt")}
      />
      <LinearProgress
        variant={determinate ? "determinate" : "indeterminate"}
        value={progress}
        style={{
          width: "34%",
          maxWidth: 157,
          marginTop: 16,
          borderRadius: 100,
        }}
        classes={{
          colorPrimary: "linear-progress-background",
          barColorPrimary: "linear-progress-bar",
        }}
      />
      <Text fontSize={11} fontWeight={400} style={{ marginTop: 8 }}>
        {title}
      </Text>
    </div>
  )
}

export default Loading
