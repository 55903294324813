import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import { deleteMethod, get } from "../services/api/api"
import { parseGroupData } from "../services/utils/parseFunctions"
import User from "../models/user"
import { MainContext } from "./main"
import { oldChallengeContractId } from "../services/config/constants"

interface UsersContextInterface {
  leaderboardLoading: boolean
  leaderboardUpdating: boolean
  leaderboardError: boolean
  leaderboard: User[]
  leaderboardNextToken: string | null
  getLeaderboard: (
    withLoading?: boolean,
    withNextToken?: boolean
  ) => Promise<boolean>
  deleteUser: () => Promise<boolean>
}

const UsersContext = createContext<UsersContextInterface>({
  leaderboardLoading: true,
  leaderboardUpdating: false,
  leaderboardError: false,
  leaderboard: [],
  leaderboardNextToken: null,
  getLeaderboard: async () => true,
  deleteUser: async () => true,
})

const UsersController = ({ children }: { children: ReactNode }) => {
  const { isChallengeOver, contract, isOldChallenge } = useContext(MainContext)

  // loadings
  const [leaderboardLoading, setLeaderboardLoading] = useState<boolean>(true)
  const [leaderboardUpdating, setLeaderboardUpdating] = useState<boolean>(true)

  // errors
  const [leaderboardError, setLeaderboardError] = useState<boolean>(false)

  // states
  const [leaderboard, setLeaderboard] = useState<User[]>([])
  const [leaderboardNextToken, setLeaderboardNextToken] = useState<
    string | null
  >(null)

  // get leaderboard
  const getLeaderboardFirstTime = async () => {
    setLeaderboardLoading(true)
    setLeaderboardUpdating(true)

    try {
      const dataToSet = []
      let nextToken
      let i = 0

      do {
        const result: any = await (isOldChallenge
          ? get(
              nextToken
                ? `/web/mission/point/leaderboard?nextToken=${nextToken}&teamcontract_id=${oldChallengeContractId}`
                : `/web/mission/point/leaderboard?teamcontract_id=${oldChallengeContractId}`
            )
          : get(
              nextToken
                ? `/web/mission/point/leaderboard?nextToken=${nextToken}${
                    isChallengeOver && contract
                      ? "&teamcontract_id=" + contract.id
                      : ""
                  }`
                : `/web/mission/point/leaderboard${
                    isChallengeOver && contract
                      ? "?teamcontract_id=" + contract.id
                      : ""
                  }`
            ))

        if (result.data.items) {
          dataToSet.push(...result.data.items)
        } else {
          break
        }
        nextToken = result.data.nextToken

        i++
      } while (i < 4 && nextToken)

      // parse data
      dataToSet.forEach((item: any) => {
        parseGroupData(item)
      })

      console.log("leaderboard", dataToSet)
      setLeaderboard(dataToSet)
      setLeaderboardNextToken(nextToken)

      setLeaderboardLoading(false)
      setLeaderboardUpdating(false)
      setLeaderboardError(false)

      return true
    } catch (e) {
      console.log("leaderboard error", e)
      setLeaderboardUpdating(false)
      setLeaderboardLoading(false)

      setLeaderboardError(true)

      return false
    }
  }

  const getLeaderboard = async (withLoading = true, withNextToken = true) => {
    if (withLoading) {
      setLeaderboardLoading(true)
    }
    setLeaderboardUpdating(true)

    try {
      const { data } = await (isOldChallenge
        ? get(
            leaderboardNextToken && withNextToken
              ? `/web/mission/point/leaderboard?nextToken=${leaderboardNextToken}&teamcontract_id=${oldChallengeContractId}`
              : `/web/mission/point/leaderboard?teamcontract_id=${oldChallengeContractId}`
          )
        : get(
            leaderboardNextToken && withNextToken
              ? `/web/mission/point/leaderboard?nextToken=${leaderboardNextToken}${
                  isChallengeOver && contract
                    ? "&teamcontract_id=" + contract.id
                    : ""
                }`
              : `/web/mission/point/leaderboard${
                  isChallengeOver && contract
                    ? "?teamcontract_id=" + contract.id
                    : ""
                }`
          ))

      // parse data
      if (data.items) {
        data.items.forEach((item: any) => {
          parseGroupData(item)
        })
      } else {
        data.items = []
      }

      let dataToSet: User[] =
        leaderboardNextToken && withNextToken
          ? [...leaderboard, ...data.items]
          : data.items

      console.log("leaderboard", dataToSet)
      setLeaderboard(dataToSet)
      setLeaderboardNextToken(data.nextToken)

      setLeaderboardLoading(false)
      setLeaderboardUpdating(false)
      setLeaderboardError(false)

      return true
    } catch (e) {
      console.log("leaderboard error", e)
      setLeaderboardUpdating(false)
      setLeaderboardLoading(false)

      setLeaderboardError(true)

      return false
    }
  }

  // delete user
  const deleteUser = async () => {
    try {
      await deleteMethod("/web/user/delete")

      return true
    } catch (e) {
      console.log("user delete error", e)

      return false
    }
  }

  // initial fetch
  useEffect(() => {
    getLeaderboardFirstTime()
  }, [])

  return (
    <UsersContext.Provider
      value={{
        leaderboardLoading,
        leaderboardUpdating,
        leaderboardError,
        leaderboard,
        leaderboardNextToken,
        getLeaderboard,
        deleteUser,
      }}
    >
      {children}
    </UsersContext.Provider>
  )
}
export { UsersController, UsersContext }
