import { Stack } from "@mui/material"
import rocketWhiteIcon from "../../../assets/icons/rocket-white.svg"
import Title from "../../global/common/Title"
import { colors } from "../../../services/config/colors"
import { CSSProperties, useContext } from "react"
import { MainContext } from "../../../controllers/main"

const BoostBadge = ({ style }: { style?: CSSProperties }) => {
  const { isMobile } = useContext(MainContext)

  return (
    <Stack
      direction="row"
      alignItems="center"
      style={{
        width: "fit-content",
        height: isMobile ? "100%" : 40,
        borderRadius: 4,
        paddingInline: isMobile ? 8 : 10,
        backgroundColor: "#5C997A",
        gap: isMobile ? 6 : 6.5,
        ...style,
      }}
    >
      <img src={rocketWhiteIcon} style={{ width: isMobile ? 15 : 17 }} alt="" />
      <Title
        fontSize={isMobile ? 14 : 18}
        color={colors.textWhite}
        style={{ marginTop: isMobile ? 4 : 6 }}
      >
        Boost
      </Title>
    </Stack>
  )
}

export default BoostBadge
