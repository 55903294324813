import { motion, useReducedMotion } from "framer-motion"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { MissionsContext } from "../../../controllers/missions"
import { useTranslation } from "react-i18next"
import Mission from "../../../models/mission"
import extraActMissionImage from "../../../assets/images/extraMissions/act-extra-mobile.png"
import extraActMissionCompletedImage from "../../../assets/images/extraMissions/act-extra-mobile-completed.png"
import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../../services/config/colors"
import Title from "../../global/common/Title"
import { daysBetweenDates } from "../../../services/utils/utils"
import PointsBadge from "../../global/common/PointsBadge"
import Text from "../../global/common/Text"
import checkWhiteIcon from "../../../assets/icons/check-white.svg"

const ExtraMissionCardMobile = ({
  index,
  slidesLength,
  mission,
  setBottomSheetOpen,
  setCarouselControlsHidden,
  onClick,
}: {
  index: number
  slidesLength: number
  mission: Mission
  setBottomSheetOpen: Dispatch<SetStateAction<boolean>>
  setCarouselControlsHidden: Dispatch<SetStateAction<boolean>>
  onClick: () => void
}) => {
  const { t } = useTranslation()
  const shouldReduceMotion = useReducedMotion()
  const { currentSlide } = useContext(MissionsContext)

  // animation
  const [cardOpen, setCardOpen] = useState<boolean>(false)

  return (
    <motion.div
      style={{
        width: 256,
        height: "100%",
        maxHeight: 340,
        display: "flex",
        justifyContent: "center",
      }}
      onClick={() => {
        if (
          Math.abs(currentSlide % slidesLength) === index &&
          !mission.satisfied
        ) {
          if (shouldReduceMotion) {
            onClick()
          } else {
            setBottomSheetOpen(false)
            setCarouselControlsHidden(true)
            setCardOpen(true)
            setTimeout(() => {
              onClick()
            }, 220)
          }
        }
      }}
      animate={cardOpen ? "open" : "closed"}
      variants={{
        open: { scale: 5 },
        closed: { scale: 1 },
      }}
      transition={{ scale: { type: "spring", duration: 0.5 } }}
      initial={false}
      aria-hidden={
        Math.abs(currentSlide % slidesLength) === index ? "false" : "true"
      }
    >
      <div
        style={{
          width: "100%",
          height: "calc(100% - 23px)",
          borderRadius: 20,
          backgroundColor: colors.backgroundWhite,
        }}
      >
        <ButtonBase
          aria-label={t("go_to_specific_mission", { mission: mission.title })}
          disabled={mission.satisfied}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 20,
            backgroundImage: `url(${
              mission.satisfied
                ? extraActMissionCompletedImage
                : extraActMissionImage
            })`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            boxShadow: "0px 4.8px 19px rgba(22, 59, 99, 0.20)",
            opacity: cardOpen ? 0 : 1,
            transition: "200ms",
            position: "relative",
          }}
        >
          {mission.satisfied ? (
            <Stack
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 20,
                paddingInline: 21,
                position: "relative",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "auto",
                  height: 28,
                  position: "absolute",
                  top: 19.5,
                  right: 21,
                  gap: 10,
                }}
              >
                {/* period badge */}
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{
                    width: "auto",
                    height: "100%",
                    paddingInline: 8,
                    backgroundColor: colors.backgroundWhite,
                    borderRadius: 4,
                    border: "1px solid " + colors.tertiary,
                    gap: 6,
                  }}
                >
                  <Title fontSize={14} color={colors.tertiary}>
                    {t("custom")}
                  </Title>
                </Stack>
              </Stack>
              <Title
                fontSize={16}
                lineHeight="20px"
                color={colors.textWhite}
                textAlign="center"
                style={{
                  marginTop: 74,
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  textTransform: "uppercase",
                }}
              >
                {t("mission_completed") + "!"}
              </Title>
              <Text
                fontSize={13}
                fontWeight={400}
                color={colors.textWhite}
                textAlign="center"
                style={{
                  width: "100%",
                  fontStyle: "italic",
                  position: "absolute",
                  left: 0,
                  bottom: 60,
                }}
              >
                {t("all_extra_points")}
              </Text>
              <div
                className="center"
                style={{
                  width: 36,
                  height: 36,
                  borderRadius: "100%",
                  backgroundColor: colors.missions.act,
                  position: "absolute",
                  bottom: 8,
                  left: 0,
                  right: 0,
                  marginInline: "auto",
                }}
              >
                <img src={checkWhiteIcon} style={{ width: 20 }} alt="" />
              </div>
            </Stack>
          ) : (
            <Stack
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 20,
                paddingInline: 21,
                position: "relative",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                style={{
                  width: "auto",
                  height: 28,
                  position: "absolute",
                  top: 19.5,
                  right: 21,
                  gap: 10,
                }}
              >
                {/* period badge */}
                <Stack
                  direction="row"
                  alignItems="center"
                  style={{
                    width: "auto",
                    height: "100%",
                    paddingInline: 8,
                    backgroundColor: colors.backgroundWhite,
                    borderRadius: 4,
                    border: "1px solid " + colors.tertiary,
                    gap: 6,
                  }}
                >
                  <Title fontSize={14} color={colors.tertiary}>
                    -
                    {daysBetweenDates(new Date(), new Date(mission.end!), true)}{" "}
                    {t("days")}
                  </Title>
                </Stack>
                {/* points badge */}
                <PointsBadge
                  points={mission.points}
                  pointPerActivity={mission.pointPerActivity}
                  style={{
                    height: "100%",
                  }}
                />
              </Stack>
              <Title
                fontSize={16}
                lineHeight="20px"
                style={{
                  marginTop: 64,
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {mission.title}
              </Title>
              <Text
                fontSize={13}
                fontWeight={400}
                textAlign="center"
                style={{
                  width: "100%",
                  fontStyle: "italic",
                  position: "absolute",
                  left: 0,
                  bottom: 60,
                }}
              >
                {t("up_to_n_points", { count: mission.target })}
              </Text>
              <Title
                fontSize={14}
                lineHeight="20px"
                color={colors.textWhite}
                textAlign="center"
                style={{
                  width: "100%",
                  textTransform: "uppercase",
                  textDecoration: "underline",
                  position: "absolute",
                  left: 0,
                  bottom: 14,
                }}
              >
                {t("act")}
              </Title>
            </Stack>
          )}
        </ButtonBase>
      </div>
    </motion.div>
  )
}

export default ExtraMissionCardMobile
