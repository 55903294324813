import {
  KeyboardEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import PerformanceMobile from "../../components/performance/PerformanceMobile"
import { MainContext } from "../../controllers/main"
import PerformanceDesktop from "../../components/performance/PerformanceDesktop"
import { registerPageEvent } from "../../services/utils/utils"
import { UsersContext } from "../../controllers/users"
import { t } from "i18next"
import { MissionsContext } from "../../controllers/missions"

const Performance = () => {
  const { isMobile, isChallengeOver, isOldChallenge, user } =
    useContext(MainContext)
  const { leaderboard, getLeaderboard } = useContext(UsersContext)
  const { getMissionsHistory, getCompletedMissionsCount } =
    useContext(MissionsContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("performance")
  }, [])

  // manage old challenge
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [displayedLeaderboardLoading, setDisplayedLeaderboardLoading] =
    useState<boolean>(false)

  useEffect(() => {
    const changeData = async () => {
      setDisplayedLeaderboardLoading(true)
      await Promise.all([
        getLeaderboard(false, false),
        getMissionsHistory(false, false),
        getCompletedMissionsCount(false),
      ])
      setDisplayedLeaderboardLoading(false)
    }

    if (!isFirstRender) {
      changeData()
    } else {
      setIsFirstRender(false)
    }
  }, [isOldChallenge])

  // old challenge tabs
  const tabs = useMemo(() => [t("active_challenge"), t("past_challenge")], [])
  const [selectedTab, setSelectedTab] = useState<number>(isOldChallenge ? 1 : 0)

  // focus tab content (accessibility)
  const focusTabContent = () => {
    const container = document.getElementById(
      "points-and-leaderboard-container"
    )!
    container.tabIndex = 0
    container.focus()
    setTimeout(() => container.removeAttribute("tabindex"), 500)
  }

  // refs for all tabs
  const tabRefs = useRef<HTMLButtonElement[]>([])

  // focus tab helper
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const focusTab = (index: number) => {
    const tab = tabRefs.current[index]
    if (tab) {
      tab.focus()
    }
  }

  // onKeyDown handler for tab elements
  const onKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    const count = tabs.length
    const nextTab = () => focusTab((selectedIndex + 1) % count)
    const prevTab = () => focusTab((selectedIndex - 1 + count) % count)
    const firstTab = () => focusTab(0)
    const lastTab = () => focusTab(count - 1)

    const keyMap = {
      ArrowRight: nextTab,
      ArrowLeft: prevTab,
      Home: firstTab,
      End: lastTab,
    }

    if (
      event.key === "ArrowRight" ||
      event.key === "ArrowLeft" ||
      event.key === "Home" ||
      event.key === "End"
    ) {
      const action = keyMap[event.key]
      if (action) {
        event.preventDefault()
        action()
      }
    }
  }

  // if user is using screen reader, based on "onFocusVisible" event
  const screenReaderEnabled = useRef<boolean>(false)

  // draw prizes banner
  const [showDrawPrizesBanner, setShowDrawPrizesBanner] = useState<boolean>(
    user &&
      user.points >= 400 &&
      !isChallengeOver &&
      !localStorage.getItem("drawPrizesBanner2Closed")
      ? true
      : false
  )

  // user position in leaderboard
  const userPosition = useMemo(() => {
    if (!user) {
      return 999
    }

    if (!leaderboard.some((item) => item.sub === user.sub)) {
      return 999
    }

    return leaderboard.find((item) => item.sub === user.sub)!.position
  }, [user, leaderboard])

  return isMobile ? (
    <PerformanceMobile
      setOldChallengeSelectedTab={setSelectedTab}
      displayedLeaderboardLoading={displayedLeaderboardLoading}
      showDrawPrizesBanner={showDrawPrizesBanner}
      setShowDrawPrizesBanner={setShowDrawPrizesBanner}
      userPosition={userPosition}
    />
  ) : (
    <PerformanceDesktop
      tabs={tabs}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      setSelectedIndex={setSelectedIndex}
      tabRefs={tabRefs}
      focusTabContent={focusTabContent}
      onKeyDown={onKeyDown}
      screenReaderEnabled={screenReaderEnabled}
      displayedLeaderboardLoading={displayedLeaderboardLoading}
      showDrawPrizesBanner={showDrawPrizesBanner}
      setShowDrawPrizesBanner={setShowDrawPrizesBanner}
      userPosition={userPosition}
    />
  )
}

export default Performance
