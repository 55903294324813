import ReactDOM from "react-dom/client"
import "./styles/index.css"
import "./styles/fonts.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { MainController } from "./controllers/main"
import { BrowserRouter } from "react-router-dom"
import { Shadows, ThemeProvider, createTheme } from "@mui/material"
import { colors } from "./services/config/colors"
import { MotionConfig } from "framer-motion"
import "./services/i18n/i18n"
import { isMainteinanceMode } from "./services/config/constants"
import Mainteinance from "./views/public/mainteinance"
// import ReactGA from "react-ga4"

// google analytics
// ReactGA.initialize("G-LY24Y362WK", {
//   testMode: process.env.REACT_APP_ENVIRONMENT === "dev",
// })

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <BrowserRouter>
    <ThemeProvider
      theme={createTheme({
        palette: {
          mode: "light",
          primary: {
            main: colors.primary,
          },
          secondary: {
            main: colors.primary,
          },
        },
        shadows: Array(25).fill("none") as Shadows,
      })}
    >
      <MotionConfig reducedMotion="user">
        <MainController>
          {isMainteinanceMode ? <Mainteinance /> : <App />}
        </MainController>
      </MotionConfig>
    </ThemeProvider>
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
