import { Location, Route, Routes } from "react-router-dom"
import Landing from "../../views/private/landing"
import Footprint from "../../views/private/footprint"
import ActionDetails from "../../views/private/actionDetails"
import ActionCalculations from "../../views/private/actionCalculations"
import Journey from "../../views/private/journey"
import Challenge from "../../views/private/challenge"
import Performance from "../../views/private/performance"
import Episode from "../../views/private/episode"
import ActionsExplore from "../../views/private/actionsExplore"
import DailyEpisode from "../../views/private/dailyEpisode"
import FootprintForm from "../../views/private/footprintForm"
import Leaderboard from "../../views/private/leaderboard"
import Missions from "../../views/private/missions"
import Settings from "../../views/private/settings"
import AvatarEdit from "../../views/private/avatarEdit"
import { useContext } from "react"
import { ChallengeContext } from "../../controllers/challenge"
import Privacy from "../../views/private/privacy"

const AppRoutes = ({ location }: { location: Location }) => {
  const { challenge } = useContext(ChallengeContext)

  return (
    <Routes location={location}>
      <Route path="/" element={<Landing />} />
      <Route path="/actions" element={<ActionsExplore />} />
      <Route path="/actions/:actionId" element={<ActionDetails />} />
      <Route
        path="/actions/:actionId/calculations"
        element={<ActionCalculations />}
      />
      <Route path="/journey" element={<Journey />} />
      <Route path="/journey/:episodeId/:slideId" element={<Episode />} />
      <Route path="/dailyepisode/:slideId" element={<DailyEpisode />} />
      {challenge ? <Route path="/challenge" element={<Challenge />} /> : null}
      <Route path="/performance" element={<Performance />} />
      <Route path="/performance/leaderboard" element={<Leaderboard />} />
      <Route path="/performance/missions" element={<Missions />} />
      <Route path="/performance/settings" element={<Settings />} />
      <Route path="/performance/settings/avataredit" element={<AvatarEdit />} />
      <Route path="/performance/settings/privacy" element={<Privacy />} />
      <Route path="/performance/footprint" element={<Footprint />} />
      <Route path="/performance/footprint/form" element={<FootprintForm />} />
    </Routes>
  )
}

export default AppRoutes
