import itFlagImage from "../../assets/images/flags/it.svg"
import enFlagImage from "../../assets/images/flags/en.svg"

// main config (prod values: false, false, false, false)
export const alwaysShowOnboarding = false
export const shouldFakeSignIn = false
export const shouldSkipChallengeOverCheck = false
export const isMainteinanceMode = false

// challenge config
// export const oldChallengeContractId =
//   process.env.REACT_APP_ENVIRONMENT === "dev"
//     ? "5b62b31f-095d-44dd-ad6c-6decbd265d93"
//     : "08e31b31-82a6-4854-8289-adbcc9bd3b66"
export const oldChallengeContractId =
  process.env.REACT_APP_ENVIRONMENT === "dev"
    ? "5b62b31f-095d-44dd-ad6c-6decbd265d93"
    : "08e31b31-82a6-4854-8289-adbcc9bd3b66"
export const shouldShowMilestone = false
export const challengeTargetToShowMilestone = 10000
export const milestoneAmount = "5k"
export const contestRules =
  "https://dist.aworld.click/integrations/levissima/files/contest-rules-3-challenge.pdf"

// config
export const shoudlShowApiLogs = false
export const maximumActionsPerTime = 10
export const mobileBreakpoint = 1280
export const accessibilityHeightThreshold = 350
export const onboardingElementsMaxWidth = 550
export const availableLanguages = [
  { code: "it", label: "italian", flag: itFlagImage },
  { code: "en", label: "english", flag: enFlagImage },
]
export const fallbackLanguage = "it"

// stories
export const numberOfRows = 43
export const numberOfColumns = 15
export const slideWidth = 360
export const slideHeight = 700

// dimensions
export const navBarHeight = 90
export const footerHeight = 106
export const desktopPadding = "8.984vw"
export const desktopMaxWidth = 1050
export const desktopActionsLogButtonWidth = "39%"
export const desktopActionsLogButtonMaxWidth = 557
export const desktopColumnsGap = 30

// data
export const italianFootprintAverage = 5.72

// utils
export const defaultBoxShadow = "0px 0px 24px rgba(17, 67, 97, 0.08)"
export const defaultBoxShadowDarker = "0px 0px 24px rgba(17, 67, 97, 0.16)"
