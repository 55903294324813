import { Skeleton, Stack } from "@mui/material"
import Text from "../../global/common/Text"
import { colors } from "../../../services/config/colors"
import { CSSProperties, useContext } from "react"
import { useTranslation } from "react-i18next"
import { MainContext } from "../../../controllers/main"
import { UsersContext } from "../../../controllers/users"

const LeaderboardTop3 = ({
  style,
  skeleton,
}: {
  style?: CSSProperties
  skeleton?: boolean
}) => {
  const { t } = useTranslation()
  const { isMobile, user } = useContext(MainContext)
  const { leaderboard } = useContext(UsersContext)

  return (
    <Stack
      direction="row"
      style={{
        width: "100%",
        height: isMobile ? 159 : 213,
        paddingInline: isMobile ? 16 : 22,
        ...style,
      }}
    >
      <Stack direction="row-reverse" style={{ width: "calc(100% / 3 * 2)" }}>
        {/* first position */}
        <Stack
          alignItems="center"
          justifyContent="flex-start"
          style={{ width: "calc(100% / 2)", height: "100%" }}
          aria-label={t("leaderboard_place", { place: 1 })}
        >
          {skeleton ? (
            <Skeleton
              variant="circular"
              style={{
                width: isMobile ? 90 : 110,
                height: isMobile ? 90 : 110,
              }}
            />
          ) : (
            <div
              style={{
                width: isMobile ? 90 : 110,
                height: isMobile ? 90 : 110,
                borderRadius: "100%",
                backgroundImage:
                  leaderboard[0] && leaderboard[0].profileImage
                    ? `url(${leaderboard[0].profileImage})`
                    : `url(https://cdn.aworld.io/users/default/profile.jpg)`,
                backgroundSize: "105%, 100%",
                backgroundPosition: "center",
                position: "relative",
              }}
            >
              {leaderboard[0] ? (
                <div
                  style={{
                    width: isMobile ? 25 : 30,
                    height: isMobile ? 25 : 30,
                    borderRadius: "100%",
                    backgroundColor: colors.primary,
                    position: "absolute",
                    top: isMobile ? 5 : 6,
                    left: isMobile ? -6 : -7,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    fontSize={isMobile ? 14 : 18}
                    fontWeight={700}
                    color={colors.textWhite}
                    ariaHidden
                  >
                    {leaderboard[0].position}
                  </Text>
                </div>
              ) : null}
            </div>
          )}
          {skeleton ? (
            <NameSkeleton />
          ) : (
            <Text
              fontSize={isMobile ? 14 : 18}
              lineHeight={isMobile ? "19px" : "23px"}
              fontWeight={700}
              style={{
                height: isMobile ? 19 : 23,
                marginTop: isMobile ? 3 : 6,
                maxWidth: "100%",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {leaderboard[0]
                ? leaderboard[0].sub === user!.sub
                  ? t("you")
                  : `${leaderboard[0].first_name} ${leaderboard[0].last_name}`
                : ""}
            </Text>
          )}
          {skeleton ? (
            <ExpSkeleton />
          ) : leaderboard[0] ? (
            <div
              style={{
                width: "auto",
                height: isMobile ? 22 : 40,
                borderRadius: isMobile ? 4 : 5,
                paddingInline: isMobile ? 6 : 10,
                border: isMobile
                  ? "1.2px solid " + colors.primary
                  : "1.5px solid " + colors.primary,
                marginTop: isMobile ? 5 : 8,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Text
                fontSize={isMobile ? 12 : 18}
                fontWeight={700}
                color={colors.primary}
              >
                {leaderboard[0].points} {t("points")}
              </Text>
            </div>
          ) : (
            <div
              style={{
                width: "auto",
                height: isMobile ? 22 : 40,
                marginTop: isMobile ? 5 : 8,
              }}
            />
          )}
        </Stack>
        {/* second position */}
        <Stack
          alignItems="center"
          justifyContent="flex-end"
          style={{ width: "calc(100% / 2)", height: "100%" }}
          aria-label={t("leaderboard_place", { place: 2 })}
        >
          {skeleton ? (
            <Skeleton
              variant="circular"
              style={{
                width: isMobile ? 70 : 85,
                height: isMobile ? 70 : 85,
              }}
            />
          ) : (
            <div
              style={{
                width: isMobile ? 70 : 85,
                height: isMobile ? 70 : 85,
                borderRadius: "100%",
                backgroundImage:
                  leaderboard[1] && leaderboard[1].profileImage
                    ? `url(${leaderboard[1].profileImage})`
                    : `url(https://cdn.aworld.io/users/default/profile.jpg)`,
                backgroundSize: "105%, 100%",
                backgroundPosition: "center",
                position: "relative",
              }}
            >
              {leaderboard[1] ? (
                <div
                  style={{
                    width: isMobile ? 20 : 25,
                    height: isMobile ? 20 : 25,
                    borderRadius: "100%",
                    backgroundColor: colors.primary,
                    position: "absolute",
                    top: isMobile ? 5 : 6,
                    left: isMobile ? -6 : -7,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    fontSize={isMobile ? 10 : 14}
                    fontWeight={700}
                    color={colors.textWhite}
                    ariaHidden
                  >
                    {leaderboard[1].position}
                  </Text>
                </div>
              ) : null}
            </div>
          )}
          {skeleton ? (
            <NameSkeleton />
          ) : (
            <Text
              fontSize={isMobile ? 14 : 18}
              lineHeight={isMobile ? "19px" : "23px"}
              fontWeight={700}
              style={{
                height: isMobile ? 19 : 23,
                marginTop: isMobile ? 3 : 6,
                maxWidth: "100%",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {leaderboard[1]
                ? leaderboard[1].sub === user!.sub
                  ? t("you")
                  : `${leaderboard[1].first_name} ${leaderboard[1].last_name}`
                : ""}
            </Text>
          )}
          {skeleton ? (
            <ExpSkeleton />
          ) : leaderboard[1] ? (
            <div
              style={{
                width: "auto",
                height: isMobile ? 22 : 40,
                borderRadius: isMobile ? 4 : 5,
                paddingInline: isMobile ? 6 : 10,
                border: isMobile
                  ? "1.2px solid " + colors.primary
                  : "1.5px solid " + colors.primary,
                marginTop: isMobile ? 5 : 8,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Text
                fontSize={isMobile ? 12 : 18}
                fontWeight={700}
                color={colors.primary}
              >
                {leaderboard[1].points} {t("points")}
              </Text>
            </div>
          ) : (
            <div
              style={{
                width: "auto",
                height: isMobile ? 22 : 40,
                marginTop: isMobile ? 5 : 8,
              }}
            />
          )}
        </Stack>
      </Stack>
      {/* third position */}
      <Stack
        alignItems="center"
        justifyContent="flex-end"
        style={{ width: "calc(100% / 3)", height: "100%" }}
        aria-label={t("leaderboard_place", { place: 3 })}
      >
        {skeleton ? (
          <Skeleton
            variant="circular"
            style={{
              width: isMobile ? 70 : 85,
              height: isMobile ? 70 : 85,
            }}
          />
        ) : (
          <div
            style={{
              width: isMobile ? 70 : 85,
              height: isMobile ? 70 : 85,
              borderRadius: "100%",
              backgroundImage:
                leaderboard[2] && leaderboard[2].profileImage
                  ? `url(${leaderboard[2].profileImage})`
                  : `url(https://cdn.aworld.io/users/default/profile.jpg)`,
              backgroundSize: "105%, 100%",
              backgroundPosition: "center",
              position: "relative",
            }}
          >
            {leaderboard[2] ? (
              <div
                style={{
                  width: isMobile ? 20 : 25,
                  height: isMobile ? 20 : 25,
                  borderRadius: "100%",
                  backgroundColor: colors.primary,
                  position: "absolute",
                  top: isMobile ? 5 : 6,
                  left: isMobile ? -6 : -7,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text
                  fontSize={isMobile ? 10 : 14}
                  fontWeight={700}
                  color={colors.textWhite}
                  ariaHidden
                >
                  {leaderboard[2].position}
                </Text>
              </div>
            ) : null}
          </div>
        )}
        {skeleton ? (
          <NameSkeleton />
        ) : (
          <Text
            fontSize={isMobile ? 14 : 18}
            lineHeight={isMobile ? "19px" : "23px"}
            fontWeight={700}
            style={{
              height: isMobile ? 19 : 23,
              marginTop: isMobile ? 3 : 6,
              maxWidth: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {leaderboard[2]
              ? leaderboard[2].sub === user!.sub
                ? t("you")
                : `${leaderboard[2].first_name} ${leaderboard[2].last_name}`
              : ""}
          </Text>
        )}
        {skeleton ? (
          <ExpSkeleton />
        ) : leaderboard[2] ? (
          <div
            style={{
              width: "auto",
              height: isMobile ? 22 : 40,
              borderRadius: isMobile ? 4 : 5,
              paddingInline: isMobile ? 6 : 10,
              border: isMobile
                ? "1.2px solid " + colors.primary
                : "1.5px solid " + colors.primary,
              marginTop: isMobile ? 5 : 8,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Text
              fontSize={isMobile ? 12 : 18}
              fontWeight={700}
              color={colors.primary}
            >
              {leaderboard[2].points} {t("points")}
            </Text>
          </div>
        ) : (
          <div
            style={{
              width: "auto",
              height: isMobile ? 22 : 40,
              marginTop: isMobile ? 5 : 8,
            }}
          />
        )}
      </Stack>
    </Stack>
  )
}

const NameSkeleton = () => {
  const { isMobile } = useContext(MainContext)

  return (
    <Skeleton
      variant="rounded"
      style={{
        width: 100,
        height: isMobile ? 19 : 23,
        marginTop: isMobile ? 3 : 6,
      }}
    />
  )
}

const ExpSkeleton = () => {
  const { isMobile } = useContext(MainContext)

  return (
    <Skeleton
      variant="rectangular"
      style={{
        width: 80,
        height: isMobile ? 22 : 40,
        borderRadius: isMobile ? 4 : 5,
        marginTop: isMobile ? 5 : 8,
      }}
    />
  )
}

export default LeaderboardTop3
