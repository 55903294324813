import { ButtonBase, Stack } from "@mui/material"
import {
  desktopMaxWidth,
  footerHeight,
  navBarHeight,
} from "../../services/config/constants"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import BreadcrumbDesktop from "../global/desktop/BreadcrumbDesktop"
import { colors } from "../../services/config/colors"
import AppearFromSide from "../animations/AppearFromSide"
import Title from "../global/common/Title"
import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import chevronRightPrimaryIcon from "../../assets/icons/chevron-right-primary.svg"
import { useContext, useState } from "react"
import { MainContext } from "../../controllers/main"
import Button from "../global/common/Button"
import AvatarButton from "./common/AvatarButton"
import { registerEvent, scrollWindowToTop } from "../../services/utils/utils"
import { ChallengeContext } from "../../controllers/challenge"
import Text from "../global/common/Text"
import Alert from "../global/common/Alert"
import { UsersContext } from "../../controllers/users"
import AlertFullScreenPDF from "../global/common/AlertFullScreenPDF"

const SettingsDesktop = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user, avatars, changeAvatar, setViewTutorial } =
    useContext(MainContext)
  const { challenge } = useContext(ChallengeContext)
  const { deleteUser } = useContext(UsersContext)

  // loading for avatar change
  const [loading, setLoading] = useState<boolean>(false)

  // selected avatar
  const [selectedAvatar, setSelectedAvatar] = useState<string>(
    user!.profileImage
  )

  // account delete alert open
  const [deleteAlertOpen, setDeleteAlertOpen] = useState<boolean>(false)

  // delete loading
  const [deletingUser, setDeletingUser] = useState<boolean>(false)

  // terms and privacy alerts
  const [termsAlertOpen, setTermsAlertOpen] = useState<boolean>(false)
  const [privacyAlertOpen, setPrivacyAlertOpen] = useState<boolean>(false)

  return (
    <Stack
      style={{
        width: "100%",
        minHeight: `calc(100% - ${footerHeight}px)`,
      }}
    >
      <Stack style={{ width: "100%", backgroundColor: colors.backgroundWhite }}>
        {/* navbar background */}
        <div
          style={{
            width: "100%",
            height: navBarHeight,
            backgroundColor: colors.background,
          }}
        />
        {/* breadcrumb */}
        <BreadcrumbDesktop
          items={[
            {
              label: t("performance"),
              onClick: () => {
                navigate("/performance")
              },
            },
            { label: t("settings") },
          ]}
          selectedItem={1}
          style={{ marginTop: 22 }}
        />
        {/* main container */}
        <PaddingContainerDesktop>
          <Stack
            style={{
              width: "100%",
              maxWidth: desktopMaxWidth,
            }}
          >
            {/* header */}
            <AppearFromSide fade>
              <Title
                fontSize={36}
                lineHeight="46px"
                style={{ marginTop: 22, fontStyle: "italic" }}
                component="h1"
                color={colors.primary}
              >
                {t("settings").toUpperCase()}
              </Title>
            </AppearFromSide>
            <Stack
              alignItems="center"
              style={{
                width: "57.14%",
                height: "auto",
                marginTop: 28,
                marginBottom: 54,
                gap: 32,
              }}
            >
              {/* avatars */}
              <AppearFromSide fade delay={0.05} style={{ width: "100%" }}>
                <Stack
                  style={{
                    width: "100%",
                    height: "auto",
                    backgroundColor: colors.backgroundWhite,
                    borderRadius: 16,
                    boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
                    paddingTop: 22,
                    paddingBottom: 22,
                    paddingInline: 28,
                  }}
                >
                  <Title fontSize={20} component="h2">
                    {t("avatar")}
                  </Title>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    style={{
                      width: "100%",
                      height: "auto",
                      gap: 13.2,
                      marginTop: 24,
                    }}
                  >
                    {avatars.slice(0, 5).map((avatar, index) => (
                      <AvatarButton
                        key={index}
                        avatar={avatar}
                        selected={selectedAvatar === avatar.url}
                        current={
                          user!.profileImage === avatar.url ? true : false
                        }
                        disabled={loading}
                        onClick={() => {
                          setSelectedAvatar(avatar.url)
                        }}
                      />
                    ))}
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    style={{
                      width: "100%",
                      height: "auto",
                      gap: 13.2,
                      marginTop: 24,
                    }}
                  >
                    {avatars.slice(5).map((avatar, index) => (
                      <AvatarButton
                        key={index}
                        avatar={avatar}
                        selected={selectedAvatar === avatar.url}
                        current={
                          user!.profileImage === avatar.url ? true : false
                        }
                        disabled={loading}
                        onClick={() => {
                          setSelectedAvatar(avatar.url)
                        }}
                      />
                    ))}
                  </Stack>
                  <Stack
                    direction="row"
                    style={{ alignSelf: "flex-end", marginTop: 24, gap: 8 }}
                  >
                    <Button
                      width={170}
                      style={{ borderRadius: 12 }}
                      title={t("cancel")}
                      outlined
                      disabled={
                        selectedAvatar === user?.profileImage || loading
                      }
                      onClick={() => {
                        setSelectedAvatar(user!.profileImage)
                      }}
                    >
                      {t("cancel")}
                    </Button>
                    <Button
                      width={170}
                      style={{ borderRadius: 12 }}
                      title={t("save_change")}
                      disabled={selectedAvatar === user?.profileImage}
                      loading={loading}
                      onClick={async () => {
                        setLoading(true)

                        await changeAvatar(selectedAvatar)

                        setLoading(false)
                      }}
                    >
                      {t("save_change")}
                    </Button>
                  </Stack>
                </Stack>
              </AppearFromSide>
              {/* privacy */}
              <AppearFromSide fade delay={0.1} style={{ width: "100%" }}>
                <Stack
                  style={{
                    width: "100%",
                    borderRadius: 16,
                    backgroundColor: colors.backgroundWhite,
                    boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
                    paddingInline: 28,
                    paddingTop: 22,
                    paddingBottom: 22,
                    position: "relative",
                  }}
                  title={t("privacy")}
                  onClick={() => {}}
                >
                  <Title fontSize={20} component="h2">
                    {t("privacy")}
                  </Title>
                  <Text
                    fontSize={18}
                    fontWeight={400}
                    style={{ marginTop: 15 }}
                  >
                    <Trans i18nKey="privacy_accepted">
                      Hai preso visione dei{" "}
                      <button
                        style={{
                          color: colors.primary,
                          fontWeight: 700,
                          textDecoration: "underline",
                          cursor: "pointer",
                          border: "none",
                          backgroundColor: "rgba(0, 0, 0, 0)",
                          fontSize: 18,
                          paddingInline: 0,
                        }}
                        onClick={() => {
                          setTermsAlertOpen(true)
                        }}
                      >
                        termini e condizioni
                      </button>{" "}
                      del servizio e della{" "}
                      <button
                        style={{
                          color: colors.primary,
                          fontWeight: 700,
                          textDecoration: "underline",
                          cursor: "pointer",
                          border: "none",
                          backgroundColor: "rgba(0, 0, 0, 0)",
                          fontSize: 18,
                          paddingInline: 0,
                        }}
                        onClick={() => {
                          setPrivacyAlertOpen(true)
                        }}
                      >
                        politica di riservatezza
                      </button>
                      .
                    </Trans>
                  </Text>
                  <Stack
                    alignItems="flex-end"
                    style={{ width: "100%", marginTop: 28 }}
                  >
                    <Button
                      title={t("revoke_consent")}
                      style={{
                        width: "fit-content",
                        borderRadius: 12,
                        paddingInline: 16,
                      }}
                      onClick={() => {
                        setDeleteAlertOpen(true)
                      }}
                    >
                      {t("revoke_consent")}
                    </Button>
                  </Stack>
                </Stack>
              </AppearFromSide>
              {/* tutorial */}
              {challenge ? (
                <MenuButton
                  title="Tutorial"
                  delay={0.15}
                  onClick={() => {
                    scrollWindowToTop()
                    navigate("/")
                    setViewTutorial(true)
                  }}
                />
              ) : null}
            </Stack>
          </Stack>
        </PaddingContainerDesktop>
      </Stack>
      {/* account delete alert */}
      <Alert
        open={deleteAlertOpen}
        title={t("delete_account_title")}
        description={t("delete_account_description")}
        primaryActionLabel={t("go_back_alert")}
        primaryActionOnClick={() => {
          setDeleteAlertOpen(false)
        }}
        secondaryActionLabel={t("delete_account")}
        secondaryActionOnClick={async () => {
          setDeletingUser(true)

          const noErrors = await deleteUser()
          if (noErrors) {
            registerEvent("account_deleted", {
              sub: user?.sub,
            })
            localStorage.removeItem("accessToken")
            localStorage.removeItem("refreshToken")
            navigate("/")
            window.location.reload()
          } else {
            setDeletingUser(false)
          }
        }}
        loading={deletingUser}
        isSecondaryActionPrimary={false}
      />
      {/* full screen alerts for terms and privacy */}
      <AlertFullScreenPDF
        open={termsAlertOpen}
        setOpen={setTermsAlertOpen}
        file="https://dist.aworld.click/integrations/levissima/files/terms-and-conditions.pdf"
      />
      <AlertFullScreenPDF
        open={privacyAlertOpen}
        setOpen={setPrivacyAlertOpen}
        file="https://dist.aworld.click/integrations/levissima/files/privacy-policy-v2.pdf"
      />
    </Stack>
  )
}

const MenuButton = ({
  title,
  onClick,
  delay,
}: {
  title: string
  onClick: () => void
  delay?: number
}) => {
  return (
    <AppearFromSide fade delay={delay ?? 0} style={{ width: "100%" }}>
      <ButtonBase
        style={{ width: "100%", borderRadius: 16 }}
        aria-label={title}
        onClick={onClick}
      >
        <Stack
          direction="row"
          alignItems="center"
          style={{
            width: "100%",
            height: 71,
            backgroundColor: colors.backgroundWhite,
            borderRadius: 16,
            boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
            paddingInline: 28,
            position: "relative",
          }}
        >
          <Title fontSize={20} component="h2">
            {title}
          </Title>
          <img
            src={chevronRightPrimaryIcon}
            style={{ width: 11, position: "absolute", right: 32 }}
            alt=""
          />
        </Stack>
      </ButtonBase>
    </AppearFromSide>
  )
}

export default SettingsDesktop
