import { Grow, Modal, Stack } from "@mui/material"
import { t } from "i18next"
import { colors } from "../../../services/config/colors"
import Title from "../../global/common/Title"
import Button from "../../global/common/Button"
import {
  defaultBoxShadow,
  defaultBoxShadowDarker,
} from "../../../services/config/constants"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"
import Text from "../../global/common/Text"
import PointsBadge from "../../global/common/PointsBadge"
import Prize from "../../../models/prize"
import { UsersContext } from "../../../controllers/users"

const ChallengeRecapFeedback = ({
  open,
  handleClose,
  position,
  prize,
}: {
  open: boolean
  handleClose: () => void
  position: number
  prize: Prize
}) => {
  const { isMobile, user } = useContext(MainContext)
  const { leaderboard } = useContext(UsersContext)

  return (
    <Modal
      open={open}
      aria-labelledby={t("mission_completed")}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
      }}
    >
      <Grow in={open} mountOnEnter unmountOnExit>
        <Stack
          alignItems="center"
          style={{
            backgroundColor: colors.backgroundWhite,
            width: isMobile ? "calc(100% - 44px)" : 560,
            maxWidth: isMobile ? 375 : 560,
            height: "auto",
            borderRadius: 16,
            paddingTop: 70,
            paddingBottom: 24,
            paddingInline: 30,
            position: "relative",
          }}
        >
          <img
            src={user?.profileImage}
            style={{
              width: 80,
              height: 80,
              borderRadius: "100%",
              boxShadow: defaultBoxShadowDarker,
              position: "absolute",
              top: -40,
            }}
            alt=""
          />
          <Title
            fontSize={26}
            lineHeight="30.4px"
            paddingLeft={10}
            paddingRight={10}
            color={colors.text}
            textAlign="center"
          >
            Congratulazioni!
            <br />
            Sei al {position}° posto
          </Title>
          <Stack
            direction="row"
            justifyContent="flex-start"
            style={{
              width: "100%",
              gap: 26,
              marginTop: 28,
            }}
          >
            {!isMobile && (
              <div style={{ width: 220, minWidth: 220 }}>
                <Stack
                  key={prize.title}
                  style={{
                    width: 200,
                    height: 280,
                    borderRadius: 12,
                    boxShadow: defaultBoxShadow,
                    backgroundColor: colors.backgroundWhite,
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: 150,
                      backgroundImage: `url(${prize.image})`,
                      backgroundSize: "122%",
                      backgroundPosition: "70%",
                      position: "relative",
                    }}
                  >
                    <div
                      className="center"
                      style={{
                        width: "fit-content",
                        height: 25,
                        backgroundColor: colors.primary,
                        borderRadius: 22,
                        paddingInline: 11,
                        paddingTop: 3,
                        position: "absolute",
                        left: 15,
                        bottom: 13,
                      }}
                    >
                      <Text
                        fontSize={14}
                        fontWeight={500}
                        color={colors.textWhite}
                      >
                        {prize.heading}
                      </Text>
                    </div>
                  </div>
                  <Stack style={{ padding: 16, gap: 12 }}>
                    <Title
                      fontSize={18}
                      lineHeight="25px"
                      style={{ height: 75 }}
                    >
                      {prize.title}
                    </Title>
                    <Title fontSize={14} fontWeight={400}>
                      {prize.value}
                    </Title>
                  </Stack>
                </Stack>
              </div>
            )}
            <Stack style={{ width: "100%" }}>
              {user &&
              leaderboard.some(
                (item) => item.position === position && item.sub !== user.sub
              ) ? (
                <Text
                  fontSize={16}
                  lineHeight="24px"
                  fontWeight={400}
                  textAlign={isMobile ? "center" : "left"}
                  style={{ flex: 1 }}
                >
                  Grazie per il tuo impegno.
                  <br />
                  Nel caso di partecipanti parimerito, procederemo ad
                  un'estrazione per decretare il vincitore, come previsto dal
                  regolamento.
                  <br />
                  Ti invieremo una email con tutte le informazioni e
                  tempistiche.
                  <br />
                  Ti auguriamo buona fortuna!
                </Text>
              ) : (
                <Text
                  fontSize={16}
                  lineHeight="24px"
                  fontWeight={400}
                  textAlign={isMobile ? "center" : "left"}
                  style={{ flex: 1 }}
                >
                  Il tuo impegno ha contribuito a migliorare il Pianeta e ti ha
                  permesso di vincere uno dei nostri bellissimi premi.
                  <br />
                  <strong>
                    Guarda la tua email per ricevere tutte le informazioni su
                    come ritirarlo.
                  </strong>
                </Text>
              )}
              <PointsBadge
                style={{
                  width: "fit-content",
                  marginTop: 30,
                  alignSelf: isMobile ? "center" : "left",
                }}
                points={user!.points}
              />
            </Stack>
          </Stack>
          <Button
            width={isMobile ? "100%" : 280}
            style={{ marginTop: 32 }}
            onClick={handleClose}
            title={t("i_understand")}
          >
            {t("i_understand")}
          </Button>
        </Stack>
      </Grow>
    </Modal>
  )
}

export default ChallengeRecapFeedback
