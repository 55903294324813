import { Stack } from "@mui/material"
import Title from "../../global/common/Title"
import episodeSuccededImage from "../../../assets/images/episode-succeded.png"
import episodeFailedImage from "../../../assets/images/episode-failed.png"
import Confetti from "../../global/common/Confetti"
import { colors } from "../../../services/config/colors"
import { useTranslation } from "react-i18next"
import { useContext, useMemo } from "react"
import { JourneysContext } from "../../../controllers/journeys"
import { MainContext } from "../../../controllers/main"

const DailyEpisodeFeedback = () => {
  const { t } = useTranslation()
  const { currentMission } = useContext(MainContext)
  const { dailyEpisode } = useContext(JourneysContext)

  // completed successfully
  const success = useMemo(
    () => dailyEpisode?.result.success || !currentMission?.requireCorrect,
    [dailyEpisode]
  )

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        minHeight: "100%",
        paddingTop: 120,
        position: "relative",
        overflow: "hidden",
        backgroundColor: colors.backgroundWhite,
      }}
    >
      <Stack alignItems="center" style={{ gap: 30 }}>
        <Title
          fontSize={26}
          textAlign="center"
          paddingLeft={32}
          paddingRight={32}
        >
          {t(success ? "daily_episode_completed" : "daily_episode_failed")}
        </Title>
        <img
          src={success ? episodeSuccededImage : episodeFailedImage}
          style={{ width: 190, height: 190 }}
          alt=""
        />
      </Stack>
      {success && <Confetti />}
    </div>
  )
}

export default DailyEpisodeFeedback
