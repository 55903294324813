import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react"
import {
  alwaysShowOnboarding,
  fallbackLanguage,
  isMainteinanceMode,
  mobileBreakpoint,
  shouldFakeSignIn,
  shouldSkipChallengeOverCheck,
} from "../services/config/constants"
import { get, post, put } from "../services/api/api"
import {
  parseAvatarsListData,
  parseUserData,
} from "../services/utils/parseFunctions"
import User from "../models/user"
import { useNavigate, useSearchParams } from "react-router-dom"
import Avatar from "../models/avatar"
import { cacheImages } from "../services/utils/utils"
import Mission from "../models/mission"
import TeamContract from "../models/teamContract"

interface MainContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  signUpError: boolean
  viewOnboarding: boolean
  viewTutorial: boolean
  setViewTutorial: Dispatch<SetStateAction<boolean>>
  viewAvatarSelection: boolean
  setViewAvatarSelection: Dispatch<SetStateAction<boolean>>
  setSignUpError: Dispatch<SetStateAction<boolean>>
  setViewOnboarding: Dispatch<SetStateAction<boolean>>
  isMobile: boolean
  windowWidth: number
  windowHeight: number
  lang: string
  user: User | null
  setUser: Dispatch<SetStateAction<User | null>>
  userError: boolean
  getUserInfo: (firstCall?: boolean) => Promise<boolean>
  setUserFirstAccess: () => Promise<boolean>
  signUp: () => Promise<boolean>
  changeLang: (newLang: string) => Promise<boolean>
  visualizingErrorPage: boolean
  setVisualizingErrorPage: Dispatch<SetStateAction<boolean>>
  visualizingLoadingPage: boolean
  setVisualizingLoadingPage: Dispatch<SetStateAction<boolean>>
  currentTutorialPage: number
  setCurrentTutorialPage: Dispatch<SetStateAction<number>>
  avatars: Avatar[]
  changeAvatar: (newAvatar: string) => Promise<boolean>
  currentMission: Mission | null
  setCurrentMission: Dispatch<SetStateAction<Mission | null>>
  updatingMissions: boolean
  setUpdatingMissions: Dispatch<SetStateAction<boolean>>
  isChallengeOver: boolean
  contract: TeamContract | null
  isOldChallenge: boolean
  setIsOldChallenge: Dispatch<SetStateAction<boolean>>
}

const MainContext = createContext<MainContextInterface>({
  loading: true,
  setLoading: () => {},
  signUpError: false,
  viewOnboarding: true,
  viewTutorial: true,
  setViewTutorial: () => {},
  viewAvatarSelection: true,
  setViewAvatarSelection: () => {},
  setSignUpError: () => {},
  setViewOnboarding: () => {},
  isMobile: false,
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  lang: fallbackLanguage,
  user: null,
  setUser: () => {},
  userError: false,
  getUserInfo: async () => true,
  setUserFirstAccess: async () => true,
  signUp: async () => true,
  changeLang: async () => false,
  visualizingErrorPage: false,
  setVisualizingErrorPage: () => {},
  visualizingLoadingPage: false,
  setVisualizingLoadingPage: () => {},
  currentTutorialPage: 0,
  setCurrentTutorialPage: () => {},
  avatars: [],
  changeAvatar: async () => true,
  currentMission: null,
  setCurrentMission: () => {},
  updatingMissions: false,
  setUpdatingMissions: () => {},
  isChallengeOver: false,
  contract: null,
  isOldChallenge: false,
  setIsOldChallenge: () => {},
})

const MainController = ({ children }: { children: ReactNode }) => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  // loadings
  const [loading, setLoading] = useState<boolean>(true) // main loading
  const [updatingMissions, setUpdatingMissions] = useState<boolean>(true) // loading for missions update

  // states
  const [signUpError, setSignUpError] = useState<boolean>(false) // signin error
  const [viewOnboarding, setViewOnboarding] = useState<boolean>(true) // view or not sign up onboarding
  const [viewAvatarSelection, setViewAvatarSelection] = useState<boolean>(true) // view or not user avatar selection
  const [viewTutorial, setViewTutorial] = useState<boolean>(true) // view or not post sign up onboarding
  const [isMobile, setIsMobile] = useState<boolean>(false) // if screen is mobile size or not
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth) // window current width
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight) // window current height
  const [lang, setLang] = useState<string>(fallbackLanguage) // app language
  const [user, setUser] = useState<User | null>(null) // current user
  const [userError, setUserError] = useState<boolean>(false) // current user error
  const [visualizingErrorPage, setVisualizingErrorPage] =
    useState<boolean>(false) // if user is visualizing error page or not
  const [visualizingLoadingPage, setVisualizingLoadingPage] =
    useState<boolean>(false) // if user is visualizing loading page or not
  const [currentTutorialPage, setCurrentTutorialPage] = useState<number>(0) // current tutorial page
  const [avatars, setAvatars] = useState<Avatar[]>([]) // avatars list
  const [currentMission, setCurrentMission] = useState<Mission | null>(
    localStorage.getItem("currentMission")
      ? JSON.parse(localStorage.getItem("currentMission")!)
      : null
  ) // current mission
  const [isChallengeOver, setIsChallengeOver] = useState<boolean>(false) // if last challenge is over or not
  const [contract, setContract] = useState<TeamContract | null>(null) // contract id to get past challenge data
  const [isOldChallenge, setIsOldChallenge] = useState<boolean>(false) // if old challenge must be shown

  // change language
  const changeLang = async (newLang: string) => {
    try {
      await put("/web/user/update", { lang: newLang })

      return true
    } catch (e) {
      console.log(e)

      return false
    }
  }

  // get user
  const getUser = async (withContractId = false, contractId?: string) => {
    const { data } = await get(
      `/web/user/get${
        withContractId && contractId ? "?teamcontract_id=" + contractId : ""
      }`
    )

    return data
  }

  // get user wallet
  const getUserWallet = async (withContractId = false, contractId?: string) => {
    const { data } = await get(
      `/web/mission/point/user${
        withContractId && contractId ? "?teamcontract_id=" + contractId : ""
      }`
    )

    return data
  }

  // get all user info
  const getUserInfo = async (firstCall = false) => {
    setUserError(false)

    try {
      // check if challenge is over or not
      const contractResult = await Promise.all([
        get("/web/challenge/get"),
        get("/web/user/teamcontract/list"),
      ])

      // check if challenge is over or not
      let contractId

      if (
        !contractResult[0].data.targetAmount &&
        !shouldSkipChallengeOverCheck
      ) {
        setIsChallengeOver(true)
        setViewTutorial(false)

        const filteredData = contractResult[1].data.items.filter(
          (item: TeamContract) => new Date(item.endDate) < new Date()
        )

        const contractToSet = filteredData.reduce(
          (latest: TeamContract, item: TeamContract) => {
            return new Date(item.startDate) > new Date(latest.startDate)
              ? item
              : latest
          },
          filteredData[0]
        )

        console.log("contracts", contractResult[1].data.items)
        console.log("contract id", contractToSet.id)
        contractId = contractToSet.id

        setContract(contractToSet)
      }

      // get user data
      const result = await Promise.all([
        getUser(!contractResult[0].data.targetAmount, contractId),
        getUserWallet(!contractResult[0].data.targetAmount, contractId),
        getAvatars(),
      ])

      const userData = result[0]
      const userWallet = result[1]

      // parse data
      parseUserData(userData)
      if (userWallet.points) {
        userData.points = userWallet.points
      } else {
        userData.points = 0
      }

      console.log("user", userData)
      setUser({ ...userData, lang: "it" })

      // set app language based on user language
      if (!userData.lang || userData.lang !== "it") {
        await changeLang("it")
      }
      // if (userData.lang) {
      //   i18next.changeLanguage(userData.lang)
      //   if (availableLanguages.find((item) => item.code === userData.lang)) {
      //     setLang(userData.lang)
      //     document.documentElement.lang = userData.lang
      //   } else {
      //     setLang(fallbackLanguage)
      //     document.documentElement.lang = fallbackLanguage
      //   }
      // } else {
      //   const langFromNavigator = navigator.language.slice(0, 2)
      //   if (
      //     langFromNavigator &&
      //     availableLanguages.some((item) => item.code === langFromNavigator)
      //   ) {
      //     await changeLang(langFromNavigator)
      //   }
      // }

      // don't show avatar selection if the user has already one set
      if (userData.profileImage && !alwaysShowOnboarding) {
        setViewAvatarSelection(false)
      }
      if (firstCall) {
        if (!userData.firstAccess && !alwaysShowOnboarding) {
          setViewTutorial(false)
        } else {
          navigate("/")
        }
      }

      return true
    } catch (e) {
      console.log("user error", e)
      setUserError(true)

      return false
    }
  }

  // get avatars list
  const getAvatars = async () => {
    try {
      const { data } = await get("/web/user/avatar/list")
      const dataToSet = parseAvatarsListData(data)
      console.log("avatars list", dataToSet)

      // cache avatars
      try {
        await cacheImages(dataToSet.map((item) => item.url))
      } catch {
        console.log("avatars cache error")
      }

      setAvatars(dataToSet)

      return true
    } catch (e) {
      console.log("avatars list error", e)
      return false
    }
  }

  // change user avatar
  const changeAvatar = async (newAvatar: string) => {
    try {
      await put("/web/user/profileimage", { profileImage: newAvatar })
      console.log(`avatar set ${newAvatar}`)

      // update user locally
      user!.profileImage = newAvatar
      setUser({ ...user! })

      return true
    } catch (e) {
      console.log("profile image change error", e)

      return false
    }
  }

  // set user first access to false
  const setUserFirstAccess = async () => {
    try {
      await put("/web/user/firstaccess")
      console.log("user firstAccess set to false")

      // set first access to false locally
      user!.firstAccess = false
      setUser({ ...user! })

      return true
    } catch (e) {
      console.log("firstaccess error", e)
      setUserError(true)

      return false
    }
  }

  // check if screen is mobile or not
  useEffect(() => {
    // first check
    if (window.innerWidth >= mobileBreakpoint) {
      setIsMobile(false)
    } else {
      setIsMobile(true)
    }

    // event listener on resize
    window.addEventListener("resize", () => {
      if (window.innerWidth >= mobileBreakpoint) {
        setIsMobile(false)
      } else {
        setIsMobile(true)
      }

      setWindowWidth(window.innerWidth)
      setWindowHeight(window.innerHeight)
    })
  }, [])

  // signin
  const signIn = async () => {
    // get key and iv from query params
    const key = searchParams.get("key")
    const iv = searchParams.get("iv")

    // do signin
    const { data } = await post(
      "/signin",
      {},
      {
        key: key,
        iv: iv,
      },
      false
    )

    // set tokens to local storage
    localStorage.setItem("accessToken", data.AccessToken)
    localStorage.setItem("refreshToken", data.RefreshToken)

    // get current user and user group
    await getUserInfo(true)

    setViewOnboarding(false)
    setLoading(false)

    return true
  }

  // signup
  const signUp = async () => {
    try {
      // get key and iv from query params
      const key = searchParams.get("key")
      const iv = searchParams.get("iv")

      // do signup
      const { data } = await post(
        "/signup",
        {},
        {
          key: key,
          iv: iv,
        },
        false
      )

      // set tokens to local storage
      localStorage.setItem("accessToken", data.AccessToken)
      localStorage.setItem("refreshToken", data.RefreshToken)

      // get current user and user group
      await getUserInfo(true)

      setViewOnboarding(false)
      setLoading(false)

      return true
    } catch (e) {
      console.log(e)
      setSignUpError(true)

      return false
    }
  }

  // check if an auth session is already present or not
  const checkSession = async () => {
    if (alwaysShowOnboarding) {
      setLoading(false)
      return
    }

    try {
      // check if user exists with signin
      await signIn()
    } catch {
      // no user, so go to onboarding, accept terms and privacy and do signup
      setLoading(false)
    }
  }

  // initial fetch
  useEffect(() => {
    // get language from navigator and set it
    // const langFromNavigator = navigator.language.slice(0, 2)
    // if (langFromNavigator) {
    //   i18next.changeLanguage(langFromNavigator)
    //   if (availableLanguages.some((item) => item.code === langFromNavigator)) {
    //     document.documentElement.lang = langFromNavigator
    //     setLang(langFromNavigator)
    //   } else {
    //     document.documentElement.lang = fallbackLanguage
    //     setLang(fallbackLanguage)
    //   }
    // }

    // fake signin with localStorage tokens
    const fakeSignIn = async () => {
      await getUserInfo()
      setLoading(false)
      setViewOnboarding(false)
      setViewAvatarSelection(false)
      setViewTutorial(false)
    }

    if (shouldFakeSignIn) {
      fakeSignIn()
      return
    }

    // get key and iv from params, show error page if one of them or both are missing and no token is present
    const key = searchParams.get("key")
    const iv = searchParams.get("iv")
    if (!key || !iv) {
      setSignUpError(true)
      return
    }

    if (!isMainteinanceMode) {
      checkSession()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainContext.Provider
      value={{
        loading,
        setLoading,
        signUpError,
        viewOnboarding,
        viewTutorial,
        setViewTutorial,
        viewAvatarSelection,
        setViewAvatarSelection,
        setSignUpError,
        setViewOnboarding,
        isMobile,
        windowWidth,
        windowHeight,
        lang,
        user,
        setUser,
        userError,
        getUserInfo,
        setUserFirstAccess,
        signUp,
        changeLang,
        visualizingErrorPage,
        setVisualizingErrorPage,
        visualizingLoadingPage,
        setVisualizingLoadingPage,
        currentTutorialPage,
        setCurrentTutorialPage,
        avatars,
        changeAvatar,
        currentMission,
        setCurrentMission,
        updatingMissions,
        setUpdatingMissions,
        isChallengeOver,
        contract,
        isOldChallenge,
        setIsOldChallenge,
      }}
    >
      {children}
    </MainContext.Provider>
  )
}
export { MainController, MainContext }
