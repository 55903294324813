import ReactCountdown, { CountdownTimeDelta } from "react-countdown"
import Title from "./Title"
import { colors } from "../../../services/config/colors"
import { CSSProperties, useContext } from "react"
import { MainContext } from "../../../controllers/main"

const Countdown = ({
  targetDate,
  showDays = false,
  showSeconds = true,
  onComplete,
  style,
}: {
  targetDate: Date
  showDays?: boolean
  showSeconds?: boolean
  onComplete?: (
    timeDelta: CountdownTimeDelta,
    completedOnStart: boolean
  ) => void
  style?: CSSProperties
}) => {
  const { isMobile } = useContext(MainContext)

  return (
    <ReactCountdown
      date={targetDate}
      renderer={(data) => {
        return CountdownRenderer({
          isMobile: isMobile,
          days: data.days,
          hours: data.hours,
          minutes: data.minutes,
          seconds: data.seconds,
          showSeconds: showSeconds,
          showDays: showDays,
          style: style,
        })
      }}
      onComplete={onComplete}
    />
  )
}

const CountdownRenderer = ({
  isMobile,
  days,
  hours,
  minutes,
  seconds,
  showSeconds,
  showDays,
  style,
}: {
  isMobile: boolean
  days: number
  hours: number
  minutes: number
  seconds: number
  showSeconds: boolean
  showDays: boolean
  style?: CSSProperties
}) => {
  // build time string based on what to show
  const buildDynamicTimeString = () => {
    let timeString = ""

    if (showDays && days > 0) {
      timeString += `${days}G `
    }
    if (hours > 0) {
      timeString += `${hours}H `
    }
    timeString += `${minutes}M `
    if (showSeconds) {
      timeString += `${seconds}S`
    }

    return timeString.trim()
  }

  return (
    <Title fontSize={isMobile ? 22 : 26} color={colors.textWhite} style={style}>
      {buildDynamicTimeString()}
    </Title>
  )
}

export default Countdown
