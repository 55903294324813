import { ButtonBase, LinearProgress, Skeleton, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import Title from "../global/common/Title"
import arrowLefIcon from "../../assets/icons/arrow-left.svg"
import settingsIcon from "../../assets/icons/settings.svg"
import { useLocation, useNavigate } from "react-router-dom"
import Text from "../global/common/Text"
import Appear from "../animations/Appear"
import footprintDoughnutImage from "../../assets/images/footprint-doughnut.svg"
import chevronRightIcon from "../../assets/icons/chevron-right.svg"
import AppearFromSide from "../animations/AppearFromSide"
import Tabs from "../global/common/Tabs"
import { Dispatch, SetStateAction, useContext, useState } from "react"
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"
import { UsersContext } from "../../controllers/users"
import PointsHistoryListItem from "./common/PointsHistoryListItem"
import LeaderboardTop3 from "./common/LeaderboardTop3"
import LeaderboardListItem from "./common/LeaderboardListItem"
import { scrollWindowToTop } from "../../services/utils/utils"
import { FootprintContext } from "../../controllers/footprint"
import errorIcon from "../../assets/icons/error.svg"
import { MissionsContext } from "../../controllers/missions"
import { MainContext } from "../../controllers/main"
import { MissionSubType } from "../../services/config/enum"
import Button from "../global/common/Button"
import {
  contestRules,
  defaultBoxShadowDarker,
} from "../../services/config/constants"
import closeIcon from "../../assets/icons/close.png"

const PerformanceMobile = ({
  setOldChallengeSelectedTab,
  displayedLeaderboardLoading,
  showDrawPrizesBanner,
  setShowDrawPrizesBanner,
  userPosition,
}: {
  setOldChallengeSelectedTab: Dispatch<SetStateAction<number>>
  displayedLeaderboardLoading: boolean
  showDrawPrizesBanner: boolean
  setShowDrawPrizesBanner: Dispatch<SetStateAction<boolean>>
  userPosition: number
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const {
    user,
    setCurrentMission,
    isChallengeOver,
    isOldChallenge,
    setIsOldChallenge,
  } = useContext(MainContext)
  const {
    leaderboard,
    leaderboardUpdating,
    leaderboardNextToken,
    getLeaderboard,
    leaderboardError,
  } = useContext(UsersContext)
  const { formsList, userFootprint } = useContext(FootprintContext)
  const {
    missions,
    missionsHistory,
    missionsHistoryUpdating,
    missionsHistoryNextToken,
    getMissionsHistory,
    completedMissionsCount,
  } = useContext(MissionsContext)

  // tabs
  const tabs = [t("missions"), t("leaderboard")]
  const [selectedTab, setSelectedTab] = useState<number>(
    location.state && location.state.viewLeaderboard ? 1 : 0
  )

  return (
    <Stack
      style={{
        width: "100%",
        minHeight: "100%",
        backgroundColor: colors.backgroundWhite,
        paddingTop: 22,
        overflowX: "hidden",
      }}
    >
      {/* header */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ width: "100%", height: 48, paddingInline: 16 }}
      >
        <ButtonBase
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
          onClick={() => {
            navigate("/", { state: { fromPerformance: true } })
          }}
          aria-label={t("go_back")}
        >
          <img src={arrowLefIcon} style={{ width: 15 }} alt="" />
        </ButtonBase>
        <Title
          fontSize={20}
          lineHeight="23px"
          style={{ height: 23, fontStyle: "italic" }}
          component="h1"
          color={colors.primary}
        >
          {t("performance").toUpperCase()}
        </Title>
        <ButtonBase
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
          onClick={() => {
            navigate("/performance/settings")
          }}
          aria-label={t("settings")}
        >
          <img src={settingsIcon} style={{ width: 24, height: 24 }} alt="" />
        </ButtonBase>
      </Stack>
      {/* user info */}
      <Stack
        direction="row"
        alignItems="center"
        style={{
          width: "100%",
          height: 77,
          marginTop: 20,
          paddingInline: 16,
          gap: 12,
        }}
      >
        <Appear fade>
          <ButtonBase
            aria-label={t("edit_avatar")}
            style={{ borderRadius: "100%" }}
            onClick={() => {
              navigate("/performance/settings/avataredit")
            }}
          >
            <div
              style={{
                width: 77,
                height: 77,
                borderRadius: "100%",
                backgroundImage: `url(${
                  user!.profileImage
                }), url(https://cdn.aworld.io/users/default/profile.jpg)`,
                backgroundSize: "105%, 100%",
                backgroundPosition: "center",
              }}
            />
          </ButtonBase>
        </Appear>
        <Appear style={{ width: "72%" }} fade>
          <Stack style={{ gap: 10 }}>
            <Title
              fontSize={18}
              lineHeight="22px"
              style={{
                width: "100%",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                textOverflow: "ellipsis",
                overflow: "hidden",
                wordWrap: "break-word",
              }}
            >
              {user?.first_name} {user?.last_name}
            </Title>
            <div
              style={{
                width: "fit-content",
                height: 32,
                borderRadius: 4,
                paddingInline: 6,
                border: "1px solid " + colors.primary,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Text fontSize={14} fontWeight={700} color={colors.primary}>
                {user?.points} {t("points")}
              </Text>
            </div>
          </Stack>
        </Appear>
      </Stack>
      {!userFootprint &&
      !missions.some(
        (mission) => mission.missionSubType === MissionSubType.TYPEFORM_BASE
      ) ? null : (
        <AppearFromSide delay={0.05} fade>
          <ButtonBase
            disabled={
              !userFootprint &&
              !missions.some(
                (mission) =>
                  mission.missionSubType === MissionSubType.TYPEFORM_BASE
              )
            }
            style={{
              width: "calc(100% - 32px)",
              marginLeft: 16,
              marginTop: 18,
              borderRadius: 10,
            }}
            onClick={() => {
              scrollWindowToTop()
              if (
                formsList.length &&
                formsList.some((item) => !item.isRefine)
              ) {
                const missionToSet = missions.find(
                  (mission) =>
                    mission.missionSubType === MissionSubType.TYPEFORM_BASE
                )!
                setCurrentMission(missionToSet)
                localStorage.setItem(
                  "currentMission",
                  JSON.stringify(missionToSet)
                )
                navigate("/performance/footprint/form")
              } else {
                navigate("/performance/footprint")
              }
            }}
            aria-label={t("your_carbon_footprint")}
          >
            <Stack
              direction="row"
              alignItems="center"
              style={{
                width: "100%",
                height: 45,
                paddingInline: 12,
                borderRadius: 10,
                boxShadow: "0px 0px 24px 0px rgba(17, 67, 97, 0.08)",
                gap: 10,
                position: "relative",
              }}
            >
              <img
                src={footprintDoughnutImage}
                style={{ width: 33, height: 30 }}
                alt=""
              />
              <Text fontSize={14} fontWeight={700} lineHeight="20px">
                {t("your_carbon_footprint")}
              </Text>
              <img
                src={chevronRightIcon}
                style={{
                  width: 24,
                  height: 24,
                  position: "absolute",
                  right: 12,
                }}
                alt=""
              />
              {!userFootprint ? (
                <img
                  src={errorIcon}
                  style={{
                    width: 22,
                    height: 22,
                    position: "absolute",
                    right: 48,
                  }}
                  alt={t("footprint_empty_state")}
                />
              ) : null}
            </Stack>
          </ButtonBase>
        </AppearFromSide>
      )}
      {/* challenge draw prizes banner */}
      {showDrawPrizesBanner ? (
        <AppearFromSide
          delay={0.1}
          fade
          style={{
            width: "100%",
            paddingInline: 16,
            marginTop:
              !userFootprint &&
              !missions.some(
                (mission) =>
                  mission.missionSubType === MissionSubType.TYPEFORM_BASE
              )
                ? 25
                : 18,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={2}
            style={{
              padding: 16,
              borderRadius: 24,
              border: `3px solid ${colors.primary}`,
            }}
          >
            <Stack gap={1} style={{ flex: 1 }}>
              <Title
                fontSize={14}
                color={colors.primary}
                style={{ textTransform: "uppercase" }}
              >
                Complimenti! Hai raggiunto i 500 pt CON LE TUE MISSIONI
              </Title>
              <Text fontSize={14} fontWeight={400}>
                Da questo momento anche tu puoi partecipare all'estrazione per
                vincere 1 Weekend allo Chalet Levissima 3000*. L'estrazione
                avverrà entro il 31/01/2025.
              </Text>
            </Stack>
            <ButtonBase
              aria-label={t("close")}
              style={{
                width: 36,
                height: 36,
                boxShadow: defaultBoxShadowDarker,
                borderRadius: "100%",
              }}
              onClick={() => {
                setShowDrawPrizesBanner(false)
                localStorage.setItem("drawPrizesBanner2Closed", "true")
              }}
            >
              <img src={closeIcon} style={{ width: 10, height: 10 }} alt="" />
            </ButtonBase>
          </Stack>
        </AppearFromSide>
      ) : null}
      {/* prizes banner */}
      {isChallengeOver && userPosition <= 5 ? (
        <Stack
          alignItems="center"
          justifyContent="center"
          style={{
            width: "calc(100% - 32px)",
            marginLeft: 16,
            borderRadius: 16,
            paddingTop: 24,
            paddingBottom: 24,
            backgroundColor: colors.background,
            marginTop:
              !userFootprint &&
              !missions.some(
                (mission) =>
                  mission.missionSubType === MissionSubType.TYPEFORM_BASE
              )
                ? 25
                : 18,
          }}
        >
          {user &&
          leaderboard.some(
            (item) => item.position === userPosition && item.sub !== user.sub
          ) ? (
            <Stack gap={1} alignItems="center">
              <Title fontSize={24} color={colors.primary} textAlign="center">
                COMPLIMENTI!
                <br />
                SEI AL {userPosition}° POSTO A PARI MERITO
              </Title>
              <Text
                fontSize={16}
                lineHeight="20px"
                fontWeight={400}
                textAlign="center"
              >
                <strong>Grazie per il tuo impegno.</strong>
                <br />
                <strong>Siamo felici di vedere questa partecipazione.</strong>
                <br />
                Nel caso di partecipanti a pari merito, procederemo ad
                un'estrazione per decretare il vincitore, come previsto dal{" "}
                <span
                  role="button"
                  style={{
                    color: colors.primary,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(contestRules)
                  }}
                >
                  regolamento
                </span>
                . Riceverei tutte le informazioni e tempistiche via email.{" "}
                <strong>Buona fortuna!</strong>
              </Text>
            </Stack>
          ) : (
            <Stack gap={1} alignItems="center">
              <Title fontSize={24} color={colors.primary} textAlign="center">
                COMPLIMENTI!
                <br />
                SEI AL {userPosition}° POSTO IN CLASSIFICA
              </Title>
              <Text
                fontSize={16}
                lineHeight="20px"
                fontWeight={400}
                textAlign="center"
              >
                Hai vinto uno tra i premi messi in palio.
                <br />
                <strong>
                  Guarda la tua email per ricevere tutte le informazioni su come
                  ritirarlo.
                </strong>
              </Text>
            </Stack>
          )}
        </Stack>
      ) : null}
      {!isChallengeOver && (
        <AppearFromSide
          delay={0.1}
          fade
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: 25,
          }}
        >
          <Button
            title={
              isOldChallenge
                ? t("view_active_challenge")
                : t("view_past_challenge")
            }
            loading={displayedLeaderboardLoading}
            style={{ height: 46 }}
            onClick={() => {
              setOldChallengeSelectedTab((current) => (current === 0 ? 1 : 0))
              setIsOldChallenge((current) => !current)
            }}
          >
            {isOldChallenge
              ? t("view_active_challenge")
              : t("view_past_challenge")}
          </Button>
        </AppearFromSide>
      )}
      {/* tabs */}
      <AppearFromSide
        delay={0.1}
        fade
        style={{
          marginTop: 18,
        }}
      >
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabContentId="performance-tabs-content"
        />
      </AppearFromSide>
      {/* tabs content */}
      <AppearFromSide delay={0.15} fade style={{ marginTop: 25 }}>
        <motion.div
          animate={selectedTab.toString()}
          variants={tabs.reduce(
            (obj, current, index) => ({
              ...obj,
              [index]: {
                x: `calc(-100vw * ${index})`,
              },
            }),
            {}
          )}
          transition={{ x: { type: "spring", damping: 18, stiffness: 160 } }}
          initial={false}
        >
          <Stack
            id="performance-tabs-content"
            direction="row"
            style={{
              width: "300vw",
              minWidth: "300vw",
              marginBottom: 26,
            }}
            role="tabpanel"
          >
            {/* completed missions */}
            <Stack
              style={{
                width: "100vw",
                minWidth: "100vw",
                paddingInline: 16,
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                style={{ width: "100%", height: 30, position: "relative" }}
              >
                <Title fontSize={18} lineHeight="24px" component="h2">
                  {t("completed_missions")}
                </Title>
              </Stack>
              {displayedLeaderboardLoading ? (
                <Skeleton
                  variant="rectangular"
                  style={{
                    width: "100%",
                    height: 396.5,
                    borderRadius: 10,
                    marginTop: 18,
                  }}
                />
              ) : !completedMissionsCount ? (
                <Text fontSize={14} fontWeight={400} style={{ marginTop: 18 }}>
                  {t("points_history_empty_state")}.
                </Text>
              ) : (
                <Stack
                  id="missions-history-container"
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: 396.5,
                    borderRadius: 10,
                    boxShadow: "0px 0px 24px 0px rgba(17, 67, 97, 0.08)",
                    marginTop: 18,
                    gap: 18,
                    paddingTop: 18,
                    paddingBottom: 18,
                    overflowY: "scroll",
                    position: "relative",
                  }}
                  onScroll={() => {
                    const container = document.getElementById(
                      "missions-history-container"
                    )

                    if (
                      container!.scrollTop + 224 + 400 >=
                        container!.scrollHeight &&
                      missionsHistoryNextToken &&
                      !missionsHistoryUpdating
                    ) {
                      getMissionsHistory(false)
                    }
                  }}
                >
                  {missionsHistory.map((item, index) => (
                    <Stack key={index} style={{ gap: 18 }}>
                      {index > 0 && (
                        <div
                          style={{
                            width: "100%",
                            height: 1.5,
                            backgroundColor: colors.backgroundLight,
                          }}
                        />
                      )}
                      <PointsHistoryListItem
                        title={item.title}
                        time={item.updatedAt!}
                        points={item.points}
                        type={item.missionType}
                        isCorrect={item.isCorrect}
                        boost={item.boost}
                      />
                    </Stack>
                  ))}
                  {missionsHistoryUpdating && (
                    <LinearProgress
                      style={{
                        width: "calc(100% - 32px)",
                        position: "fixed",
                        bottom: 0,
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                      }}
                    />
                  )}
                </Stack>
              )}
            </Stack>
            {/* leaderboard */}
            <Stack
              style={{ width: "100vw", minWidth: "100vw", paddingInline: 16 }}
            >
              <Stack
                direction="row"
                alignItems="center"
                style={{ width: "100%", height: 30, position: "relative" }}
              >
                <Title fontSize={18} lineHeight="24px" component="h2">
                  {t("leaderboard")}
                  {!isOldChallenge && "*"}
                </Title>
              </Stack>
              {!leaderboardError && (
                <LeaderboardTop3
                  skeleton={displayedLeaderboardLoading}
                  style={{ marginTop: 18 }}
                />
              )}
              {displayedLeaderboardLoading ? (
                <Skeleton
                  variant="rectangular"
                  style={{
                    width: "100%",
                    height: 224,
                    borderRadius: 10,
                    marginTop: 28,
                  }}
                />
              ) : leaderboardError ? (
                <Stack gap={3} style={{ marginTop: 18 }}>
                  <Text fontSize={14} fontWeight={400}>
                    {t("leaderboard_error")}.
                  </Text>
                  <Button
                    title={t("retry")}
                    width={170}
                    style={{ height: 36, alignSelf: "center" }}
                    onClick={() => {
                      getLeaderboard(false, false)
                    }}
                  >
                    {t("retry")}
                  </Button>
                </Stack>
              ) : leaderboard.length > 3 ? (
                <Stack
                  id="leaderboard-container"
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: 224,
                    borderRadius: 10,
                    marginTop: 28,
                    boxShadow: "0px 0px 24px 0px rgba(17, 67, 97, 0.08)",
                    overflowY: "scroll",
                    position: "relative",
                  }}
                  onScroll={() => {
                    const container = document.getElementById(
                      "leaderboard-container"
                    )

                    if (
                      container!.scrollTop + 224 + 200 >=
                        container!.scrollHeight &&
                      leaderboardNextToken &&
                      !leaderboardUpdating
                    ) {
                      getLeaderboard(false)
                    }
                  }}
                >
                  {leaderboard.slice(3).map((item, index) => (
                    <Stack key={index} style={{ height: 56, minHeight: 56 }}>
                      {index > 0 &&
                      item.sub !== user!.sub &&
                      leaderboard[index + 2].sub !== user!.sub ? (
                        <div
                          style={{
                            width: "100%",
                            height: 1.5,
                            backgroundColor: colors.backgroundLight,
                          }}
                        />
                      ) : null}
                      <LeaderboardListItem
                        place={item.position}
                        name={`${item.first_name} ${item.last_name}`}
                        points={item.points}
                        isUser={item.sub === user!.sub}
                        profileImage={item.profileImage}
                      />
                    </Stack>
                  ))}
                  {leaderboardUpdating && (
                    <LinearProgress
                      style={{
                        width: "calc(100% - 32px)",
                        position: "fixed",
                        bottom: 0,
                        borderBottomLeftRadius: 10,
                        borderBottomRightRadius: 10,
                      }}
                    />
                  )}
                </Stack>
              ) : (
                <Text fontSize={14} fontWeight={400} style={{ marginTop: 18 }}>
                  {t("leaderboard_empty_state")}.
                </Text>
              )}
              {!isOldChallenge && (
                <Text fontSize={14} fontWeight={400} style={{ marginTop: 26 }}>
                  *Tra i partecipanti a parimerito, si terrà conto dell’ordine
                  cronologico di raggiungimento punti. Per maggiori info vedi{" "}
                  <span
                    role="button"
                    style={{
                      fontWeight: 700,
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() => {
                      window.open(contestRules)
                    }}
                  >
                    regolamento
                  </span>
                  .
                </Text>
              )}
            </Stack>
          </Stack>
        </motion.div>
      </AppearFromSide>
    </Stack>
  )
}

export default PerformanceMobile
