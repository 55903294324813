import { useLocation, useNavigate } from "react-router-dom"
import { Compose } from "../../services/utils/compose"
import { MissionsContext, MissionsController } from "../../controllers/missions"
import { ActionsContext, ActionsController } from "../../controllers/actions"
import { useContext, useEffect, useState } from "react"
import Loading from "../public/loading"
import { MainContext } from "../../controllers/main"
import NavBarDesktop from "../../components/global/desktop/NavBarDesktop"
import FooterDesktop from "../../components/global/desktop/FooterDesktop"
import AppRoutes from "../../services/config/routes"
import { JourneysContext, JourneysController } from "../../controllers/journeys"
import { TagsContext, TagsController } from "../../controllers/tags"
import Error from "../public/error"
import { UsersContext, UsersController } from "../../controllers/users"
import {
  ChallengeContext,
  ChallengeController,
} from "../../controllers/challenge"
import {
  FootprintContext,
  FootprintController,
} from "../../controllers/footprint"
import { useTranslation } from "react-i18next"
import TutorialAlertDesktop from "../../components/onboarding/desktop/TutorialAlertDesktop"
import NavBarDesktopTutorial from "../../components/global/desktop/NavBarDesktopTutorial"
import TutorialAlertMobile from "../../components/onboarding/mobile/TutorialAlertMobile"
import { t } from "i18next"
import DrawPrizesFeedback from "../../components/feedbacks/common/DrawPrizesFeedback"
import AlertInfo from "../../components/global/common/AlertInfo"
import { contestRules } from "../../services/config/constants"

const Main = () => {
  // all controllers
  const controllers = [
    UsersController,
    TagsController,
    ChallengeController,
    MissionsController,
    ActionsController,
    JourneysController,
    FootprintController,
  ]

  return (
    <Compose components={controllers}>
      <Container />
    </Compose>
  )
}

const Container = () => {
  const navigate = useNavigate()
  const { isMobile } = useContext(MainContext)

  // check that every controller has finished loading data
  const { user, isChallengeOver } = useContext(MainContext)
  const {
    actionsLoading,
    historyLoading,
    detailedHistoryLoading,
    actionsError,
    historyError,
    detailedHistoryError,
  } = useContext(ActionsContext)
  const {
    journeysLoading,
    journeysError,
    dailyEpisodeLoading,
    dailyEpisodeError,
  } = useContext(JourneysContext)
  const {
    categoriesLoading,
    categoriesError,
    mainCategoriesLoading,
    mainCategoriesError,
  } = useContext(TagsContext)
  const {
    missionsLoading,
    missionsError,
    missionsHistoryLoading,
    missionsHistoryError,
    completedMissionsCountLoading,
    completedMissionsCountError,
    missionsLocked,
  } = useContext(MissionsContext)
  const { leaderboardLoading } = useContext(UsersContext)
  const { challengeLoading, challengeError, savingsLoading, savingsError } =
    useContext(ChallengeContext)
  const {
    userFootprintLoading,
    userFootprintError,
    formsListLoading,
    formsListError,
    userFormsListLoading,
    userFormsListError,
  } = useContext(FootprintContext)

  const loadings = [
    actionsLoading,
    historyLoading,
    detailedHistoryLoading,
    journeysLoading,
    categoriesLoading,
    mainCategoriesLoading,
    missionsLoading,
    dailyEpisodeLoading,
    leaderboardLoading,
    challengeLoading,
    missionsHistoryLoading,
    userFootprintLoading,
    formsListLoading,
    userFormsListLoading,
    savingsLoading,
    completedMissionsCountLoading,
  ]

  const errors = [
    actionsError,
    historyError,
    detailedHistoryError,
    journeysError,
    categoriesError,
    mainCategoriesError,
    missionsError,
    dailyEpisodeError,
    challengeError,
    missionsHistoryError,
    userFootprintError,
    formsListError,
    userFormsListError,
    savingsError,
    completedMissionsCountError,
  ]

  const loading = loadings.some((item) => item)

  const error = errors.some((item) => item)

  // check user points (if >= 500, show draw prizes feedback)
  const [drawPrizesFeedbackOpen, setDrawPrizesFeedbackOpen] =
    useState<boolean>(false)
  // show info alert
  const [infoAlertOpen, setInfoAlertOpen] = useState<boolean>(
    localStorage.getItem("importantUpdateFeedback3Closed") ? false : true
  )

  useEffect(() => {
    if (
      user &&
      user.points >= 500 &&
      !isChallengeOver &&
      !localStorage.getItem("drawPrizesFeedback2Closed")
    ) {
      setDrawPrizesFeedbackOpen(true)
      localStorage.setItem("drawPrizesFeedback2Closed", "true")
    }
  }, [user])

  if (error) {
    return (
      <Error
        modules={[
          { name: "Authentication", error: false },
          { name: "Categories", error: categoriesError },
          { name: "Main categories", error: mainCategoriesError },
          { name: "Actions", error: actionsError },
          { name: "History", error: historyError },
          { name: "Daily history", error: detailedHistoryError },
          { name: "Journey", error: journeysError },
          { name: "Daily episode", error: dailyEpisodeError },
          { name: "Missions", error: missionsError },
          { name: "Missions history", error: missionsHistoryError },
          { name: "Completed missions", error: completedMissionsCountError },
          // { name: "Leaderboard", error: leaderboardError },
          { name: "Challenge", error: challengeError },
          { name: "Challenge savings", error: savingsError },
          { name: "Footprint", error: userFootprintError },
          { name: "Footprint forms", error: formsListError },
          { name: "User footprint forms", error: userFormsListError },
        ]}
      />
    )
  }

  if (loading) {
    return (
      <Loading
        title={!journeysLoading ? t("just_a_moment") : t("loading_experience")}
        determinate
        progress={
          (loadings.filter((item) => !item).length * 80) / loadings.length + 20
        }
      />
    )
  }

  return (
    <>
      {isMobile ? <ContainerMobile /> : <ContainerDesktop />}
      <DrawPrizesFeedback
        open={drawPrizesFeedbackOpen}
        setOpen={setDrawPrizesFeedbackOpen}
      />
      <AlertInfo
        open={
          missionsLocked &&
          (window.location.pathname.startsWith("/actions") ||
            window.location.pathname.startsWith("/journey") ||
            window.location.pathname.startsWith("/dailyepisode") ||
            window.location.pathname.startsWith("/performance/footprint/form"))
        }
        title="NON PUOI PIÙ COMPLETARE MISSIONI PER OGGI"
        description="Torna alle 9:00 per continuare ad accumulare punti, contribuire alla <strong>missione collettiva</strong> e <strong>vincere i fantastici premi</strong> di Rigeneriamo insieme."
        descriptionAlign="center"
        onCloseAction={() => {
          navigate("/")
        }}
      />
      <AlertInfo
        open={infoAlertOpen}
        setOpen={setInfoAlertOpen}
        title="🎉 Benvenuto nella 3° Challenge di Rigeneriamo Insieme! 🌍"
        description={`Questa volta ci sono alcune novità nelle Missioni: ti invitiamo a leggere il regolamento completo <a href="${contestRules}" target="_blank">qui</a>.<br /><br />
          <strong>Cosa sapere subito:</strong>
          <ul>
          <li>Il concorso è attivo ogni giorno dalle <strong>9:00 alle 24:00</strong>. ⏰</li>
          <li>Entro il <strong>31/01/2025</strong> saranno proclamati i 3 vincitori finali. 🏆</li>
          </ul>
          📊 La classifica si baserà sui punteggi ottenuti dai partecipanti. In caso di parità, verrà considerato l’ordine cronologico di raggiungimento del punteggio.`}
        descriptionAlign="left"
        onCloseAction={() => {
          localStorage.setItem("importantUpdateFeedback3Closed", "true")
        }}
        style={{ maxWidth: 600 }}
      />
    </>
  )
}

const ContainerDesktop = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { windowHeight, viewTutorial } = useContext(MainContext)

  return (
    <div
      id="main-container"
      style={{
        width: "100%",
        height: windowHeight,
        maxHeight: windowHeight,
        position: "relative",
        overflowY: "scroll",
        zIndex: 10,
      }}
    >
      {window.location.pathname.includes("/journey/") ||
      window.location.pathname.includes(
        "/dailyepisode/"
      ) ? null : viewTutorial ? (
        <NavBarDesktopTutorial />
      ) : (
        <NavBarDesktop />
      )}
      <main
        id="main-content"
        style={{
          height:
            window.location.pathname.includes("/journey/") ||
            window.location.pathname.includes("/dailyepisode/")
              ? "100%"
              : "auto",
        }}
        aria-label={t("main_content")}
      >
        <TutorialAlertDesktop />
        <AppRoutes location={location} />
      </main>
      {window.location.pathname.includes("/journey/") ||
      window.location.pathname.includes("/dailyepisode") ? null : (
        <FooterDesktop />
      )}
    </div>
  )
}

const ContainerMobile = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { windowHeight } = useContext(MainContext)

  return (
    <main
      id="main-container"
      style={{
        width: "100%",
        height: windowHeight,
        maxHeight: windowHeight,
        position: "relative",
        overflowY: "scroll",
      }}
      aria-label={t("main_content")}
    >
      <TutorialAlertMobile />
      <AppRoutes location={location} />
    </main>
  )
}

export default Main
