import { CSSProperties, useContext, useEffect, useState } from "react"
import { colors } from "../../../services/config/colors"
import { useTranslation } from "react-i18next"
import { Stack } from "@mui/material"
import Title from "../../global/common/Title"
import { MainContext } from "../../../controllers/main"
import CountUp from "react-countup"
import { ChallengeContext } from "../../../controllers/challenge"
import {
  challengeTargetToShowMilestone,
  shouldShowMilestone,
} from "../../../services/config/constants"

const ChallengeDotProgressBar = ({
  percentage,
  challengeTargetAmount,
  animated = true,
  style,
}: {
  percentage: number
  challengeTargetAmount: number
  animated?: boolean
  style?: CSSProperties
}) => {
  const { t } = useTranslation()
  const { viewTutorial, currentTutorialPage } = useContext(MainContext)
  const { challenge } = useContext(ChallengeContext)

  // bar percentage, different from the props one beacause the max of this one is 100
  const [barPercentage, setBarPercentage] = useState<number>(0)

  useEffect(() => {
    setBarPercentage(percentage > 100 ? 100 : percentage)
  }, [percentage])

  return (
    <Stack
      direction="row"
      alignItems="center"
      style={{
        width: "100%",
        height: 44,
        minHeight: 44,
        position: "relative",
        ...style,
      }}
      aria-label={t("challenge_completion", { percentage: percentage })}
      role="group"
      aria-roledescription={t("progress_bar")}
    >
      <div
        style={{
          width: "100%",
          height: 16,
          backgroundColor: colors.background,
          borderRadius: 100,
          border: "1px solid " + colors.primary,
          overflow: "hidden",
        }}
      >
        <div
          style={{
            width: `${barPercentage}%`,
            height: "100%",
            backgroundColor: colors.primary,
            borderRadius: 100,
            transition: animated ? "1000ms" : "0ms",
            transitionTimingFunction: "cubic-bezier(0.47, 0, 0.745, 0.2)",
          }}
        />
      </div>
      {/* milestone indicator */}
      {challengeTargetAmount === challengeTargetToShowMilestone &&
      shouldShowMilestone ? (
        <div
          style={{
            width: 10,
            height: 10,
            position: "absolute",
            top: 17,
            left: 0,
            right: 0,
            marginInline: "auto",
            backgroundColor: "#84C57B",
            transform: "rotate(45deg)",
          }}
        />
      ) : null}
      <div
        className="center"
        style={{
          width: 44,
          height: 44,
          borderRadius: "100%",
          backgroundColor: colors.primary,
          position: "absolute",
          left:
            barPercentage > 6 && barPercentage < 94
              ? `calc(${barPercentage}% - 22px)`
              : barPercentage <= 6
              ? "0%"
              : "88%",
          transition: animated ? "1000ms" : "0ms",
          transitionTimingFunction: "cubic-bezier(0.47, 0, 0.745, 0.2)",
        }}
      >
        {viewTutorial && currentTutorialPage === 1 ? (
          <CountUp
            start={Math.round(
              (challenge!.currentAmount / challenge!.targetAmount) * 100
            )}
            end={100}
            duration={1}
            style={{
              fontSize: `${14 * 0.063}rem`,
              fontWeight: 700,
              color: colors.textWhite,
            }}
            suffix="%"
            useEasing={false}
          />
        ) : (
          <Title fontSize={14} color={colors.textWhite} ariaHidden>
            {percentage === Infinity ? "100" : percentage}%
          </Title>
        )}
      </div>
    </Stack>
  )
}

export default ChallengeDotProgressBar
