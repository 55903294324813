import { ButtonBase, Stack } from "@mui/material"
import { colors } from "../../services/config/colors"
import { useNavigate } from "react-router-dom"
import Title from "../global/common/Title"
import { Trans, useTranslation } from "react-i18next"
import arrowLefIcon from "../../assets/icons/arrow-left.svg"
import FadeFromBottom from "../animations/FadeFromBottom"
import Button from "../global/common/Button"
import Alert from "../global/common/Alert"
import { useContext, useState } from "react"
import { UsersContext } from "../../controllers/users"
import { MainContext } from "../../controllers/main"
import Text from "../global/common/Text"
import FadeFromTop from "../animations/FadeFromTop"
import { registerEvent } from "../../services/utils/utils"
import AlertFullScreenPDF from "../global/common/AlertFullScreenPDF"

const PrivacyMobile = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { user } = useContext(MainContext)
  const { deleteUser } = useContext(UsersContext)

  // account delete alert open
  const [deleteAlertOpen, setDeleteAlertOpen] = useState<boolean>(false)

  // delete loading
  const [deletingUser, setDeletingUser] = useState<boolean>(false)

  // terms and privacy alerts
  const [termsAlertOpen, setTermsAlertOpen] = useState<boolean>(false)
  const [privacyAlertOpen, setPrivacyAlertOpen] = useState<boolean>(false)

  return (
    <Stack
      alignItems="center"
      style={{
        width: "100%",
        minHeight: "100%",
        backgroundColor: colors.backgroundWhite,
        paddingTop: 22,
        overflowX: "hidden",
        paddingBottom: 100,
      }}
    >
      {/* header */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ width: "100%", height: 48, paddingInline: 16 }}
      >
        <ButtonBase
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
          onClick={() => {
            navigate("/performance/settings")
          }}
          aria-label={t("go_back")}
        >
          <img src={arrowLefIcon} style={{ width: 15 }} alt="" />
        </ButtonBase>
        <Title
          fontSize={20}
          lineHeight="23px"
          style={{ height: 23 }}
          component="h1"
        >
          {t("revoke_consent")}
        </Title>
        <div
          style={{
            width: 48,
            minWidth: 48,
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 12,
          }}
        />
      </Stack>
      {/* content */}
      <FadeFromTop style={{ marginTop: 16, width: "100%" }}>
        <Text fontSize={18} fontWeight={400} paddingLeft={16} paddingRight={16}>
          <Trans i18nKey="privacy_accepted">
            Hai preso visione dei{" "}
            <button
              style={{
                color: colors.primary,
                fontWeight: 700,
                textDecoration: "underline",
                cursor: "pointer",
                border: "none",
                backgroundColor: "rgba(0, 0, 0, 0)",
                fontSize: 18,
                paddingInline: 0,
              }}
              onClick={() => {
                setTermsAlertOpen(true)
              }}
            >
              termini e condizioni
            </button>{" "}
            del servizio e della{" "}
            <button
              style={{
                color: colors.primary,
                fontWeight: 700,
                textDecoration: "underline",
                cursor: "pointer",
                border: "none",
                backgroundColor: "rgba(0, 0, 0, 0)",
                fontSize: 18,
                paddingInline: 0,
              }}
              onClick={() => {
                setPrivacyAlertOpen(true)
              }}
            >
              politica di riservatezza
            </button>
            .
          </Trans>
        </Text>
      </FadeFromTop>
      {/* delete account button */}
      <FadeFromBottom
        style={{ width: "100%", position: "fixed", bottom: 0, left: 0 }}
      >
        <Stack
          style={{
            width: "100%",
            paddingTop: 16,
            paddingBottom: 16,
            boxShadow: "0px 0px 64px rgba(3, 56, 120, 0.12)",
            borderRadius: "10px 10px 0px 0px",
            backgroundColor: colors.backgroundWhite,
            gap: 8,
          }}
        >
          <Button
            width="calc(100% - 32px)"
            style={{ marginLeft: 16 }}
            title={t("revoke_consent")}
            onClick={async () => {
              setDeleteAlertOpen(true)
            }}
          >
            {t("revoke_consent")}
          </Button>
        </Stack>
      </FadeFromBottom>
      {/* account delete alert */}
      <Alert
        open={deleteAlertOpen}
        title={t("delete_account_title")}
        description={t("delete_account_description")}
        primaryActionLabel={t("go_back_alert")}
        primaryActionOnClick={() => {
          setDeleteAlertOpen(false)
        }}
        secondaryActionLabel={t("delete_account")}
        secondaryActionOnClick={async () => {
          setDeletingUser(true)

          const noErrors = await deleteUser()
          if (noErrors) {
            registerEvent("account_deleted", {
              sub: user?.sub,
            })
            localStorage.removeItem("accessToken")
            localStorage.removeItem("refreshToken")
            navigate("/")
            window.location.reload()
          } else {
            setDeletingUser(false)
          }
        }}
        loading={deletingUser}
        isSecondaryActionPrimary={false}
      />
      {/* full screen alerts for terms privacy */}
      <AlertFullScreenPDF
        open={termsAlertOpen}
        setOpen={setTermsAlertOpen}
        file="https://dist.aworld.click/integrations/levissima/files/terms-and-conditions.pdf"
      />
      <AlertFullScreenPDF
        open={privacyAlertOpen}
        setOpen={setPrivacyAlertOpen}
        file="https://dist.aworld.click/integrations/levissima/files/privacy-policy-v2.pdf"
      />
    </Stack>
  )
}

export default PrivacyMobile
