import { Stack } from "@mui/material"
import Title from "../../global/common/Title"
import journeySuccededImage from "../../../assets/images/journey-succeded.png"
import episodeFailedImage from "../../../assets/images/episode-failed.png"
import Confetti from "../../global/common/Confetti"
import { colors } from "../../../services/config/colors"
import { useTranslation } from "react-i18next"
import { useContext, useMemo } from "react"
import { JourneysContext } from "../../../controllers/journeys"
import { MainContext } from "../../../controllers/main"

const JourneyFeedback = () => {
  const { t } = useTranslation()
  const { currentMission } = useContext(MainContext)
  const { journey } = useContext(JourneysContext)

  // completed successfully
  const success = useMemo(
    () => journey?.result.success || !currentMission?.requireCorrect,
    [journey]
  )

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        minHeight: "100%",
        paddingTop: 120,
        position: "relative",
        overflow: "hidden",
        backgroundColor: colors.backgroundWhite,
      }}
    >
      <Stack alignItems="center" style={{ gap: 30 }}>
        <Title fontSize={26}>
          {t(success ? "journey_completed" : "journey_failed")}
        </Title>
        <img
          src={success ? journeySuccededImage : episodeFailedImage}
          style={{ width: 190, height: 190 }}
          alt=""
        />
      </Stack>
      {success && <Confetti />}
    </div>
  )
}

export default JourneyFeedback
