import { useEffect } from "react"
import { registerPageEvent } from "../../services/utils/utils"
import PrivacyMobile from "../../components/settings/PrivacyMobile"

const Privacy = () => {
  // register google analytics event
  useEffect(() => {
    registerPageEvent("privacy")
  }, [])

  return <PrivacyMobile />
}

export default Privacy
