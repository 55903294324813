import { Grid, Stack } from "@mui/material"
import backgroundImageTop from "../../assets/images/landing-background-top.jpg"
import backgroundImageBottom from "../../assets/images/landing-background-bottom.png"
import Title from "../global/common/Title"
import Text from "../global/common/Text"
import AppearFromSide from "../animations/AppearFromSide"
import ChallengeCardDesktop from "../challenge/desktop/ChallengeCardDesktop"
import { colors } from "../../services/config/colors"
import MissionCardDesktop from "./desktop/MissionCardDesktop"
import Button from "../global/common/Button"
import FadeFromTop from "../animations/FadeFromTop"
import {
  desktopColumnsGap,
  desktopMaxWidth,
} from "../../services/config/constants"
import PaddingContainerDesktop from "../global/desktop/PaddingContainerDesktop"
import { useContext, useState } from "react"
import { MissionsContext } from "../../controllers/missions"
import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { scrollWindow, scrollWindowToTop } from "../../services/utils/utils"
import MissionsTabs from "../missions/common/MissionsTabs"
import { MissionPeriod } from "../../services/config/enum"
import DailyMissionsCardDesktop from "./desktop/DailyMissionsCardDesktop"
import AlertInfo from "../global/common/AlertInfo"
import MissionInfoCardDesktop from "./desktop/MissionInfoCardDesktop"
import spaceshipImage from "../../assets/images/spaceship.svg"
import { MainContext } from "../../controllers/main"
import { UsersContext } from "../../controllers/users"
import { ChallengeContext } from "../../controllers/challenge"
import Prize from "../../models/prize"
import ExtraMissionCardDesktop from "./desktop/ExtraMissionCardDesktop"
import BoostBannerDesktop from "../missions/desktop/BoostBannerDesktop"

const LandingDesktop = ({ prizes }: { prizes: Prize[] }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { viewTutorial, setCurrentMission } = useContext(MainContext)
  const { leaderboard } = useContext(UsersContext)
  const { challenge } = useContext(ChallengeContext)
  const { missions, selectedTab, setSelectedTab, completedMissionsCount } =
    useContext(MissionsContext)

  // what is a mission alert
  const [missionInfoAlertOpen, setMissionInfoAlertOpen] =
    useState<boolean>(false)

  // come back tomorrow alert
  const [comeBackAlertOpen, setComeBackAlertOpen] = useState<boolean>(false)

  return (
    <Stack
      alignItems="center"
      style={{
        width: "100%",
        position: "relative",
      }}
    >
      {/* header */}
      <div
        style={{
          width: "100%",
          height: 746,
          minHeight: 746,
          paddingTop: 177,
          position: "relative",
        }}
      >
        <img
          src={backgroundImageTop}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "bottom",
            position: "absolute",
            top: 0,
          }}
          alt=""
        />
        <PaddingContainerDesktop style={{ position: "relative" }}>
          <Stack
            direction="row"
            style={{
              width: "100%",
              maxWidth: desktopMaxWidth,
              gap: desktopColumnsGap,
            }}
          >
            {/* first header half */}
            <Stack style={{ width: "50%" }}>
              <AppearFromSide delay={0.05} fade>
                <Title
                  fontSize={40}
                  lineHeight="46px"
                  style={{
                    letterSpacing: "-0.4px",
                    fontStyle: "italic",
                    textTransform: "uppercase",
                  }}
                  component="h1"
                  color={colors.primary}
                >
                  {t("join_partner_challenge")}
                </Title>
              </AppearFromSide>
              <AppearFromSide delay={0.1} fade>
                <Text
                  fontSize={24}
                  lineHeight="40px"
                  fontWeight={400}
                  style={{ marginTop: 40 }}
                >
                  <Trans i18nKey="complete_missions" />
                </Text>
              </AppearFromSide>
              <AppearFromSide fade style={{ marginTop: 34 }} delay={0.15}>
                <Stack>
                  <Button
                    width={330}
                    onClick={() => {
                      if (!missions.length) {
                        scrollWindowToTop()
                        navigate("/performance")
                      } else {
                        scrollWindow(780, "smooth")
                      }
                    }}
                    title={
                      missions.length
                        ? t("discover_missions")
                        : t("go_to_your_points")
                    }
                  >
                    {missions.length
                      ? t("discover_missions")
                      : t("go_to_your_points")}
                  </Button>
                  <Button
                    width={330}
                    outlined
                    title={t("go_to_leaderboard")}
                    style={{ marginTop: 10 }}
                    onClick={() => {
                      if (leaderboard.length > 8) {
                        scrollWindowToTop()
                        navigate("/performance/leaderboard")
                      } else {
                        scrollWindow(500, "smooth")
                        navigate("/performance")
                      }
                    }}
                  >
                    {t("go_to_leaderboard")}
                  </Button>
                </Stack>
              </AppearFromSide>
            </Stack>
            {/* second header half */}
            <Stack justifyContent="center" style={{ width: "50%" }}>
              {challenge ? (
                <AppearFromSide
                  x={100}
                  delay={0.05}
                  fade
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <ChallengeCardDesktop prizes={prizes} />
                </AppearFromSide>
              ) : null}
            </Stack>
          </Stack>
        </PaddingContainerDesktop>
      </div>
      {/* missions */}
      <FadeFromTop style={{ marginTop: 70 }}>
        <Title
          fontSize={40}
          lineHeight="46px"
          component="h2"
          color={colors.primary}
          style={{ fontStyle: "italic" }}
        >
          {t("your_missions").toUpperCase()}
        </Title>
      </FadeFromTop>
      {/* missions */}
      {missions.length ? (
        <FadeFromTop style={{ marginTop: 24 }}>
          <Title
            fontSize={20}
            lineHeight="23px"
            color={colors.primary}
            style={{ textDecoration: "underline" }}
          >
            {completedMissionsCount}{" "}
            {completedMissionsCount === 1
              ? t("completed_singular")
              : t("completed_plural")}
          </Title>
        </FadeFromTop>
      ) : null}
      {/* tabs */}
      {missions.length ? (
        <FadeFromTop style={{ width: "100%", marginTop: 60 }} delay={0.05}>
          <PaddingContainerDesktop>
            <MissionsTabs
              tabs={[t("all"), t("today"), t("week"), t("special")]}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              style={{
                width: "100%",
                maxWidth: 1050,
              }}
              infoButtonOnClick={() => {
                setMissionInfoAlertOpen(true)
              }}
            />
          </PaddingContainerDesktop>
        </FadeFromTop>
      ) : (
        <FadeFromTop style={{ width: "100%", marginTop: 36 }} delay={0.05}>
          <PaddingContainerDesktop>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              style={{
                width: "100%",
                maxWidth: 1050,
                height: 60,
                position: "relative",
              }}
            >
              <Text fontSize={26} fontWeight={400}>
                {t("all_missions_completed")}
              </Text>
            </Stack>
          </PaddingContainerDesktop>
        </FadeFromTop>
      )}
      {/* missions grid */}
      {missions.length ? (
        <FadeFromTop style={{ marginTop: 40, width: "100%" }} delay={0.1}>
          <PaddingContainerDesktop>
            {/* missions grid */}
            <Grid
              id="missions-desktop-container"
              aria-label={t("available_missions")}
              container
              columns={3}
              columnSpacing={`${desktopColumnsGap}px`}
              rowSpacing="74px"
              style={{
                width: `calc(100% + ${desktopColumnsGap}px)`,
                maxWidth: 1080,
              }}
              role="tabpanel"
            >
              {missions
                .filter((mission) => !mission.pointPerActivity)
                .filter((mission) => {
                  switch (selectedTab) {
                    case 0:
                      return mission
                    case 1:
                      if (mission.period === MissionPeriod.DAILY) {
                        return mission
                      } else {
                        return null
                      }
                    case 2:
                      if (mission.period === MissionPeriod.WEEKLY) {
                        return mission
                      } else {
                        return null
                      }
                    case 3:
                      if (mission.period === MissionPeriod.CUSTOM) {
                        return mission
                      } else {
                        return null
                      }
                  }

                  return null
                })
                .map((mission, index) => (
                  <Grid key={mission.missionId + selectedTab} item xs={1}>
                    <MissionCardDesktop
                      mission={mission}
                      index={index}
                      color={mission.color}
                      title={mission.title}
                      titleColor={mission.titleColor}
                      goButtonColor={mission.goButtonColor}
                      image={mission.image}
                      current={mission.current}
                      target={mission.target}
                      points={mission.points}
                      satisfied={mission.satisfied}
                      missionType={mission.missionType}
                      missionSubType={mission.missionSubType}
                      missionTypeId={mission.missionTypeId}
                      period={mission.period}
                      onClick={() => {
                        scrollWindowToTop()

                        setCurrentMission(mission)
                        localStorage.setItem(
                          "currentMission",
                          JSON.stringify(mission)
                        )

                        if (
                          mission.navigationPath ===
                          "/performance/footprint/form"
                        ) {
                          navigate(mission.navigationPath, {
                            state: { formId: mission.missionTypeId },
                          })
                        } else {
                          navigate(mission.navigationPath)
                        }
                      }}
                      selectedTab={selectedTab}
                      end={mission.end}
                      currentPerDay={mission.currentPerDay}
                      targetPerDay={mission.targetPerDay}
                      setComeBackAlertOpen={setComeBackAlertOpen}
                      updatedAt={mission.updatedAt}
                      isCorrect={mission.isCorrect}
                    />
                  </Grid>
                ))}
              {selectedTab === 1 ? (
                <Grid item xs={1}>
                  {missions.filter(
                    (mission) =>
                      mission.period === MissionPeriod.DAILY &&
                      mission.satisfied
                  ).length === 2 ? (
                    <MissionInfoCardDesktop
                      missionsCompleted={2}
                      missionsPeriod={MissionPeriod.DAILY}
                    />
                  ) : (
                    <DailyMissionsCardDesktop />
                  )}
                </Grid>
              ) : selectedTab === 2 ? (
                <Grid item xs={1}>
                  <MissionInfoCardDesktop
                    key={selectedTab}
                    missionsCompleted={
                      missions.filter(
                        (mission) =>
                          mission.period === MissionPeriod.WEEKLY &&
                          mission.satisfied
                      ).length
                    }
                    missionsPeriod={MissionPeriod.WEEKLY}
                  />
                </Grid>
              ) : selectedTab === 3 ? (
                <Grid item xs={1}>
                  <MissionInfoCardDesktop
                    key={selectedTab}
                    missionsCompleted={
                      missions.filter(
                        (mission) =>
                          mission.period === MissionPeriod.CUSTOM &&
                          mission.satisfied
                      ).length
                    }
                    missionsPeriod={MissionPeriod.CUSTOM}
                  />
                </Grid>
              ) : null}
            </Grid>
            {missions.some(
              (mission) => mission.boost && !mission.satisfied
            ) && (
              <BoostBannerDesktop
                mission={missions.find((mission) => mission.boost)!}
                style={{ marginTop: 74 }}
              />
            )}
          </PaddingContainerDesktop>
        </FadeFromTop>
      ) : (
        <FadeFromTop delay={0.1} style={{ marginTop: 80 }}>
          <PaddingContainerDesktop>
            <img src={spaceshipImage} style={{ width: 330 }} alt="" />
          </PaddingContainerDesktop>
        </FadeFromTop>
      )}
      {/* pointPerActivity missions */}
      {missions.filter((mission) => mission.pointPerActivity).length ? (
        <FadeFromTop delay={0.1} style={{ width: "100%", marginTop: 74 }}>
          <PaddingContainerDesktop>
            <Stack gap={2} style={{ width: "100%", maxWidth: desktopMaxWidth }}>
              {missions
                .filter((mission) => mission.pointPerActivity)
                .map((mission) => (
                  <ExtraMissionCardDesktop
                    key={mission.missionId}
                    mission={mission}
                  />
                ))}
            </Stack>
          </PaddingContainerDesktop>
        </FadeFromTop>
      ) : null}
      {/* bottom part */}
      <Stack
        alignItems="center"
        justifyContent="center"
        style={{
          width: "100%",
          height: 424,
          backgroundImage: `url(${backgroundImageBottom})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          position: "relative",
        }}
      />
      {/* what is a mission info alert */}
      <AlertInfo
        open={missionInfoAlertOpen}
        setOpen={setMissionInfoAlertOpen}
        title={t("what_are_missions_extended")}
        description={t("what_is_a_mission_description")}
        descriptionAlign="center"
      />
      {/* come back tomorrow info alert */}
      <AlertInfo
        open={comeBackAlertOpen}
        setOpen={setComeBackAlertOpen}
        title={t("thats_all_for_today")}
        description={t("come_back_tomorrow_to_continue")}
        descriptionAlign="center"
      />
      {/* tutorial backdrop */}
      {viewTutorial && (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            position: "absolute",
            top: 0,
            zIndex: 100,
          }}
        />
      )}
    </Stack>
  )
}

export default LandingDesktop
