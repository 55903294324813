import { Grow, Modal, Stack } from "@mui/material"
import { t } from "i18next"
import { colors } from "../../../services/config/colors"
import Title from "../../global/common/Title"
import Button from "../../global/common/Button"
import { useNavigate } from "react-router-dom"
import { scrollWindowToTop } from "../../../services/utils/utils"
import { defaultBoxShadowDarker } from "../../../services/config/constants"
import { useContext } from "react"
import { MainContext } from "../../../controllers/main"
import MissionCardDesktop from "../../landing/desktop/MissionCardDesktop"
import Text from "../../global/common/Text"
import PointsBadge from "../../global/common/PointsBadge"
import { Trans } from "react-i18next"
import { MissionPeriod, MissionType } from "../../../services/config/enum"
import BoostBadge from "../../missions/common/BoostBadge"

const MissionCompletedFeedback = ({
  open,
  missionType,
  isFailed,
}: {
  open: boolean
  missionType: MissionType
  isFailed?: boolean
}) => {
  const navigate = useNavigate()
  const { isMobile, currentMission, user } = useContext(MainContext)

  return (
    <Modal
      open={open}
      aria-labelledby={t("mission_completed")}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
      }}
    >
      <Grow in={open} mountOnEnter unmountOnExit>
        <Stack
          alignItems="center"
          style={{
            backgroundColor: colors.backgroundWhite,
            width: isMobile ? "calc(100% - 44px)" : 560,
            maxWidth: isMobile ? 375 : 560,
            height: "auto",
            borderRadius: 16,
            paddingTop: 70,
            paddingBottom: 24,
            paddingInline: 30,
            position: "relative",
          }}
        >
          <img
            src={user?.profileImage}
            style={{
              width: 80,
              height: 80,
              borderRadius: "100%",
              boxShadow: defaultBoxShadowDarker,
              position: "absolute",
              top: -40,
            }}
            alt=""
          />
          <Title
            fontSize={26}
            lineHeight="30.4px"
            paddingLeft={10}
            paddingRight={10}
            color={colors.text}
            textAlign="center"
          >
            {isFailed ? t("mission_not_completed") : t("mission_completed")}
          </Title>
          <Stack
            direction="row"
            justifyContent="flex-start"
            style={{
              width: "100%",
              gap: 30,
              marginTop: 28,
            }}
          >
            {!isMobile && !currentMission!.pointPerActivity && !isFailed ? (
              <div style={{ width: 178, minWidth: 178 }}>
                <MissionCardDesktop
                  mission={currentMission!}
                  index={0}
                  color={currentMission!.color}
                  title={currentMission!.title}
                  titleColor={currentMission!.titleColor}
                  goButtonColor={currentMission!.goButtonColor}
                  image={currentMission!.image}
                  current={currentMission!.current}
                  target={currentMission!.target}
                  points={currentMission!.points}
                  satisfied={false}
                  missionType={currentMission!.missionType}
                  missionSubType={currentMission!.missionSubType}
                  missionTypeId={currentMission!.missionTypeId}
                  period={currentMission!.period}
                  end={currentMission!.end}
                  currentPerDay={currentMission!.currentPerDay}
                  targetPerDay={currentMission!.targetPerDay}
                  onClick={() => {}}
                  forFeedback
                />
              </div>
            ) : null}
            <Stack style={{ width: "100%" }}>
              {isFailed ? (
                <Text
                  fontSize={16}
                  lineHeight="24px"
                  textAlign={isMobile || isFailed ? "center" : "left"}
                >
                  {currentMission?.period === MissionPeriod.DAILY ? (
                    <Trans i18nKey="mission_daily_not_completed">
                      Hai completato la missione
                      <br />
                      giornaliera{" "}
                      {{
                        type: t(missionType.toLowerCase()).toUpperCase(),
                      }}
                    </Trans>
                  ) : currentMission?.period === MissionPeriod.WEEKLY ? (
                    <Trans i18nKey="mission_weekly_not_completed">
                      Hai completato la missione
                      <br />
                      settimanale{" "}
                      {{
                        type: t(missionType.toLowerCase()).toUpperCase(),
                      }}
                    </Trans>
                  ) : (
                    <Trans i18nKey="mission_special_not_completed">
                      Hai completato la missione
                      <br />
                      speciale{" "}
                      {{
                        type: t(missionType.toLowerCase()).toUpperCase(),
                      }}
                    </Trans>
                  )}
                </Text>
              ) : (
                <Text
                  fontSize={16}
                  lineHeight="24px"
                  textAlign={isMobile || isFailed ? "center" : "left"}
                >
                  {currentMission?.period === MissionPeriod.DAILY ? (
                    <Trans i18nKey="mission_daily_completed">
                      Hai completato la missione
                      <br />
                      giornaliera{" "}
                      {{
                        type: t(missionType.toLowerCase()).toUpperCase(),
                      }}
                    </Trans>
                  ) : currentMission?.period === MissionPeriod.WEEKLY ? (
                    <Trans i18nKey="mission_weekly_completed">
                      Hai completato la missione
                      <br />
                      settimanale{" "}
                      {{
                        type: t(missionType.toLowerCase()).toUpperCase(),
                      }}
                    </Trans>
                  ) : (
                    <Trans i18nKey="mission_special_completed">
                      Hai completato la missione
                      <br />
                      speciale{" "}
                      {{
                        type: t(missionType.toLowerCase()).toUpperCase(),
                      }}
                    </Trans>
                  )}
                </Text>
              )}
              <Text
                fontSize={16}
                lineHeight="24px"
                fontWeight={500}
                textAlign={isMobile || isFailed ? "center" : "left"}
                style={{
                  fontStyle: "italic",
                  height: isMobile || isFailed ? "auto" : 72,
                  marginTop: 24,
                }}
              >
                {currentMission?.title}
              </Text>
              {!currentMission!.pointPerActivity && !isFailed ? (
                <Text
                  fontSize={12}
                  lineHeight="24px"
                  fontWeight={400}
                  color={colors.primary}
                  textAlign={isMobile ? "center" : "left"}
                  style={{ marginTop: isMobile ? 24 : 19 }}
                >
                  {t("points_earned").toUpperCase()}
                </Text>
              ) : null}
              {!currentMission!.pointPerActivity && !isFailed ? (
                <Stack direction="row" gap={1} style={{ marginTop: 4 }}>
                  <PointsBadge
                    style={{
                      width: "fit-content",
                      alignSelf: isMobile ? "center" : "left",
                    }}
                    points={
                      currentMission?.boost &&
                      currentMission.bonusType &&
                      currentMission.activeTimeSlots &&
                      new Date() < new Date(currentMission.activeTimeSlots.end)
                        ? currentMission.bonusType.type === "multiplier"
                          ? currentMission.points *
                            currentMission.bonusType.value
                          : currentMission.points +
                            currentMission.bonusType.value
                        : currentMission!.points
                    }
                  />
                  {currentMission?.boost ? <BoostBadge /> : null}
                </Stack>
              ) : null}
            </Stack>
          </Stack>
          {missionType === MissionType.MEASURE ? (
            <Button
              width={isMobile ? "100%" : 280}
              style={{ marginTop: 28 }}
              onClick={() => {
                scrollWindowToTop()
                navigate("/performance/footprint")
              }}
              title={t("discover_your_result")}
            >
              {t("discover_your_result")}
            </Button>
          ) : null}
          <Button
            width={isMobile ? "100%" : 280}
            outlined={missionType === MissionType.MEASURE}
            style={{ marginTop: missionType === MissionType.MEASURE ? 12 : 28 }}
            onClick={() => {
              scrollWindowToTop()
              navigate("/")
            }}
            title={t("back_to_missions")}
          >
            {t("back_to_missions")}
          </Button>
        </Stack>
      </Grow>
    </Modal>
  )
}

export default MissionCompletedFeedback
