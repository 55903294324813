import Center from "../../components/global/common/Center"
import { colors } from "../../services/config/colors"
import { ButtonBase, Stack } from "@mui/material"
import { useContext, useEffect } from "react"
import { MainContext } from "../../controllers/main"
import { useTranslation } from "react-i18next"
import { registerPageEvent } from "../../services/utils/utils"
import partnerPoweredByAWorldLogo from "../../assets/images/partner-powered-by-aworld.png"
import { defaultBoxShadowDarker } from "../../services/config/constants"
import Title from "../../components/global/common/Title"
import Text from "../../components/global/common/Text"
import Button from "../../components/global/common/Button"
import Appear from "../../components/animations/Appear"
import FadeFromTop from "../../components/animations/FadeFromTop"
import recapDecorLeft from "../../assets/images/recap-decor-left.png"
import recapDecorRight from "../../assets/images/recap-decor-right.png"

const Error = ({
  modules,
}: {
  modules: { name: string; error: boolean }[]
}) => {
  const { t } = useTranslation()
  const { setVisualizingErrorPage, isMobile, user } = useContext(MainContext)

  // register google analytics event
  useEffect(() => {
    registerPageEvent("error")
  }, [])

  // tell other components this page is being visualized
  useEffect(() => {
    setVisualizingErrorPage(true)

    return () => {
      setVisualizingErrorPage(false)
    }
  }, [])

  return (
    <Center
      style={{
        backgroundColor: colors.backgroundWhite,
        position: "relative",
      }}
    >
      <img
        src={recapDecorLeft}
        style={{ width: isMobile ? 149 : 249, position: "absolute", left: 0 }}
        alt=""
      />
      <img
        src={recapDecorRight}
        style={{
          width: isMobile ? 190 : 357,
          position: "absolute",
          top: 260,
          right: 0,
        }}
        alt=""
      />
      <Appear
        style={{
          paddingInline: 16,
          position: "absolute",
          top: isMobile ? 16 : 62,
        }}
      >
        <ButtonBase
          disableRipple
          aria-label="Rigeneriamo insieme"
          onClick={() => {
            window.open("https://rigeneriamoinsieme.it")
          }}
        >
          <img
            src={partnerPoweredByAWorldLogo}
            style={{
              width: 500,
              maxWidth: "90%",
            }}
            alt={t("partner_logo")}
          />
        </ButtonBase>
      </Appear>
      <FadeFromTop
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingInline: 16,
          position: "relative",
        }}
      >
        <Stack
          alignItems="center"
          style={{
            width: 520,
            maxWidth: "100%",
            backgroundColor: colors.backgroundWhite,
            boxShadow: defaultBoxShadowDarker,
            borderRadius: 16,
            paddingTop: user ? 70 : 36,
            paddingBottom: 30,
            paddingInline: isMobile ? 30 : 75,
          }}
        >
          <Title
            fontSize={isMobile ? 24 : 26}
            color={colors.primary}
            textAlign="center"
            style={{ textTransform: "uppercase", fontStyle: "italic" }}
          >
            Oh no!
            <br />
            Qualcosa è andato storto
          </Title>
          <Text
            fontSize={isMobile ? 16 : 18}
            fontWeight={400}
            style={{ marginTop: 26 }}
          >
            Ma non preoccuparti, ci stiamo impegnando per risolvere al più
            presto il problema.
          </Text>
          <Text
            fontSize={isMobile ? 16 : 18}
            fontWeight={400}
            style={{ marginTop: 24 }}
          >
            Per ulteriori informazioni e supporto contatta{" "}
            <strong>support@aworld.org</strong>
          </Text>
          <Button
            title="Torna alla home"
            style={{
              width: 260,
              marginTop: 40,
              borderRadius: 80,
            }}
            onClick={() => {
              window.location.reload()
            }}
          >
            Riprova
          </Button>
        </Stack>
      </FadeFromTop>
    </Center>
  )
}

export default Error
