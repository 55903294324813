import { ButtonBase, Stack } from "@mui/material"
import Title from "../../global/common/Title"
import { colors } from "../../../services/config/colors"
import Text from "../../global/common/Text"
import lightningIcon from "../../../assets/icons/lightning.svg"
import propicsRowAltImage from "../../../assets/images/propics-row-alt.svg"
import { useTranslation } from "react-i18next"
import { useContext, useEffect, useRef, useState } from "react"
import { ChallengeContext } from "../../../controllers/challenge"
import { MainContext } from "../../../controllers/main"
import Confetti from "../../global/common/Confetti"
import ChallengeDotProgressBar from "../common/ChallengeDotProgressBar"
import { useNavigate } from "react-router-dom"
import { numberWithSeparators } from "../../../services/utils/utils"
import Prize from "../../../models/prize"
import months from "../../../services/config/months"
import {
  challengeTargetToShowMilestone,
  milestoneAmount,
  shouldShowMilestone,
} from "../../../services/config/constants"

const ChallengeCardDesktop = ({ prizes }: { prizes: Prize[] }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { viewTutorial, currentTutorialPage } = useContext(MainContext)
  const { challenge } = useContext(ChallengeContext)

  // show confetti fot tutorial animation
  const [showConfetti, setShowConfetti] = useState<boolean>(false)
  const showConfettiTimeoutId = useRef<any>(null)

  useEffect(() => {
    if (viewTutorial && currentTutorialPage === 1) {
      showConfettiTimeoutId.current = setTimeout(() => {
        setShowConfetti(true)
      }, 1000)
    } else {
      if (showConfettiTimeoutId.current) {
        clearTimeout(showConfettiTimeoutId.current)
      }
      setShowConfetti(false)
    }
  }, [viewTutorial, currentTutorialPage])

  return (
    <ButtonBase
      aria-label={t("go_to_challenge")}
      style={{ width: "75%", borderRadius: 15 }}
      onClick={() => {
        navigate("/challenge")
      }}
    >
      <Stack
        id="challenge-card"
        alignItems="center"
        style={{
          width: "100%",
          height: "auto",
          backgroundColor: colors.backgroundWhite,
          borderRadius: 15,
          position: "relative",
          paddingTop: 18,
          paddingBottom: 18,
          boxShadow: "0px 0px 24px 0px rgba(147, 160, 167, 0.16)",
        }}
        role="region"
        aria-label={t("challenge_card")}
      >
        {showConfetti ? <Confetti zIndex={150} particleCount={80} /> : null}
        <Title fontSize={14} lineHeight="17px" color={colors.primary}>
          {t("collective_challenge").toUpperCase()}
        </Title>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ width: "100%", height: 39, gap: 10, marginTop: 28 }}
        >
          <img
            src={lightningIcon}
            style={{ height: 27, marginTop: -4 }}
            alt=""
          />
          <Title fontSize={30} color={colors.primary}>
            {numberWithSeparators(challenge!.targetAmount)}{" "}
            {t("missions").toLowerCase()}
          </Title>
        </Stack>
        {challenge ? (
          <Text fontSize={14} fontWeight={400}>
            {`entro il ${new Date(challenge.endDate).getDate()} ${t(
              months[new Date(challenge.endDate).getMonth()]
            )}`}
          </Text>
        ) : null}
        {challenge &&
        challenge.targetAmount === challengeTargetToShowMilestone &&
        shouldShowMilestone ? (
          <Text
            fontSize={14}
            fontWeight={400}
            color={colors.primary}
            style={{ marginTop: 5 }}
          >
            Milestone challenge collettiva:{" "}
            <span style={{ fontWeight: 600 }}>
              {milestoneAmount} missioni completate
            </span>
          </Text>
        ) : null}
        <ChallengeDotProgressBar
          percentage={
            viewTutorial && currentTutorialPage === 1
              ? 100
              : Math.round(
                  (challenge!.currentAmount / challenge!.targetAmount) * 100
                )
          }
          challengeTargetAmount={challenge!.targetAmount}
          style={{
            width: "calc(100% - 36px)",
            height: 15.6,
            marginTop: 6,
          }}
        />
        <Stack
          direction="row"
          alignItems="center"
          style={{
            width: "100%",
            height: 28,
            gap: 8,
            marginTop: 4,
            paddingInline: 18,
          }}
        >
          <img src={propicsRowAltImage} style={{ height: "100%" }} alt="" />
          <Text fontSize={14} fontWeight={600} style={{ marginTop: 4 }}>
            {challenge!.userCount} {t("partecipants")}
          </Text>
        </Stack>
        <Text
          fontSize={14}
          lineHeight="20px"
          fontWeight={400}
          paddingLeft={18}
          color={colors.primary}
          style={{ width: "100%", marginTop: 22 }}
        >
          {t("prizes")}
        </Text>
        <Stack
          direction="row"
          style={{
            width: "calc(100% - 36px)",
            borderRadius: 8,
            marginTop: 6,
            paddingInline: 14,
            paddingTop: 22,
            paddingBottom: 22,
            gap: 12,
            backgroundColor: colors.primary,
          }}
        >
          {prizes.map((prize, index) => (
            <Stack
              key={index}
              style={{
                width: "100%",
                height: 150,
                backgroundColor: prize.draw
                  ? "#D02963"
                  : colors.backgroundWhite,
                borderRadius: 6,
                position: "relative",
                overflow: "hidden",
              }}
            >
              <img
                src={prize.image}
                style={{ objectFit: "cover", width: "100%", height: 77 }}
              />
              <div
                className="center"
                style={{
                  width: "fit-content",
                  height: 13,
                  borderRadius: 12,
                  backgroundColor: prize.draw ? "#D02963" : colors.primary,
                  position: "absolute",
                  top: 58,
                  left: 8,
                  paddingInline: 6,
                }}
              >
                <Text
                  fontSize={7.5}
                  fontWeight={500}
                  color={colors.textWhite}
                  style={{ marginTop: 2 }}
                >
                  {prize.heading}
                </Text>
              </div>
              <Title
                fontSize={9}
                fontWeight={600}
                lineHeight="13.3px"
                paddingLeft={8}
                paddingRight={8}
                color={prize.draw ? colors.textWhite : colors.text}
                style={{ marginTop: 6, height: 40 }}
              >
                {prize.title}
              </Title>
              <Text
                fontSize={9}
                fontWeight={400}
                lineHeight="13.3px"
                paddingLeft={8}
                paddingRight={8}
                color={prize.draw ? colors.textWhite : colors.text}
                style={{ marginTop: 6 }}
              >
                {prize.value}
              </Text>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </ButtonBase>
  )
}

export default ChallengeCardDesktop
